import { Typography, Modal, Table } from "antd";
import { useWeightsLogs } from "./store";

const { Title } = Typography;

export const WeightsModal = () => {
  const { historyData, isHistoryModal, setIsHistoryModal } = useWeightsLogs();

  let data = historyData
    ? Object.keys(historyData)?.map((key) => ({
        name: key,
        weight: historyData[key],
      }))
    : [];

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      width: 55,
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => text.replace(/_/g, " "),
    },
    {
      title: "Weight",
      dataIndex: "weight",
      key: "weight",
    },
  ];

  return (
    <Modal
      forceRender
      title={<Title level={5}> Weights Values</Title>}
      className="app-modal"
      centered
      visible={isHistoryModal}
      onCancel={() => {
        setIsHistoryModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={1500}
    >
      <Table
        columns={columns}
        dataSource={data ? data : []}
        size="small"
        pagination={false}
        scroll={{
          y: 400,
        }}
      />
    </Modal>
  );
};
