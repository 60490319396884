import { useState, useEffect } from "react";
import {
  Table,
  Button,
  message,
  Alert,
  Space,
  Popconfirm,
  Pagination,
  Switch,
  Popover,
  Tag,
} from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useQuery, useMutation } from "react-query";
import { queryClient } from "../../queryClient";
// import authFunc from "../../helper/auth";
import { useSearchParams } from "react-router-dom";
import {
  removeSortingSetting,
  getSortingSetting,
  editSortingSettingStatus,
  editSortingSettingDistanceStatus,
  editSortingSettingPinnedStatus,
} from "../../api";
import { useSortingSettings } from "./store";

export const PuerTable = () => {
  const [page, setPage] = useState(1);
  const {
    setId,
    setIsCreateModal,
    setPinned_distance_limit,
    setPinned_vendors,
    setName,
    setDistance_setting_id,
    setPinned_vendors_str,
  } = useSortingSettings();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const handleEdit = (row) => {
    setId(row.id);
    setName(row.name);
    setPinned_distance_limit(row.pinned_distance_limit);
    setPinned_vendors(row.pinned_vendors);
    setDistance_setting_id(row.distance_setting_id);
    setPinned_vendors_str(JSON.parse(row.pinned_vendors_str));
    // console.log("row.pinned_vendors_str: ", row.pinned_vendors_str);

    setIsCreateModal(true);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      width: 50,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Pinned Distance Limit",
      dataIndex: "pinned_distance_limit",
      key: "pinned_distance_limit",
    },

    {
      title: "Vendors",
      dataIndex: "pinned_vendors_str",
      key: "pinned_vendors_str",
      width: 300,
      ellipsis: true,
      render: (vendorsStr) => {
        const vendorsObj = JSON.parse(vendorsStr);
        const vendorNames = Object.values(vendorsObj);

        return (
          <Popover
            content={vendorNames.map((name, index) => (
              <Tag key={index}>{name}</Tag>
            ))}
          >
            {vendorNames.map((name, index) => (
              <Tag key={index}>{name}</Tag>
            ))}
          </Popover>
        );
      },
    },
    {
      title: "Distance Setting Name",
      dataIndex: "distance_setting_name",
      key: "distance_setting_name",
    },
    {
      title: "Status",
      key: "id",
      render: (id, row) => (
        <Switch
          loading={editLoading}
          checked={row.status}
          onChange={() => handleChangeStatus(row)}
          //   disabled={!authFunc("SORTING_SETTINGS_UPDATE") ? true : false}
        />
      ),
    },
    {
      title: "Distance Status",
      key: "id",
      render: (id, row) => (
        <Switch
          loading={editDistanceLoading}
          checked={row.distance_setting_status}
          onChange={() => handleDistanceStatus(row)}
          //   disabled={!authFunc("SORTING_SETTINGS_UPDATE") ? true : false}
        />
      ),
    },
    {
      title: "Pinned Status",
      key: "id",
      render: (id, row) => (
        <Switch
          loading={editPinnedLoading}
          checked={row.pinned_vendors_status}
          onChange={() => handlePinnedStatus(row)}
          //   disabled={!authFunc("SORTING_SETTINGS_UPDATE") ? true : false}
        />
      ),
    },
    {
      title: "Actions",
      key: "id",
      dataIndex: "id",
      render: (id, row, index) => (
        <Space>
          {/* {authFunc("SORTING_SETTINGS_UPDATE") && ( */}
          <Button
            onClick={() => handleEdit(row)}
            size="small"
            icon={<EditOutlined />}
          />
          {/* )} */}
          {/* {authFunc("SORTING_SETTINGS_DELETE") && ( */}
          <Popconfirm
            title="Are you sure to delete this setting?"
            onConfirm={() => removeMutate(id)}
            okText="Yes"
            cancelText="No"
          >
            <Button size="small" danger icon={<DeleteOutlined />} />
          </Popconfirm>
          {/* )} */}
        </Space>
      ),
    },
  ];

  const { mutate: editStatusMutate, isLoading: editLoading } = useMutation(
    editSortingSettingStatus,
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          queryClient.invalidateQueries("sortingSettings");
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );
  const { mutate: editDistanceStatusMutate, isLoading: editDistanceLoading } =
    useMutation(editSortingSettingDistanceStatus, {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          queryClient.invalidateQueries("sortingSettings");
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    });
  const { mutate: editPinnedStatusMutate, isLoading: editPinnedLoading } =
    useMutation(editSortingSettingPinnedStatus, {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          queryClient.invalidateQueries("sortingSettings");
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    });

  const handleChangeStatus = (row) => {
    editStatusMutate({
      id: row.id,
      status: !row.status,
    });
  };
  const handleDistanceStatus = (row) => {
    editDistanceStatusMutate({
      id: row.id,
      status: !row.distance_setting_status,
    });
  };
  const handlePinnedStatus = (row) => {
    editPinnedStatusMutate({
      id: row.id,
      status: !row.pinned_vendors_status,
    });
  };

  const { mutate: removeMutate } = useMutation(removeSortingSetting, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        queryClient.invalidateQueries("sortingSettings");
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(["sortingSettings", page], getSortingSetting, {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  if (error || (!data?.success && data?.data?.err))
    return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />
    );

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data ? data?.data : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        total={data && data?.data?.total}
        showSizeChanger={false}
      />
    </>
  );
};
