import { useState, useEffect } from "react";
import {
  Table,
  Tag,
  Typography,
  Button,
  message,
  Alert,
  Space,
  Popconfirm,
  Pagination,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  DislikeFilled,
  LikeFilled,
} from "@ant-design/icons";
import { useQuery, useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import moment from "moment";
import { getReviewReasons, removeData, pageSize } from "../../api";
import { useReviewReason } from "./store";
import authFunc from "../../helper/auth";
import { useSearchParams } from "react-router-dom";

const { Text } = Typography;

//'VENDOR','CLIENT','BIKER','BACKOFFICE_USER'
const typeColor = {
  1: "red",
  5: "blue",
};
const categoryColor = {
  VENDOR: "pink",
  PRODUCT: "geekblue",
  BIKER: "cyan",
};

export const PureTable = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    queryKey,
    setIsCreateModal,
    setId,
    setCategory,
    setTitle,
    setType,
    setPriority,
    page,
    setPage,
    categoryFilter,
  } = useReviewReason();

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const handleEdit = (row) => {
    setId(row.id);
    setCategory(row.category);
    setTitle(row.title);
    setType(row.type);
    setPriority(row.priority);
    setIsCreateModal(true);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text) => <Text style={{ fontWeight: "bold" }}>{text}</Text>,
    },

    {
      title: "Reason Type",
      dataIndex: "type",
      key: "type",
      render: (type, row) =>
        (type === "1" || type === "5") && row.category !== "VENDOR" ? (
          <Tag color={typeColor[type]}>
            {type === "1" ? <DislikeFilled /> : <LikeFilled />}
          </Tag>
        ) : (
          <Tag color={typeColor[type]}>{type}</Tag>
        ),
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      render: (priority) => <Tag color={"purple"}>{priority}</Tag>,
    },

    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (cat) => <Tag color={categoryColor[cat]}>{cat}</Tag>,
    },

    {
      title: "Creation Date",
      dataIndex: "CreatedAt",
      key: "CreatedAt",
      render: (date) => (
        <Text type="secondary">{moment(date).format("ll")}</Text>
      ),
    },
    {
      title: "Actions",
      key: "id",
      dataIndex: "id",
      render: (id, row) => (
        <Space>
          {authFunc("REVIEW_REASON_UPDATE") && (
            <Button
              onClick={() => handleEdit(row)}
              size="small"
              icon={<EditOutlined />}
            />
          )}
          {authFunc("REVIEW_REASON_DELETE") && (
            <Popconfirm
              title="Are you sure to delete this task?"
              onConfirm={() => mutate(id)}
              //onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Button size="small" danger icon={<DeleteOutlined />} />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  const { mutate } = useMutation((data) => removeData("reviewreasons", data), {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        queryClient.invalidateQueries("reviewReasons");
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(
    ["reviewReasons", queryKey, page, categoryFilter],
    getReviewReasons,
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );

  if (error || (!data?.success && data?.data?.err))
    return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />
    );

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data ? data.data.records : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        defaultPageSize={pageSize}
        total={data && data.data.total}
        showSizeChanger={false}
      />
    </>
  );
};
