import { ClockCircleOutlined } from "@ant-design/icons";
import { Typography, Space, Row, Col, Tag } from "antd";
import { useOrderView } from "./store";
import dayjs from "dayjs";
import moment from "moment";
const { Text } = Typography;

export const MoreInfo = () => {
  const { order } = useOrderView();
  // var utc = require("dayjs/plugin/utc");
  // dayjs.extend(utc);

  return (
    <>
      <Row gutter={[10, 20]}>
        <Col span={24}>
          <Text style={{ fontSize: "13px" }}>Ready At </Text>
          <div className="status-history">
            <section className="app-space-between">
              <Text type="secondary">
                <Space>
                  <ClockCircleOutlined />
                  {order?.order?.ready_at ? moment(order?.order?.ready_at).format('MM-DD hh:mm a') : 'Not Ready'}

                </Space>
              </Text>
            </section>
          </div>
        </Col>
        <Col span={10}>
          <Text style={{ fontSize: "12px" }}>Estimated delivery time</Text>
          <div className="status-history">
            <section className="app-space-between">
              <Text type="secondary">
                <Space>
                  <ClockCircleOutlined />
                  {order?.order?.estimatedDeliveryTime} Minute
                </Space>
              </Text>
            </section>
          </div>
        </Col>
        <Col span={14}>
          <Text style={{ fontSize: "12px" }}>PreOrder Delivery Time</Text>
          <div className="status-history">
            <section className="app-space-between">
              <Text type="secondary">
                <Space>
                  <ClockCircleOutlined />
                  {order?.order?.preOrder
                    ? dayjs(order?.order?.preOrderDeliveryTime).format(
                        "YYYY-MM-DD hh:mm A"
                      )
                    : "No Delivery Time"}
                </Space>
              </Text>
            </section>
          </div>
        </Col>
        <Col span={10}>
          <Text style={{ fontSize: "13px" }}>Hybrid Payment Status</Text>
          <div className="status-history">
            <section className="app-space-between">
              <Tag color="purple">{order?.order?.hybridPaymentStatus}</Tag>
            </section>
          </div>
        </Col>
        <Col span={14}>
          <Text style={{ fontSize: "13px" }}>Hybrid Payment Enabled</Text>
          <div className="status-history">
            <section className="app-space-between">
              <Tag color={order?.order?.hybridPaymentEnabled ? "blue" : "red"}>
                {order?.order?.hybridPaymentEnabled ? "true" : "false"}
              </Tag>
            </section>
          </div>
        </Col>
      </Row>
    </>
  );
};
