import create from "zustand";

export const useVendor = create((set) => ({
  isCreateModal: false,
  name: null,
  username: null,
  password: null,
  roleID: 1,
  email: null,
  phone: null,
  authGroupID: 1,
  status: true,
  isFilter: false,
  vendorID: null,
  queryFilter: "",
  id: null,
  queryKey: "",
  isReset: false,
  resturantName: "",
  page: 1,
  vendorIdFilter: null,

  setVendorIdFilter: (vendorIdFilter) => set({ vendorIdFilter }),
  setResturantName: (resturantName) => set({ resturantName }),
  setQueryKey: (queryKey) => set({ queryKey }),
  setIsFilter: (isFilter) => set({ isFilter }),
  setQueryFilter: (queryFilter) => set({ queryFilter }),
  setIsReset: (isReset) => set({ isReset }),
  setId: (id) => set({ id }),
  setName: (name) => set({ name }),
  setUsername: (username) => set({ username }),
  setPassword: (password) => set({ password }),
  setRoleID: (roleID) => set({ roleID }),
  setStatus: (status) => set({ status }),
  setEmail: (email) => set({ email }),
  setPhone: (phone) => set({ phone }),
  setVendorID: (vendorID) => set({ vendorID }),
  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
  setPage: (page) => set({ page }),
}));
