import {
  Form,
  Modal,
  Typography,
  message,
  Divider,
  Col,
  Row,
  Button,
  DatePicker,
} from "antd";
import { useResturant } from "./store";
import { SaveOutlined } from "@ant-design/icons";
import { editVendorCloseTime } from "../../api";
import { useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import moment from "moment";
const { Title } = Typography;

export const CloseTimeModal = () => {
  const {
    isCloseTimeModal,
    setIsCloseTimeModal,
    setRow,
    row,
    setClosedUntil,
    closedUntil,
  } = useResturant();

  const { mutate, isLoading: editLoading } = useMutation(editVendorCloseTime, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        setIsCloseTimeModal(false);
        setClosedUntil(null);
        setRow(null);
        queryClient.invalidateQueries("resturants");
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const onFinish = (value) => {
    let _data = {
      id: row.id,
      closed_until: value.closedUntil,
    };
    mutate(_data);
  };

  return (
    <Modal
      title={<Title level={5}>Restaurant Close Time</Title>}
      className="app-modal"
      centered
      visible={isCloseTimeModal}
      onCancel={() => {
        setIsCloseTimeModal(false);
        setClosedUntil(null);
        setRow(null);
      }}
      footer={false}
      destroyOnClose={true}
      width={400}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        onFinish={onFinish}
        initialValues={{
          closedUntil: closedUntil && moment(closedUntil),
        }}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Form.Item
              label="Closed Until"
              name="closedUntil"
              rules={[{ required: true }]}
            >
              <DatePicker
                style={{ width: "100%" }}
                format="YYYY-MM-DD HH:mm"
                showTime
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={editLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
