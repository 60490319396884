import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  InputNumber,
  Select,
} from "antd";
import { message as ANTmessage } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import Input from "antd/lib/input/Input";
import { useWaterfallProcess } from "./store";
import { useMutation, useQuery } from "react-query";
import { queryClient } from "../../queryClient";
import {
  createWaterfallProcess,
  editWaterfallProcess,
  getFee,
  getRegions,
} from "../../api";
import { useState } from "react";

const { Title } = Typography;
const { Option } = Select;

export const PureCreate = () => {
  const {
    isCreateModal,
    setIsCreateModal,
    id,
    setId,
    processName,
    cities,
    cityIDs,
    delieveryfeeID,
    default_buffer_time,
    setDefault_buffer_time,
    setDelieveryfeeID,
    setDelieveryfee,
    setCities,
    setProcessName,
    setCityIDs,
  } = useWaterfallProcess();

  const [citySearch, setCitySearch] = useState();
  const [feeSearch, setFeeSearch] = useState("");

  const resetState = () => {
    setId(null);
    setProcessName(null);
    setDelieveryfeeID(null);
    setDelieveryfee(null);
    setDefault_buffer_time(null);
    setCities([]);
    setCityIDs([]);
  };

  const {
    isLoading: isCityLoading,
    data: citiesData,
    error: cityError,
  } = useQuery(["regions", "", citySearch, 1], getRegions, {
    retry: 2,
    refetchOnWindowFocus: false,
  });

  const {
    isLoading: feeLoading,
    error: feeError,
    data: feeList,
  } = useQuery(["deliverFee", feeSearch, 1, 1000], getFee, {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  const { mutate, isLoading } = useMutation(createWaterfallProcess, {
    onSuccess: (data) => {
      if (data.success) {
        ANTmessage.success(data.msg);
        setIsCreateModal(false);
        queryClient.invalidateQueries("waterfallProcess");
        resetState();
      } else {
        ANTmessage.error(data.msg);
      }
    },
    onError: (e) => {
      ANTmessage.error(e.message);
    },
  });
  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editWaterfallProcess,
    {
      onSuccess: (data) => {
        if (data.success) {
          ANTmessage.success(data.msg);
          setIsCreateModal(false);
          queryClient.invalidateQueries("waterfallProcess");
          resetState();
        } else {
          ANTmessage.error(data.msg);
        }
      },
      onError: (e) => {
        ANTmessage.error(e.message);
      },
    }
  );

  const onFinish = (values) => {
    values.cities = cityIDs;
    if (id) {
      editMutate({
        id: id,
        name: values.processName,
        cities: values.cities,
        deliveryFeeID:
          values.delieveryfeeID !== "null" ? values.delieveryfeeID : null,
        default_buffer_time: values.default_buffer_time,
      });
    } else {
      mutate({
        name: values.processName,
        cities: values.cities,
        deliveryFeeID:
          values.delieveryfeeID !== "null" ? values.delieveryfeeID : null,
        default_buffer_time: values.default_buffer_time,
      });
    }
  };

  return (
    <Modal
      title={<Title level={5}>{id ? "Edit" : "New"} Waterfall Process</Title>}
      className="app-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetState();
        setIsCreateModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={400}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        initialValues={{
          remember: true,
          processName,
          cityIDs,
          delieveryfeeID,
          default_buffer_time,
        }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Form.Item
              label="Process Name"
              name="processName"
              rules={[{ required: true }]}
            >
              <Input rows={4} placeholder={"Process Name"} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Cities"
              name="cityIDs"
              rules={[{ required: true }]}
            >
              <Select
                onChange={(val) => {
                  setCityIDs(val.map((el) => el.value));
                }}
                mode="multiple"
                showArrow
                maxTagCount="responsive"
                loading={isCityLoading}
                error={cityError}
                placeholder="Choose Cities"
                showSearch
                labelInValue
                filterOption={false}
                onSearch={(val) => {
                  setCitySearch(val);
                }}
              >
                {citiesData
                  ? citiesData?.data?.records?.map((el) => (
                      <Option key={el.id} value={el.id}>
                        {el?.name}
                      </Option>
                    ))
                  : null}
                {cities
                  ? cities.map((el) => (
                      <Option key={el.id} value={el.id}>
                        {el?.name}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Default Delivery Fee Type"
              name="delieveryfeeID"
              rules={[{ required: true }]}
            >
              <Select
                onChange={(val) => setDelieveryfeeID(val)}
                loading={feeLoading}
                error={feeError}
                placeholder="Choose Default Delivery Fee"
                onSearch={(v) => {
                  setFeeSearch(v);
                }}
                showSearch
                searchValue={feeSearch}
                allowClear
                filterOption={false}
              >
                <Option value={"null"}>No Delivery Fee</Option>
                {feeList
                  ? feeList.data.records.map((el) => (
                      <Option key={el.id} value={el.id}>
                        {el.name}
                      </Option>
                    ))
                  : null}
                {/* {delieveryfee ? (
                  <Option key={delieveryfee.id} value={delieveryfee.id}>
                    {delieveryfee.name}
                  </Option>
                ) : null} */}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Default Buffer Time"
              name="default_buffer_time"
              rules={[{ required: true }]}
            >
              <InputNumber
                defaultValue={0}
                min={0}
                placeholder="Buffer Time"
                formatter={(value) => `${value} minutes`}
                parser={(value) => value?.replace("minutes", "")}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={isLoading || editLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
