import { Card, Input, Space, Button, Breadcrumb } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { PureTable } from "./table";
import { PureReset } from "./reset";
import { PureCreate } from "./create";
import { useVendor } from "./store";
import VendorFilters from "./filters";
import authFunc from "../../helper/auth";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const { Search } = Input;

export const VendorsPage = () => {
  const { setIsCreateModal, setQueryKey, queryKey } = useVendor();
  const [search, setSearch] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (queryKey) {
      setSearch(queryKey);
    }
  }, []);

  useEffect(() => {
    if (searchParams.get("q")) {
      setSearch(searchParams.get("q"));
      setQueryKey(searchParams.get("q"));
    } else {
      setSearch("");
      setQueryKey("");
    }
  }, [searchParams.get("q")]);

  return (
    <div className="app-page">
      <Breadcrumb style={{ marginBottom: 10 }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Users</Breadcrumb.Item>
        <Breadcrumb.Item>Vendors List</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <div className="app-space-between">
          <Space>
            <Search
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onSearch={(val) => {
                searchParams.set("q", val);
                searchParams.set("page_num", 1);
                setSearchParams(searchParams);
                setQueryKey(val);
              }}
              style={{ width: 340 }}
              placeholder="Write To Search..."
            />
            {/* <Button type={queryFilter ? "primary" : "default"} onClick={()=> setIsFilter(true)} icon={<FilterOutlined />} /> */}
            <VendorFilters />
          </Space>
          {authFunc("VENDOR_USER_CREATE") && (
            <Button
              onClick={() => setIsCreateModal(true)}
              type="primary"
              icon={<PlusOutlined />}
            >
              Create New
            </Button>
          )}
        </div>
        <br />
        <PureTable />
        <PureCreate />
        <PureReset />
      </Card>
    </div>
  );
};
