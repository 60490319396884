import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  Upload,
  InputNumber,
  message,
} from "antd";
import { SaveOutlined, InboxOutlined } from "@ant-design/icons";
import { useSubProduct } from "./store";
import Input from "antd/lib/input/Input";
import { editTopping, createTopping } from "../../api";
import TextArea from "antd/lib/input/TextArea";
import { useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import { useEffect } from "react";

const { Title } = Typography;
const { Dragger } = Upload;

export const PureCreate = ({ SubProductGroupID }) => {
  const {
    isCreateModal,
    setIsCreateModal,
    id,
    name,
    price,
    description,
    status,
    setDescription,
    setId,
    setName,
    setPrice,
    setProductGroupID,
    setStatus,
    priority,
  } = useSubProduct();
  const [form] = Form.useForm();

  const props = {
    name: "file",
    multiple: false,
    maxCount: 1,
    beforeUpload: (file) => {
      return false;
    },
  };

  const { mutate, isLoading } = useMutation(createTopping, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        resetState();
        setIsCreateModal(false);
        queryClient.invalidateQueries("topping");
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editTopping,
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          resetState();
          setIsCreateModal(false);
          queryClient.invalidateQueries("topping");
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const onFinish = (values) => {
    if (id) {
      let formData = {
        id,
        name: values.name,
        price: values.price,
        description: values.description,
        groupID: Number(SubProductGroupID),
        status: status,
        priority: values.priority,
      };

      editMutate({
        data: JSON.stringify(formData),
      });
    } else {
      let formData = {
        name: values.name,
        price: values.price,
        description: values.description,
        groupID: Number(SubProductGroupID),
        priority: values.priority,
      };
      mutate({
        data: JSON.stringify(formData),
        img: values?.imgFile?.file || null,
      });
    }
  };

  let resetState = () => {
    setId(null);
    setProductGroupID(null);
    setDescription(null);
    setId(null);
    setName(null);
    setPrice(null);
    setStatus(false);

    form.resetFields();
  };

  useEffect(() => {
    if (id) {
      form.setFieldsValue({
        name,
        description,
        price,
        priority,
      });
    } else {
      resetState();
    }
  }, [id]);

  return (
    <Modal
      title={<Title level={5}>{id ? "Edit" : "New"} Topping</Title>}
      className="app-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetState();
        setIsCreateModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={550}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        form={form}
        onFinish={onFinish}
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={8}>
            <Form.Item
              label="Item Name"
              name="name"
              rules={[{ required: true }]}
            >
              <Input placeholder={"Cheese Burger"} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Priority"
              name="priority"
              rules={[{ required: true }]}
            >
              <InputNumber
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\\s?|(,*)/g, "")}
                style={{ width: "100%" }}
                type="number"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Price ( IQD )"
              name="price"
              rules={[{ required: true }]}
            >
              <InputNumber
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\\s?|(,*)/g, "")}
                placeholder="Price"
                style={{ width: "100%" }}
                min={0}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Description"
              name="description"
              rules={[{ required: false }]}
            >
              <TextArea
                rows={3}
                style={{ width: "100%" }}
                placeholder={"Write description of this Item"}
              />
            </Form.Item>
          </Col>

          {!id ? (
            <Col span={24}>
              <Form.Item name="imgFile" rules={[{ required: false }]}>
                <Dragger {...props}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag Image to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    This Image will view in Product details
                  </p>
                </Dragger>
              </Form.Item>
            </Col>
          ) : null}
          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={isLoading || editLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
