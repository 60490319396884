import create from "zustand";

export const useReferralSettings = create((set) => ({
  isCreateModal: false,
  id: null,
  name: null,
  config: null,
  deepLink: null,
  referredVoucherID: null,
  referrerVoucherID: null,
  inviteCount: null,
  maxUse: null,
  msg: null,
  queryKey: "",
  type:"",
  setQueryKey: (queryKey) => set({ queryKey }),


  setId: (id) => set({ id }),
  setInviteCount: (inviteCount) => set({ inviteCount }),
  setMaxUse: (maxUse) => set({ maxUse }),
  setMsg: (msg) => set({ msg }),
  setName: (name) => set({ name }),
  setReferredVoucherID: (referredVoucherID) => set({ referredVoucherID }),
  setReferrerVoucherID: (referrerVoucherID) => set({ referrerVoucherID }),
  setConfig: (config) => set({ config }),
  setDeepLink: (deepLink) => set({ deepLink }),
  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
  setType:(type)=>set({type })
}));
