import { useState, useEffect } from "react";
import {
  Table,
  Button,
  message,
  Alert,
  Space,
  Popconfirm,
  Pagination,
  Switch,
  Tooltip,
  Popover,
  Tag,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { useQuery, useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import authFunc from "../../helper/auth";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  getWaterfallProcess,
  editWaterfallProcessStatus,
  removeWaterfallProcess,
} from "../../api";
import { useWaterfallProcess } from "./store";
import Text from "antd/lib/typography/Text";

export const PuerTable = () => {
  const [page, setPage] = useState(1);
  const {
    setId,
    setIsCreateModal,
    setProcessName,
    setCities,
    queryKey,
    setCityIDs,
    setDelieveryfeeID,
    setDelieveryfee,
    setDefault_buffer_time,
  } = useWaterfallProcess();

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const handleEdit = (row) => {
    setId(row.id);
    setProcessName(row.name);
    setCities(row.citiesObjects);
    setCityIDs(row.cities);
    setDelieveryfee(row.deliveryFee);
    setDelieveryfeeID(row.deliveryFeeID === null ? "null" : row.deliveryFeeID);
    setDefault_buffer_time(row.default_buffer_time);
    setIsCreateModal(true);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      width: 50,
    },
    {
      title: "Process Name",
      dataIndex: "name",
      key: "name",
    },

    {
      title: "Default Buffer Time",
      dataIndex: "default_buffer_time",
      key: "default_buffer_time",
      render: (val) => <Text>{val} min</Text>,
    },

    {
      title: "Cities",
      dataIndex: "citiesObjects",
      key: "citiesObjects",
      ellipsis: true,
      render: (Cities) => (
        <Popover
          content={Cities?.map((el, index) => (
            <Tag key={index}>{el.name}</Tag>
          ))}
        >
          {Cities?.map((el, index) => (
            <Tag key={index}>{el.name}</Tag>
          ))}
        </Popover>
      ),
    },
    {
      title: "Status",
      key: "id",
      render: (id, row) => (
        <Switch
          loading={editLoading}
          checked={row.active}
          onChange={() => handleChangeActive(row)}
          disabled={!authFunc("WATERFALL_SETTINGS_CREATE") ? true : false}
        />
      ),
    },
    {
      title: "Actions",
      key: "id",
      dataIndex: "id",
      render: (id, row, index) => (
        <Space>
          <Tooltip title="Actions">
            <Button
              onClick={() => navigate(`/waterfall_process_action/${id}`)}
              size="small"
              icon={<UnorderedListOutlined />}
            />
          </Tooltip>
          {authFunc("WATERFALL_SETTINGS_CREATE") && (
            <Button
              onClick={() => handleEdit(row)}
              size="small"
              icon={<EditOutlined />}
            />
          )}
          {authFunc("WATERFALL_SETTINGS_CREATE") && (
            <Popconfirm
              title="Are you sure to delete this task?"
              onConfirm={() => removeMutate(id)}
              okText="Yes"
              cancelText="No"
            >
              <Button size="small" danger icon={<DeleteOutlined />} />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  const { mutate: editStatusMutate, isLoading: editLoading } = useMutation(
    editWaterfallProcessStatus,
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          queryClient.invalidateQueries("waterfallProcess");
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const handleChangeActive = (row) => {
    {
      editStatusMutate({
        id: row.id,
        active: !row.active,
      });
    }
  };

  const { mutate: removeMutate } = useMutation(removeWaterfallProcess, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        queryClient.invalidateQueries("waterfallProcess");
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(["waterfallProcess", page, queryKey], getWaterfallProcess, {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  if (error || (!data?.success && data?.data?.err))
    return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />
    );

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data ? data?.data : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        total={data && data?.data?.total}
        showSizeChanger={false}
      />
    </>
  );
};
