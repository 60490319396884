import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  Input,
  message,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useEmployee } from "./store";
import { useMutation } from "react-query";
import { resetUser } from "../../api";
import PasswordStrengthBar from "react-password-strength-bar";
import { useState } from "react";

const { Title } = Typography;

export const PureReset = () => {
  const { id, isReset, setIsReset } = useEmployee();
  const [passScore, setPassScore] = useState(0);
  const [confirmErr, setConfirmErr] = useState("validating");
  const [pass, setPass] = useState("");
  const [form] = Form.useForm();

  const resetFileds = () => {
    setConfirmErr("validating");
    setPassScore(0);
    setPass("");
    setIsReset(false);
    form.resetFields();
  };

  const { mutate, isLoading } = useMutation(resetUser, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        resetFileds();
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const onFinish = (values) => {
    if (form.getFieldValue("password") !== form.getFieldValue("conPassword"))
      setConfirmErr("error");
    else {
      values.id = id;
      mutate(values);
    }
  };

  return (
    <Modal
      title={<Title level={5}>Reset Password</Title>}
      className="app-modal"
      centered
      visible={isReset}
      onCancel={() => {
        resetFileds();
      }}
      footer={false}
      destroyOnClose={true}
      width={400}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Form.Item
              label="New Password"
              name="password"
              rules={[{ required: true, message: "required" }]}
              style={{ marginBottom: 10 }}
            >
              <Input
                value={pass}
                onChange={(e) => setPass(e.target.value)}
                placeholder="*****"
              />
            </Form.Item>
            <PasswordStrengthBar password={pass} onChangeScore={setPassScore} />
          </Col>
          <Col span={24}>
            <Form.Item
              label="Confirm Password"
              name="conPassword"
              validateStatus={confirmErr}
              rules={[{ required: true, message: "required" }]}
            >
              <Input placeholder="*****" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                disabled={passScore < 3}
                loading={isLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
