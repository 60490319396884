import {
  Table,
  Space,
  Button,
  Typography,
  message,
  Alert,
  Popconfirm,
  Pagination,
  Switch,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useQuery, useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import { getZones, removeZone, pageSize, editZone } from "../../api";
import { useState, useEffect } from "react";
import { useZone } from "./store";
import PolygonToCenter from "../../constants/PolygonToCenter";
import authFunc from "../../helper/auth";
import { useSearchParams } from "react-router-dom";

const { Text } = Typography;

export const PureTable = () => {
  const [page, setPage] = useState(1);
  let {
    setAreaSelect,
    setIsCreateModal,
    queryKey,
    setArea,
    setName,
    setId,
    setCreateMapCenter,
  } = useZone();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const handleEdit = (row) => {
    setId(row.id);
    setName(row.name);
    setArea(JSON.parse(row.area));
    setCreateMapCenter(PolygonToCenter(JSON.parse(row.area)));
    setIsCreateModal(true);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) =>
      setAreaSelect(JSON.parse(selectedRows[0].area)),
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (value) => <Text style={{ fontWeight: "bold" }}>{value}</Text>,
    },

    {
      title: "Status",
      key: "id",
      render: (row) => (
        <Switch
          disabled={authFunc("AREA_UPDATE") ? false : true}
          loading={editLoading}
          checked={row.status}
          onChange={(state) => handleChangeActive(row)}
        />
      ),
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id, row) => (
        <Space>
          {authFunc("AREA_UPDATE") && (
            <Button
              onClick={() => handleEdit(row)}
              size={"small"}
              icon={<EditOutlined />}
            />
          )}
          {authFunc("AREA_DELETE") && (
            <Popconfirm
              title="Are you sure to delete this task?"
              onConfirm={() => mutate(id)}
              okText="Yes"
              cancelText="No"
            >
              <Button size="small" danger icon={<DeleteOutlined />} />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  const { mutate } = useMutation(removeZone, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        queryClient.invalidateQueries("zones");
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(["zones", queryKey, page], getZones, {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  const { mutate: editMutate, isLoading: editLoading } = useMutation(editZone, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        queryClient.invalidateQueries("regions");
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const handleChangeActive = (row) => {
    {
      row.status = !row.status;

      editMutate(row);
    }
  };

  if (error || (!data?.success && data?.data?.err))
    return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />
    );

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        rowSelection={{
          type: "radio",
          ...rowSelection,
        }}
        dataSource={data ? data.data.records : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        defaultPageSize={pageSize}
        total={data && data.data.total}
        showSizeChanger={false}
      />
    </>
  );
};
