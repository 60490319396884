import { Card, Descriptions, Tag } from "antd";

const getTotal = (el) => {
  let value = el?.balyValue + el?.vendorValue;
  let label = el?.valueType === "PERCENTAGE" ? "%" : " IQD";
  return `${value}${label}`;
};

const getValue = (el, name) => {
  let value = el[name];
  let label = el?.valueType === "PERCENTAGE" ? "%" : " IQD";
  return `${Number(value).toLocaleString("en")}${label}`;
};

const InfoCard = ({ item }) => {
  return item ? (
    <Descriptions
      bordered
      size={"small"}
      column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
    >
      <Descriptions.Item label="Voucher Title">
        <b>{item?.voucherTitle}</b>
      </Descriptions.Item>
      <Descriptions.Item label="Type">
        <Tag color="magenta">{item?.type}</Tag>
      </Descriptions.Item>
      <Descriptions.Item label="Baly Value">
        {getValue(item, "balyValue")}
      </Descriptions.Item>
      <Descriptions.Item label="Vendor Value">
        {getValue(item, "vendorValue")}
      </Descriptions.Item>
      <Descriptions.Item label="Min Amount">
        {Number(item?.minAmount).toLocaleString("en")}
      </Descriptions.Item>
      <Descriptions.Item label="Max Amount">
        {Number(item?.maxAmount).toLocaleString("en")}
      </Descriptions.Item>
      <Descriptions.Item label="Total Value">
        <b>{getTotal(item)}</b>
      </Descriptions.Item>
    </Descriptions>
  ) : (
    <Card
      bodyStyle={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 135,
        background: "#f0f0f0",
        color: "#666",
      }}
    >
      <span>Select Voucher Setting</span>
    </Card>
  );
};

export default InfoCard;
