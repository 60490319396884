import { useEffect, useState } from "react";
import {
  Table,
  Tag,
  Space,
  Button,
  Typography,
  Alert,
  message,
  Popconfirm,
  Switch,
  Pagination,
} from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "react-query";
import { queryClient } from "../../queryClient";
import {
  getVendorDiscount,
  removeData,
  editVendorDiscountStatus,
  pageSize,
} from "../../api";
import { useVendorDiscount } from "../discounts/store";
import authFunc from "../../helper/auth";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";

const { Text } = Typography;
export const PureTable = () => {
  const {
    queryKey,
    setId,
    setIsCreateModal,
    active,
    setPage,
    page,
    setSchedule,
    setVendorID,
  } = useVendorDiscount();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedID, setSelectedID] = useState(null);

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const handlEdit = (row) => {
    setId(row.id);
    setVendorID(row.vendorID);
    setSchedule(JSON.parse(row.schedule));
    setIsCreateModal(true);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Resturant",
      dataIndex: "vendor",
      key: "vendor",
      render: (text) => <Text style={{ fontWeight: "bold" }}>{text}</Text>,
    },

    {
      title: "Total Value",
      dataIndex: "totalValue",
      key: "totalValue",
      render: (val, row) => (
        <Tag>
          <b>
            {row.discountType === "AMOUNT"
              ? Number(val).toLocaleString() + " IQD"
              : val + " %"}{" "}
          </b>
        </Tag>
      ),
    },

    {
      title: "Value Type",
      dataIndex: "discountType",
      key: "discountType",
      render: (val) => <Tag color={"purple"}>{val}</Tag>,
    },

    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (text) => (
        <Text type="secondary">{dayjs(text).format("YYYY-MM-DD")}</Text>
      ),
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (text) => (
        <Text type="secondary">{dayjs(text).format("YYYY-MM-DD")}</Text>
      ),
    },
    {
      title: "Status",
      key: "id",
      render: (row) => (
        <Switch
          loading={selectedID === row.id && editLoading}
          checked={row?.status && row?.status === true ? true : false}
          onChange={(state) => handleChangeActive(row)}
        />
      ),
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id, row) => (
        <Space size="small">
          {authFunc("VENDOR_DISCOUNT_UPDATE") && (
            <Button
              onClick={() => handlEdit(row)}
              size="small"
              icon={<EditOutlined />}
            />
          )}
          {authFunc("VENDOR_DISCOUNT_DELETE") && (
            <Popconfirm
              title="Are you sure to delete this task?"
              onConfirm={() => mutate(id)}
              okText="Yes"
              cancelText="No"
            >
              <Button size="small" danger icon={<DeleteOutlined />} />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  const { mutate } = useMutation((val) => removeData("discount", val), {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        queryClient.invalidateQueries("vendorDiscounts");
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(["vendorDiscounts", queryKey, page, active], getVendorDiscount, {
    retry: 2,
    refetchOnWindowFocus: false,
  });

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editVendorDiscountStatus,
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          setSelectedID(null);
          queryClient.invalidateQueries("vendorDiscounts");
        } else {
          setSelectedID(null);
          message.error(data.err);
        }
      },
      onError: (e) => {
        setSelectedID(null);
        message.error(e.message);
      },
    }
  );

  const handleChangeActive = (row) => {
    setSelectedID(row.id);
    let dataForm = {
      id: row.id,
      status: !row.status,
      vendorID: row.vendorID,
    };
    editMutate(dataForm);
  };

  if (error || (!data?.success && data?.data?.err))
    return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />
    );

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data ? data.data.records : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        defaultPageSize={pageSize}
        total={data && data.data.total}
        showSizeChanger={false}
      />
    </>
  );
};
