import create from "zustand";

export const useDeliveryFee = create((set) => ({
  isCreateModal: false,
  queryKey: "",
  domains: null,
  name: null,
  description: null,
  id: null,
  extraFeePerKilometer : 0,
  extraMinAmount : 0,
  extraStartRange :0,



setExtraFeePerKilometer: (extraFeePerKilometer) => set({ extraFeePerKilometer }),
  setExtraMinAmount: (extraMinAmount) => set({ extraMinAmount }),
  setExtraStartRange: (extraStartRange) => set({ extraStartRange }),

  setId: (id) => set({ id }),
  setName: (name) => set({ name }),
  setDescription: (description) => set({ description }),
  setDomains: (domains) => set({ domains }),
  setQueryKey: (queryKey) => set({ queryKey }),
  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
}));
