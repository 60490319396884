import { useEffect, useState } from "react";
import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  InputNumber,
  Input,
  message,
} from "antd";
import { SaveOutlined, PercentageOutlined } from "@ant-design/icons";
import { useServiceFee } from "./store";
import { useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import { editServiceFee, createServiceFee } from "../../api";

const { Title } = Typography;
const { TextArea } = Input;

export const PureCreate = () => {
  const {
    isCreateModal,
    setIsCreateModal,
    id,
    setId,
    name,
    description,
    domains,
    setName,
    setDescription,
    max,
    setMax,
    min,
    setMin,
    value,
    setValue,
  } = useServiceFee();

  const [maxValCheck, setMaxValCheck] = useState(null);

  const [form] = Form.useForm();

  let resetState = () => {
    setId(null);
    setName(null);
    setDescription(null);
    setMax(null);
    setMin(null);
    setValue(null);
    setMaxValCheck(null);
    form.resetFields();
  };

  useEffect(() => {
    if (id) {
      form.setFieldsValue({
        name,
        description,
        domains,
        max,
        min,
        value,
      });
    } else {
      resetState();
    }
  }, [id]);

  const { mutate, isLoading: addLoading } = useMutation(createServiceFee, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        resetState();
        setIsCreateModal(false);
        queryClient.invalidateQueries("serviceFee");
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });
  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editServiceFee,
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          resetState();
          setIsCreateModal(false);
          queryClient.invalidateQueries("serviceFee");
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const onFinish = (valuse) => {
    if (id) {
      valuse.id = id;
      editMutate(valuse);
    } else {
      mutate(valuse);
    }
  };

  return (
    <Modal
      forceRender
      title={<Title level={5}>{id ? "Edit" : "Create"} Service Fee</Title>}
      className="app-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetState();
        setIsCreateModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={500}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        form={form}
        onFinish={onFinish}
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
              <Input placeholder={"Baly Express"} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Description"
              name="description"
              rules={[{ required: false }]}
            >
              <TextArea placeholder={"Note"} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Value"
              name="value"
              rules={[{ required: true, message: "required" }]}
            >
              <InputNumber
                style={{ width: "100%" }}
                max={100}
                min={0}
                addonAfter={<PercentageOutlined />}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Max Value"
              name="max"
              rules={[{ required: true, message: "required" }]}
            >
              <InputNumber
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={(val) => setMaxValCheck(val)}
                style={{ width: "100%" }}
                min={0}
                addonAfter="IQD"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Min Value"
              name="min"
              rules={[
                { required: true, message: "required" },
                {
                  type: "number",
                  min: 0,
                  max: maxValCheck ? maxValCheck - 1 : max && max - 1,
                  message: "must be less than max value",
                },
              ]}
            >
              <InputNumber
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                style={{ width: "100%" }}
                max={maxValCheck ? maxValCheck - 1 : max && max - 1}
                min={0}
                addonAfter="IQD"
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={addLoading || editLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
