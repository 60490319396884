import { Card, Button, Breadcrumb } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { PuerTable } from "./table";
import { PureCreate } from "./create";
import { useInternalProduct } from "./store";
import authFunc from "../../helper/auth";

export const InternalProductCategoryPage = () => {

  const { setIsCreateModal } = useInternalProduct();


  return (
    <div className="app-page">
      <Breadcrumb style={{ marginBottom: 10 }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Settings</Breadcrumb.Item>
        <Breadcrumb.Item>Internal Product Category Settings</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <div className="app-space-between">
          <Button
            onClick={() => setIsCreateModal(true)}
            type="primary"
            icon={<PlusOutlined />}
            disabled={!authFunc("INTERNAL_PRODUCT_GROUP_CREATE")}
          >
            Create New
          </Button>
        </div>
        <br />
        <PuerTable />
        <PureCreate />
      </Card>
    </div>
  );
};
