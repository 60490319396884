import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Polygon, useMap } from "react-leaflet";
import "react-leaflet-draw";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import { useRegion } from "./store";
import L from "leaflet";

export const PureMapDrow = () => {
  const { setArea, area, createMapCenter } = useRegion();
  const [reverseArea, setreverseArea] = useState();

  const [isRender, setIsRender] = useState(false);

  const reverseCords = (cords) => {
    return cords.map((item) => {
      return item.map((item) => {
        return [item[1], item[0]];
      });
    });
  };

  useEffect(() => {
    if (area) {
      setreverseArea(reverseCords(area?.coordinates));
    }
  }, [area]);

  const MapComponent = () => {
    const map = useMap();
    useEffect(() => {
      if (createMapCenter) {
        const _center = createMapCenter?.lat
          ? [createMapCenter?.lat, createMapCenter?.lon]
          : [createMapCenter[1], createMapCenter[0]];
        map.flyTo(_center, map.getZoom());
      }
    }, [createMapCenter]);
    return null;
  };

  const ToolsButtons = () => {
    const map = useMap();

    const drawnItems = new L.FeatureGroup();
    map.addLayer(drawnItems);

    const drawControlFull = new L.Control.Draw({
      position: "topright",
      edit: {
        featureGroup: drawnItems,
      },
      draw: {
        rectangle: false,
        polyline: false,
        circle: false,
        circlemarker: false,
        marker: false,
        polygon: true,
      },
    });

    const drawControlEditOnly = new L.Control.Draw({
      position: "topright",
      edit: {
        featureGroup: drawnItems,
      },
      draw: false,
    });

    if (!isRender) {
      map.addControl(drawControlFull);
      setIsRender(true);
    }

    map.on(L.Draw.Event.CREATED, (e) => {
      if (!isRender) {
        setArea({
          coordinates: e.layer.toGeoJSON().geometry.coordinates,
          type: e.layer.toGeoJSON().geometry.type,
        });
        drawnItems.addLayer(e.layer);
        map.removeControl(drawControlFull);
        map.addControl(drawControlEditOnly);
      }
    });
    map.on(L.Draw.Event.EDITED, (e) => {
      if (!isRender) {
        setArea({
          coordinates: e.layers.toGeoJSON().features[0].geometry.coordinates,
          type: "polygon",
        });
        map.removeControl(drawControlFull);
        map.addControl(drawControlEditOnly);
      }
    });

    map.on(L.Draw.Event.DELETED, () => {
      if (!isRender) {
        setArea(null);
        if (drawnItems.getLayers().length === 0) {
          map.removeControl(drawControlEditOnly);
          map.addControl(drawControlFull);
        }
      }
    });

    return null;
  };

  return (
    <MapContainer
      zoomControl={false}
      zoom={10}
      scrollWheelZoom={true}
      attributionControl={false}
      style={{ height: "400px" }}
      center={[33.312805, 44.361488]}
    >
      <ToolsButtons />
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://osrm.baly.dev/osm/{z}/{x}/{y}.png"
      />
      {area &&
        area?.coordinates &&
        reverseArea &&
        reverseArea?.map((item, index) => {
          return (
            <>
              <Polygon
                key={index}
                positions={item}
                pathOptions={{
                  color: "blue",
                  fillColor: "blue",
                }}
              />
            </>
          );
        })}
      <MapComponent />
    </MapContainer>
  );
};
