import create from "zustand";

export const useSubProductGroup = create((set) => ({
  id: null,
  name: null,
  multiSelect: null,
  priority: null,
  productID: null,
  isCreateModal: false,
  status: false,
  
  queryKey: "",
  
  setQueryKey: (queryKey) => set({ queryKey }),

  setId: (id) => set({ id }),
  setName: (name) => set({ name }),
  setMultiSelect: (multiSelect) => set({ multiSelect }),
  setPriority: (priority) => set({ priority }),
  setProductID: (productID) => set({ productID }),
  setStatus: (status) => set({ status }),
  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
}));
