import {
  Breadcrumb,
  Button,
  Card,
  Form,
  InputNumber,
  message,
  Tooltip,
} from "antd";
import { useMutation, useQuery } from "react-query";
import {
  getVendorPerformanceCycleSettings,
  putVendorPerformanceCycleSettings,
} from "../../api";
import { queryClient } from "../../queryClient";
import authFunc from "../../helper/auth";

export const VendorPerformanceCycleSettings = () => {
  const [form] = Form.useForm();

  const { data } = useQuery(
    ["cycleSettings"],
    getVendorPerformanceCycleSettings,
    {
      retry: 2,
      refetchOnWindowFocus: false,
    }
  );

  const { mutate, isLoading } = useMutation(
    async (values) => await putVendorPerformanceCycleSettings(values)
  );

  form.setFieldsValue({
    minOrders: parseInt(data?.data?.minOrders),
    defaultScore: parseInt(data?.data?.defaultScore),
  });

  const onFinish = (values) => {
    mutate(values, {
      onSuccess: (response) => {
        queryClient.invalidateQueries("cycleSettings");
        message.success(response.msg);
      },
    });
  };

  return (
    <div className="app-page">
      <Breadcrumb style={{ marginBottom: 10 }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Vendor Performance</Breadcrumb.Item>
        <Breadcrumb.Item>Cycle Settings</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <h1>Vendor Performance Cycle Settings</h1>

        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            label="Cycle Threshold"
            name="minOrders"
            required
            rules={[
              { required: true, message: "Please input your cycle threshold!" },
              { type: "number", message: "Please input a valid number!" },
              {
                validator: (_, value) => {
                  if (value < 0) {
                    return Promise.reject(
                      "The cycle threshold must be greater than 0!"
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <InputNumber
              style={{
                width: "100%",
              }}
              disabled={isLoading}
            />
          </Form.Item>
          <Form.Item
            label="Default Score for New Vendors"
            name="defaultScore"
            required
            rules={[
              {
                required: true,
                message: "Please input the default threshold!",
              },
              { type: "number", message: "Please input a valid number!" },
              {
                validator: (_, value) => {
                  if (value < 0) {
                    return Promise.reject(
                      "The default threshold must be greater than 0!"
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <InputNumber
              style={{
                width: "100%",
              }}
              disabled={isLoading}
            />
          </Form.Item>
          <Form.Item>
            <Tooltip
              placement="top"
              title={
                !authFunc("CYCLE_SETTINGS_CREATE")
                  ? "You don't have permission to save"
                  : null
              }
            >
              <Button
                type="primary"
                htmlType="submit"
                loading={isLoading}
                disabled={isLoading || !authFunc("CYCLE_SETTINGS_CREATE")}
              >
                Save
              </Button>
            </Tooltip>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};
