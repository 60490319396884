import {
  Table,
  Space,
  Button,
  Typography,
  Popover,
  Alert,
  message,
  Popconfirm,
} from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useQuery, useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import { getContracts, removeContract } from "../../api";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { useApp } from "../../store";
import { useContract } from "./store";
import authFunc from "../../helper/auth";

const { Text } = Typography;

export const PureTable = () => {
  const { setIsLogin } = useApp();
  const { id } = useParams();
  const {
    setIsCreateModal,
    queryKey,
    setId,
    setEndDate,
    setPercenage,
    setResturantOwnerEmail,
    setResturantOwnerName,
    setResturantOwnerPhoneNo,
    setStartDate,
    setVendorID,
    setContractImg,
  } = useContract();
  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Image",
      dataIndex: "contractImg",
      key: "contractImg",
      render: (img) => (
        <Popover
          content={
            <img
              style={{ width: 240, height: 120, objectFit: "cover" }}
              src={img}
            />
          }
        >
          <img
            style={{
              width: 80,
              height: 50,
              borderRadius: 5,
              objectFit: "cover",
            }}
            src={img}
          />
        </Popover>
      ),
    },

    {
      title: "Owner Name",
      dataIndex: "resturantOwnerName",
      key: "resturantOwnerName",
      render: (text, row) => <Text style={{ fontWeight: "bold" }}>{text}</Text>,
    },

    {
      title: "Phone",
      dataIndex: "resturantOwnerPhoneNo",
      key: "resturantOwnerPhoneNo",
      //render: (text) => <Text style={{ fontSize: 12 }}>{text}</Text>,
    },
    {
      title: "Email",
      dataIndex: "resturantOwnerEmail",
      key: "resturantOwnerEmail",
      //render: (text) => <Text style={{ fontSize: 12 }}>{text}</Text>,
    },
    {
      title: "Resturant",
      dataIndex: "vendor",
      key: "vendor",
      //render: (text) => <Text style={{ fontSize: 12 }}>{text}</Text>,
    },
    {
      title: "Percenage",
      dataIndex: "percenage",
      key: "percenage",
      render: (text) => <Text>{text} %</Text>,
    },

    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (text) => (
        <Text type="secondary">{dayjs(text).format("MMMM D, YYYY")}</Text>
      ),
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (text) => (
        <Text type="secondary">{dayjs(text).format("MMMM D, YYYY")}</Text>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id, row) => (
        <Space>
          {authFunc("VENDOR_CONTRACT_UPDATE") && (
            <Button
              onClick={() => handleEdit(row)}
              size="small"
              icon={<EditOutlined />}
            />
          )}
          {authFunc("VENDOR_CONTRACT_DELETE") && (
            <Popconfirm
              title="Are you sure to delete this task?"
              onConfirm={() => mutate(id)}
              okText="Yes"
              cancelText="No"
            >
              <Button size="small" danger icon={<DeleteOutlined />} />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  const handleEdit = (row) => {
    setIsCreateModal(true);
    setId(row.id);
    setEndDate(row.endDate);
    setPercenage(row.percenage);
    setResturantOwnerEmail(row.resturantOwnerEmail);
    setResturantOwnerName(row.resturantOwnerName);
    setResturantOwnerPhoneNo(row.resturantOwnerPhoneNo);
    setStartDate(row.startDate);
    setVendorID(row.vendorID);
    setContractImg(row.contractImg);
  };

  const { mutate, isLoading: addLoading } = useMutation(removeContract, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        queryClient.invalidateQueries("contracts");
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(["contracts", id, queryKey], getContracts, {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  if (error || (!data?.success && data?.data?.err))
  return (
    <Alert
      message={data?.data?.err || error?.message}
      type="error"
      showIcon
    />
  );

  return  (
    <Table
      rowKey={(record) => record.id}
      loading={loading || addLoading}
      columns={columns}
      dataSource={data ? data.data.records : []}
      size="small"
      pagination={false}
    />
  );
};
