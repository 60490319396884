import React from "react";
import { Modal, Typography, Descriptions, List } from "antd";
import { useWaterfallProcessAction } from "./store";

const { Title } = Typography;

const DeliveryFeeModal = () => {
  const { isDelieveryfeeModal, setIsDelieveryfeeModal, delieveryModalData } =
    useWaterfallProcessAction();

  const numberForamt = (number) =>
    String(number).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

  return (
    <Modal
      title={<Title level={5}>Delivery Fee Details</Title>}
      className="app-modal"
      centered
      visible={isDelieveryfeeModal}
      onCancel={() => {
        setIsDelieveryfeeModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={500}
      bodyStyle={{ overflowY: "auto", maxHeight: "85vh" }}
    >
      {delieveryModalData && (
        <Descriptions bordered column={1}>
          <Descriptions.Item label="ID">
            {delieveryModalData.id}
          </Descriptions.Item>
          <Descriptions.Item label="Name">
            {delieveryModalData.name}
          </Descriptions.Item>
          <Descriptions.Item label="Domains">
            <List
              dataSource={delieveryModalData.domains}
              renderItem={(item) => (
                <List.Item>
                  <Descriptions bordered column={1}>
                    <Descriptions.Item label="Domain ID">
                      {item.id}
                    </Descriptions.Item>
                    <Descriptions.Item label="From">
                      {item.from} KM
                    </Descriptions.Item>
                    <Descriptions.Item label="To">
                      {item.to} KM
                    </Descriptions.Item>
                    <Descriptions.Item label="Value">
                      {numberForamt(item.value)} IQD
                    </Descriptions.Item>
                  </Descriptions>
                </List.Item>
              )}
            />
          </Descriptions.Item>
        </Descriptions>
      )}
    </Modal>
  );
};

export default DeliveryFeeModal;
