import {
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Spin,
  message,
  Select,
  Card,
  Space,
} from "antd";
import { SaveOutlined, DeleteOutlined } from "@ant-design/icons";
import { useAppBanner } from "./store";
import { useQuery, useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import {
  getCategories,
  categoryToBanner,
  getAppbannersCategory,
} from "../../api";
import { useEffect, useState } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import Avatar from "antd/lib/avatar/avatar";
import { arrayMoveImmutable } from "array-move";
const { Title, Text } = Typography;
const { Option } = Select;

export const AddCategories = () => {
  const { id, setIsAddCat, isAddCat, categories, type, setId, setCategories } =
    useAppBanner();
  const [list, setList] = useState([]);
  const [filter, setFilter] = useState("");

  const CategoryItem = SortableElement(({ value }) => (
    <Card
      bordered={false}
      bodyStyle={{ padding: "10px 16px", backgroundColor: "#f6f6f6" }}
      style={{ marginBottom: 10 }}
    >
      <div className="app-space-between" style={{ alignItems: "center" }}>
        <Space size={16}>
          <Avatar size="large" src={value.img} />
          <Space direction="vertical" size={0}>
            <Text>{value.name}</Text>
            <Text type="secondary" style={{ fontSize: 12 }}>
              {value.description}
            </Text>
          </Space>
        </Space>
        <Button
          onClick={(e) => {
            e.stopPropagation();

            setList(list.filter((el) => el.id != value.id));
          }}
          icon={<DeleteOutlined />}
          size="small"
          type="text"
          danger
        />
      </div>
    </Card>
  ));
  const SortableList = SortableContainer(({ items }) => {
    return (
      <div>
        {items.map((value, index) => (
          <CategoryItem key={`item-${index}`} index={index} value={value} />
        ))}
      </div>
    );
  });

  const { isLoading: categorieLoading, data } = useQuery(
    ["categories", filter],
    getCategories,
    {
      refetchOnWindowFocus: false,
      retry: 2,
      enabled: !!isAddCat,
    }
  );
  const { isLoading: bannerLoading, data: bannerData } = useQuery(
    ["appbanners/categories", filter, id],
    getAppbannersCategory,
    {
      refetchOnWindowFocus: false,
      retry: 2,
      enabled: !!isAddCat,
    }
  );

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    categoryToBanner,
    {
      onSuccess: (data) => {
        if (data?.success) {
          message.success(data?.msg);
          setIsAddCat(false);
          queryClient.invalidateQueries("appBanner");
          resetState();
        } else {
          message.error(data?.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  let onSortEnd = ({ oldIndex, newIndex }) => {
    setList(arrayMoveImmutable(list, oldIndex, newIndex));
  };

  const isRepeated = (id) => (list.find((el) => el.id == id) ? true : false);

  const handleSave = () => {
    let dataForm = {
      category: list.map((el, index) => {
        return { id: el.id, priority: index + 1 };
      }),
      id,
    };
    editMutate(dataForm);
  };

  const resetState = () => {
    setId(null);
    setCategories([]);
  };

  useEffect(() => {
    if (id) {
      setList(bannerData && bannerData.data ? bannerData?.data : []);
    }
  }, [id, bannerData]);

  return (
    <Modal
      title={<Title level={5}>Category to Banner</Title>}
      className="app-modal"
      centered
      visible={isAddCat}
      bodyStyle={{ paddingTop: 5 }}
      onCancel={() => {
        resetState();
        setIsAddCat(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={500}
    >
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <Select
            showSearch
            filterOption={true}
            optionFilterProp="children"
            onSearch={(val) => setFilter(val)}
            onChange={(val) => {
              if (val) {
                if (type == "BANNER") {
                  setList([data?.data?.records?.find((el) => el.id == val)]);
                } else {
                  setList([
                    ...list,
                    data?.data?.records?.find((el) => el.id == val),
                  ]);
                }
              }
              // if (val) {
              //   setList([
              //     ...list,
              //     categories.data.records.find((el) => el.id == val),
              //   ]);
              // }
            }}
            style={{ width: "100%" }}
            placeholder="Find categorie"
            allowClear
          >
            {!categorieLoading ? (
              data?.success ? (
                data?.data?.records?.map((el) => (
                  <Option
                    key={el.id}
                    id={el.id}
                    value={el.id}
                    disabled={isRepeated(el.id)}
                  >
                    {el.name}
                  </Option>
                ))
              ) : null
            ) : (
              <Spin spinning={true} />
            )}
          </Select>
        </Col>
        {/* <Col span={24}>
          {list.map((item) => (
            <li key={item.id}>{item.arName}</li>
            ))}
          </Col> */}
        <Col span={24}>
          <div style={{ minHeight: 400, maxHeight: "70vh", overflow: "auto" }}>
            <SortableList distance={1} items={list} onSortEnd={onSortEnd} />
          </div>
        </Col>
        <Col span={24}>
          <Button
            loading={editLoading}
            type="primary"
            onClick={handleSave}
            icon={<SaveOutlined />}
          >
            Save
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};
