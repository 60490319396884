import create from "zustand";

export const useInternalProduct = create((set) => ({
  isCreateModal: false,
  id: null,
  internal_name: null,
  setId: (id) => set({ id }),
  setInternalName: (internal_name) => set({ internal_name }),
  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
}));
