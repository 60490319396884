import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  Input,
  message,
  Space,
  Checkbox,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useApp } from "../../store";
import { useEffect } from "react";
import { useMutation } from "react-query";
import { editeProfile } from "../../api";
import { useState } from "react";

const { Title } = Typography;

export const EditAccount = () => {
  const [phoneValidation, setPhoneValidation] = useState(false);
  const {
    isEditAccount,
    setIsEditAccount,
    setIsLogin,
    setUser,
    user,
    setIsQrModal,
  } = useApp();
  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation(editeProfile, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        setIsEditAccount(false);
        user.name = data.data.name;
        user.username = data.data.username;
        user.email = data.data.email;
        user.phone = data.data.phone;
        // user.twofaEnabled = data.data.twofaEnabled;

        setUser(user);
      } else {
        message.error(data.msg);
      }
      setPhoneValidation(false);
    },
    onError: (e) => {
      message.error(e.message);
    },
  });
  const phoneValidate = (phone) => {
    const regex = /^07[3-9]\d{8}$/;
    const result = regex.exec(phone);
    return result;
  };

  const onFinish = (values) => {
    if (phoneValidate(values.phone)) {
      // values.phone = phone;
      mutate(values);
    } else {
      setPhoneValidation(true);
    }
  };

  useEffect(() => {
    let _user = localStorage.getItem("bo_user");
    if (_user) {
      setUser(JSON.parse(_user));
    } else {
      localStorage.removeItem("bo_token");
      localStorage.removeItem("bo_refresh_token");
      localStorage.removeItem("bo_user");
      setIsLogin(false);
    }
  }, []);

  return (
    <Modal
      title={<Title level={5}>Edit Account</Title>}
      className="app-modal"
      centered
      visible={isEditAccount}
      onCancel={() => {
        setIsEditAccount(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={400}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        form={form}
        initialValues={{
          remember: true,
          name: user && user.name,
          username: user && user.username,
          email: user && user.email,
          phone: user && user.phone,
          // twofaEnabled: user?.twofaEnabled,
        }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
              <Input placeholder="Murtadha M. Abed" />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              label="Username"
              name="username"
              rules={[{ required: true }]}
            >
              <Input placeholder={"baly001"} />
            </Form.Item>
          </Col>
          <Col span={14}>
            <Form.Item
              label="Phone Number"
              name="phone"
              rules={[{ required: true }]}
            >
              <Input
                placeholder={"07**** ****"}
                status={phoneValidation ? "error" : ""}
                maxLength="11"
                minLength="11"
                // onChange={(e) => setPhoneState(e.target.value)}
                // value={phone}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Email" name="email" rules={[{ type: "email" }]}>
              <Input placeholder={"example@baly.iq"} />
            </Form.Item>
          </Col>
          {/* <Col span={24}>
            <Form.Item name="twofaEnabled" valuePropName="checked">
              <Checkbox
                onClick={() => {
                  if (!user.twofaEnabled) {
                    setIsEditAccount(false);
                    setIsQrModal(true);
                  }
                }}
              >
                <a>Two-factor authentication</a>
              </Checkbox>
            </Form.Item>
          </Col> */}

          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={isLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
