import create from "zustand";

export const useVendorPerformanceScore = create((set) => ({
  id: null,
  vendorID: null,
  cityID: null,
  from: null,
  to: null,
  page: 1,

  setPage: (page) => set({ page }),
  setFrom: (from) => set({ from }),
  setTo: (to) => set({ to }),
  setCityID: (cityID) => set({ cityID }),
  setVendorID: (vendorID) => set({ vendorID }),
  setId: (id) => set({ id }),
}));
