import React from "react";
import { Select, DatePicker, Space } from "antd";
import { useFreeDelivery } from "./store";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

const { RangePicker } = DatePicker;
const { Option } = Select;

export default function FreeDeliveryFilters() {
  const { setPage, setStatus, status, from, setFrom, to, setTo } =
    useFreeDelivery();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    let obj = {
      status: searchParams.get("status") || null,
      from: searchParams.get("from") || null,
      to: searchParams.get("to") || null,
      page_num: searchParams.get("page_num") || null,
    };

    setStatus(obj.status);
    setFrom(obj.from);
    setTo(obj.to);
    setPage(obj.page_num);
  }, [
    searchParams.get("status"),
    searchParams.get("from"),
    searchParams.get("to"),
    searchParams.get("page_num"),
  ]);

  const SelectTimeDate = (value, dateString) => {
    let from = JSON.stringify(dateString[0]);
    let to = JSON.stringify(dateString[1]);

    if (value) {
      setFrom(from);
      setTo(to);
      searchParams.set("from", from);
      searchParams.set("to", to);
      searchParams.set("page_num", 1);
      setSearchParams(searchParams);
    } else {
      setFrom(null);
      setTo(null);
      searchParams.set("page_num", 1);
      searchParams.delete("from");
      searchParams.delete("to");
      setSearchParams(searchParams);
    }
  };

  return (
    <div className="filter-wrapper">
      <div className="item">
        <Select
          filterOption={false}
          dropdownMatchSelectWidth={false}
          allowClear
          onChange={(val) => {
            if (val != undefined) {
              setStatus(val);
              searchParams.set("status", val);
              searchParams.set("page_num", 1);
              setSearchParams(searchParams);
            }
          }}
          onClear={() => {
            setStatus(null);
            searchParams.set("page_num", 1);
            searchParams.delete("status");
            setSearchParams(searchParams);
          }}
          placeholder="Select Status"
          style={{ width: "100%" }}
          value={status}
        >
          <Option value={"true"}>Active</Option>
          <Option value={"false"}>InActive</Option>
        </Select>
      </div>
      <div className="item">
        <Space direction="vertical" size={5}>
          <RangePicker
            value={
              from && to
                ? [
                    moment(from, "YYYY-MM-DD HH:mm"),
                    moment(to, "YYYY-MM-DD HH:mm"),
                  ]
                : []
            }
            showTime={{
              format: "HH:mm",
            }}
            format="YYYY-MM-DD HH:mm"
            onChange={SelectTimeDate}
          />
        </Space>
      </div>
    </div>
  );
}
