import React from "react";
import { Select } from "antd";
import { useReviews } from "../../store";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const { Option } = Select;

export default function ProductReviewFilters() {
  const { productsStatus, setProductsStatus, setProductsPage } = useReviews();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    let obj = {
      productsStatus: searchParams.get("status") || null,
      page_num: searchParams.get("page_num") || null,
    };

    setProductsStatus(obj.productsStatus);
    setProductsPage(obj.page_num);
  }, [searchParams.get("productsStatus"), searchParams.get("page_num")]);

  return (
    <div className="filter-wrapper">
      <div className="item">
        <Select
          filterOption={false}
          dropdownMatchSelectWidth={false}
          allowClear
          onChange={(val) => {
            if (val != undefined) {
              setProductsStatus(val);
              searchParams.set("status", val);
              searchParams.set("page_num", 1);
              setSearchParams(searchParams);
            }
          }}
          onClear={() => {
            setProductsStatus(null);
            searchParams.delete("status");
            searchParams.set("page_num", 1);
            setSearchParams(searchParams);
          }}
          placeholder="Select Status"
          style={{ width: "100%" }}
          value={productsStatus}
        >
          <Option value={"ACCEPTED"}>Accepted</Option>
          <Option value={"REJECTED"}>Rejected</Option>
          <Option value={"PENDING"}>Pending</Option>
        </Select>
      </div>
    </div>
  );
}
