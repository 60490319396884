import { Card, Input, Space, Button, Breadcrumb } from "antd";
import { PlusOutlined, FileExcelOutlined } from "@ant-design/icons";
import { PureTable } from "./table";
import { PureCreateBulk } from "./create";
import { useBulk } from "./store";
import { PureCreate } from "../discounts/create";
import { PureAddVendores } from "../discounts/options/addVendors";
import { PureAddCitents } from "../discounts/options/addCities";
import { PureAddCategories } from "../discounts/options/addCategories";
import { PureAddClients } from "../discounts/options/addClients";
// import VoucherFilters from "../comonents/Filters/voucherFilters";
import authFunc from "../../helper/auth";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const { Search } = Input;

export const BulkVouchersPage = () => {
  const { setIsCreateModal, setQueryKey, setIsExport, queryKey } = useBulk();
  const [search, setSearch] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (queryKey) {
      setSearch(queryKey);
    }
  }, []);

  useEffect(() => {
    if (searchParams.get("q")) {
      setSearch(searchParams.get("q"));
      setQueryKey(searchParams.get("q"));
    } else {
      setSearch("");
      setQueryKey("");
    }
  }, [searchParams.get("q")]);

  return (
    <div className="app-page">
      <Breadcrumb style={{ marginBottom: 10 }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Marketing</Breadcrumb.Item>
        <Breadcrumb.Item>Bulk Vouchers</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <div className="app-space-between">
          <Space>
            <Search
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onSearch={(val) => {
                searchParams.set("q", val);
                searchParams.set("page_num", 1);
                setSearchParams(searchParams);
                setQueryKey(val);
              }}
              style={{ width: 340 }}
              placeholder="Write To Search..."
            />
            {/* <VoucherFilters /> */}
          </Space>

          <Space>
            <Button
              onClick={() => setIsExport(true)}
              type="primary"
              icon={<FileExcelOutlined />}
            >
              Export To Excel
            </Button>
            {authFunc("VOUCHER_CREATE") && (
              <Button
                onClick={() => setIsCreateModal(true)}
                type="primary"
                icon={<PlusOutlined />}
              >
                Create New
              </Button>
            )}
          </Space>
        </div>
        <br />
        <PureTable />
        <PureCreateBulk />

        <PureCreate />
        <PureAddVendores />
        <PureAddCitents />
        <PureAddCategories />
        <PureAddClients />
      </Card>
    </div>
  );
};
