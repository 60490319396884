import { Typography, Modal, Row, Col } from "antd";
import { useAppBanner } from "./store";
import { PureMap } from "./map";

const { Title } = Typography;

export const PreviewModal = () => {
  const { isPreview, setIsPrivew, lat, lon } = useAppBanner();

  const url =
    process.env.REACT_APP_IS_DEV === "true"
      ? `https://app.baly.dev/?lat=${lat}&lon=${lon}`
      : `https://app.food.baly.iq/?lat=${lat}&lon=${lon}`;

  return (
    <Modal
      title={<Title level={5}>App Home Page</Title>}
      className="app-modal"
      centered
      visible={isPreview}
      bodyStyle={{ paddingTop: 5 }}
      onCancel={() => {
        setIsPrivew(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={"80%"}
    >
      <div className="preview-modal">
        <Row gutter={[40, 20]}>
          <Col flex="410px">
            <div className="mobile-card">
              <div className="content">
                <iframe src={url} title="Baly Food"></iframe>
              </div>
            </div>
          </Col>
          <Col flex="auto">
            <PureMap />
          </Col>
        </Row>
      </div>
    </Modal>
  );
};
