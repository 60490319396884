import React from "react";
import { useOrder } from "../store";
import { InvoiceInfo } from "../invoice";
import { getDeliveryDiscountByVendor } from "../../../api";
import { useQuery } from "react-query";

export const GetProducts = () => {

  const { vendor, client } = useOrder();
  const { isLoading: deliveryDiscountLoading, data: deliveryDiscountData } =
    useQuery(["deliveryDiscount", vendor?.id], getDeliveryDiscountByVendor, {
      refetchOnWindowFocus: false,
      retry: 2,
      client: !!client,
    });
  
  return (
    <div
      className="step-container order-product"
      style={{ maxHeight: 510, overflowX: "clip", overflowY: "auto" }}
    >
      <InvoiceInfo
        isCreate
        client={client}
        vendor={vendor}
        usedVendor={vendor}
        deliveryDiscountValue={deliveryDiscountData?.data}
      />
    </div>
  );
};
