import { Button, Modal, Typography, message } from "antd";
import dayjs from "dayjs";
import { useProduct } from "./store";
import { CustomeSchedule } from "../../comonents/global";
import { useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import { SaveOutlined } from "@ant-design/icons";
import { editProductSchedule } from "../../api";

const { Title } = Typography;

export const ProductSchedule = () => {
  const {
    isScheduleModal,
    setIsScheduleModal,
    schedule,
    setSchedule,
    id,
    setId,
  } = useProduct();

  const { mutate, isLoading } = useMutation(
    (e) => editProductSchedule("product/schedule", e),
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          resetState();
          queryClient.invalidateQueries("products");
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const handleSave = async () => {
    let isError = false;
    await schedule.map((el) => {
      el.workingTime.map((time) => {
        if (time.from == null) {
          isError = true;
        }
      });
    });

    if (isError) {
      message.error("There is Empty Cell !");
    } else {
      let _workingSchedule = JSON.stringify(
        schedule.map((t) => {
          t.workingTime = t.workingTime.map((w) => ({
            from: dayjs(w.from).toDate(),
            to: dayjs(w.to).toDate(),
          }));
          return { ...t };
        })
      );
      //row.BusinessCategoryIDS = row?.businessCategories ? JSON.stringify(row?.businessCategories?.map(el => el?.id)) : "[]";
      let data = {
        id,
        schedule: _workingSchedule,
      };
      // row.tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      mutate(data);
    }
  };

  const resetState = () => {
    setIsScheduleModal(false);
    setId(null);
    setSchedule(null);
  };
  return (
    <Modal
      title={
        <Title style={{ marginBottom: 0 }} level={5}>
          Product Schedule
        </Title>
      }
      className="app-modal"
      centered
      visible={isScheduleModal}
      onCancel={() => resetState()}
      footer={false}
      destroyOnClose={true}
      width={"90vw"}
      bodyStyle={{ paddingTop: 10, minHeight: "75vh" }}
    >
      <CustomeSchedule
        value={schedule}
        onChange={(val) => {
          setSchedule(val);
        }}
        fromInit={12}
      />

      <Button
        loading={isLoading}
        style={{ position: "absolute", bottom: 35, left: 35 }}
        type="primary"
        onClick={handleSave}
        icon={<SaveOutlined />}
      >
        Save
      </Button>
    </Modal>
  );
};
