import { useEffect, useState } from "react";
import { useVendorLog } from "./store";
import { Table, Typography, Alert, Pagination, Tooltip, Button } from "antd";
import { HistoryOutlined, SafetyCertificateOutlined } from "@ant-design/icons";
import { useQuery } from "react-query";
import { getVendorLog, pageSize } from "../../api";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import { WorkingSchedule } from "./workingSchedule";
const { Text } = Typography;

export const PuerTable = () => {
  const { queryKey, setIsStatusModal, setHistoryData, from, to } =
    useVendorLog();
  const [page, setPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const handleHistory = (values) => {
    setHistoryData(values);
    setIsStatusModal(true);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Vendor Name",
      dataIndex: "vendorName",
      key: "vendorName",
    },
    {
      title: "Resource Name",
      dataIndex: "resourceName",
      key: "resourceName",
    },
    {
      title: "Client",
      dataIndex: "client",
      key: "client",
      render: (val) => <Text>{val !== "" ? val : "..."}</Text>,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text) => <Text style={{ fontWeight: "bold" }}>{text}</Text>,
    },
    {
      title: "previous Status",
      dataIndex: "prevStatus",
      key: "prevStatus",
      render: (val) => (
        <>
          <Tooltip placement="top" title="Show previous Status">
            <Button
              onClick={() => handleHistory(val)}
              size="small"
              icon={<HistoryOutlined />}
            >
              previous Status
            </Button>
          </Tooltip>
        </>
      ),
    },
    {
      title: "Current Status",
      dataIndex: "currentStatus",
      key: "currentStatus",
      render: (val) => (
        <>
          <Tooltip placement="top" title="Show Current Status">
            <Button
              onClick={() => handleHistory(val)}
              size="small"
              icon={<SafetyCertificateOutlined />}
            >
              Current Status
            </Button>
          </Tooltip>
        </>
      ),
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (el) => <Text>{dayjs(el).format("YYYY-MM-DD hh:mm A")}</Text>,
    },
  ];

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(["referralCodes", page, queryKey, from, to], getVendorLog, {
    refetchOnWindowFocus: false,
    retry: 2,
    enabled: !!from,
  });

  if (error || (!data?.success && data?.data?.err))
    return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />
    );

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data ? data?.data?.records : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        defaultPageSize={pageSize}
        total={data && data?.data?.total}
        showSizeChanger={false}
      />
      <WorkingSchedule />
    </>
  );
};
