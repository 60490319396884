import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  Input,
  message,
  Upload,
  InputNumber,
  Select,
  DatePicker,
} from "antd";
import { SaveOutlined, InboxOutlined } from "@ant-design/icons";
import { useAppBanner } from "./store";
import { PureMapDrow } from "./mapDrow";
import { useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import { createBanner, editeBanner } from "../../api";
import moment from "moment";
import { useEffect } from "react";
import { CustomeCalender } from "../../comonents/global/customeCalender";
import authFunc from "../../helper/auth";
const { Title } = Typography;
const { Dragger } = Upload;
const { Option } = Select;

export const PureCreate = () => {
  const {
    isCreateModal,
    setIsCreateModal,
    polygon,
    setPolygon,
    setPriorty,
    setQueryKey,
    setId,
    setName,
    setType,
    setImg,
    name,
    type,
    priority,
    id,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    setAppBannerVendor,
    description,
    setDescription,
    workingSchedule,
    setSchedule,
    setMarkerPosition,
    pinned_vendors,
    setPinned_vendors,
    pinned_distance_limit,
    setPinned_distance_limit,
  } = useAppBanner();

  const [form] = Form.useForm();

  const props = {
    name: "file",
    multiple: false,
    maxCount: 1,
    beforeUpload: (file) => {
      return false;
    },
  };

  const resetState = () => {
    setPolygon(null);
    setPriorty(null);
    // setQueryKey(null);
    setId(null);
    setName(null);
    setType(null);
    setImg(null);
    setStartDate(null);
    setEndDate(null);
    setAppBannerVendor([]);
    setDescription(null);
    setSchedule(null);
    setMarkerPosition(null);
    setPinned_vendors(0);
    setPinned_distance_limit(0);
    form.resetFields();
  };

  const { mutate, isLoading } = useMutation(createBanner, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        setIsCreateModal(false);
        queryClient.invalidateQueries("appBanner");
        resetState();
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editeBanner,
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          setIsCreateModal(false);
          queryClient.invalidateQueries("appBanner");
          resetState();
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const onFinish = (values) => {
    if (polygon) {
      if (id) {
        let dataForm = {
          id,
          name: values.name,
          priority: values.priority,
          type: values.type,
          polygon,
          startDate: values.startDate,
          endDate: values.endDate,
          users: "[]",
          description: values.description,
          pinned_distance_limit: values.pinned_distance_limit,
          pinned_vendors: values.pinned_vendors,
          workingSchedule: workingSchedule
            ? JSON.stringify(workingSchedule)
            : "[]",
        };
        editMutate(dataForm);
      } else {
        let dataForm = {
          imgFile: values.img.file,
          data: JSON.stringify({
            appBanner_vendor: [],
            name: values.name,
            priority: values.priority,
            type: values.type,
            polygon,
            startDate: values.startDate,
            endDate: values.endDate,
            description: values.description,
            workingSchedule: workingSchedule
              ? JSON.stringify(workingSchedule)
              : "[]",
          }),
        };
        mutate(dataForm);
      }
    } else {
      message.error("Polygon Not found ");
    }
  };

  useEffect(() => {
    if (id) {
      form.setFieldsValue({
        name,
        type,
        priority,
        description,
        pinned_vendors,
        pinned_distance_limit,
        startDate: startDate ? moment(startDate) : null,
        endDate: endDate ? moment(endDate) : null,
        schedule: workingSchedule,
      });
    } else {
      form.resetFields();
      resetState();
    }
  }, [id]);

  return (
    <Modal
      forceRender
      title={<Title level={5}>{id ? "Edit" : "New"} appBanner</Title>}
      className="app-modal"
      centered
      visible={isCreateModal}
      bodyStyle={{ paddingTop: 5 }}
      onCancel={() => {
        resetState();
        setIsCreateModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={880}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={14}>
            <PureMapDrow />
          </Col>
          <Col span={10}>
            <Row gutter={[20, 20]}>
              <Col span={16}>
                <Form.Item
                  label="Banner Name"
                  name="name"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Banner title" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Priority"
                  name="priority"
                  rules={[{ required: true }]}
                >
                  <InputNumber
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\\s?|(,*)/g, "")}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Banner Description"
                  name="description"
                  rules={[{ required: false }]}
                >
                  <Input placeholder="Banner Description" />
                </Form.Item>
              </Col>
              {!id && (
                <Col span={24}>
                  <Form.Item
                    label="Banner Type"
                    name="type"
                    rules={[{ required: true }]}
                  >
                    <Select>
                      <Option value={"CAROUSEL"}>CAROUSEL</Option>
                      <Option value={"LIST"}>LIST</Option>
                      <Option value={"FOOD_PARTY"}>FOOD_PARTY</Option>
                      <Option value={"SLIDER"}>SLIDER</Option>
                      <Option value={"BANNER"}>BANNER</Option>
                      <Option value={"CATEGORY"}>CATEGORY</Option>
                      <Option value={"NEW"}>NEW</Option>
                      <Option value={"WEEKLY_DISCOUNT"}>WEEKLY_DISCOUNT</Option>
                      {/* <Option value={"BEST_SELLER"}>BEST_SELLER</Option> */}
                    </Select>
                  </Form.Item>
                </Col>
              )}
              <Col span={24}>
                <Form.Item
                  label="Start Date"
                  name="startDate"
                  rules={[{ required: true }]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="End Date"
                  name="endDate"
                  rules={[{ required: true }]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime
                  />
                </Form.Item>
              </Col>
              {id && authFunc("PIN_SETTINGS_CREATE") ? (
                <>
                  <Col span={12}>
                    <Form.Item
                      label="Pinned Vendors"
                      name="pinned_vendors"
                      rules={[{ required: true }]}
                    >
                      <InputNumber
                        defaultValue={0}
                        min={0}
                        style={{ width: "100%" }}
                        onChange={(val) => {
                          setPinned_vendors(val);
                          if (val === 0) {
                            setPinned_distance_limit(0);
                            form.setFieldsValue({
                              pinned_distance_limit: 0,
                            });
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Pinned Distance Limit"
                      name="pinned_distance_limit"
                      rules={[{ required: true }]}
                    >
                      <InputNumber
                        defaultValue={0}
                        min={0}
                        disabled={pinned_vendors === 0}
                        style={{ width: "100%" }}
                        addonAfter={"KM"}
                      />
                    </Form.Item>
                  </Col>
                </>
              ) : null}

              {!id ? (
                <Col span={24}>
                  <Form.Item
                    label="Image"
                    name="img"
                    rules={[{ required: true }]}
                  >
                    <Dragger {...props}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-hint">
                        Click or drag Image to this area
                      </p>
                    </Dragger>
                  </Form.Item>
                </Col>
              ) : null}
            </Row>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Schedule"
              name="schedule"
              rules={[{ required: true, message: "schedule is required" }]}
            >
              <CustomeCalender
                value={id ? workingSchedule : null}
                onChange={(val) => setSchedule(val)}
                isAll={() => null}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={isLoading || editLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
