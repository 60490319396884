import { Card, Input, Button, Breadcrumb, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { PureTable } from "./table";
import { PureCreate } from "./create";
import { OrderView } from "./view";
import { useOrder } from "./store";
import OrderFilters from "./filters";
import authFunc from "../../helper/auth";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { SubProductModal } from "../../comonents/global";

const { Search } = Input;

export const OrdersPage = () => {
  const {
    setQueryKey,
    setQueryID,
    setIsCreateModal,
    queryKey,
    queryID,
    setVoucherCode,
    voucherCode,
  } = useOrder();

  const [search, setSearch] = useState("");
  const [searchID, setSearchID] = useState(null);
  const [searchVoucher, setSearchVoucher] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (queryKey) {
      setSearch(queryKey);
    }
    if (queryID) {
      setSearchID(queryID);
    }
    if (voucherCode) {
      setSearchVoucher(voucherCode);
    }
  }, []);

  useEffect(() => {
    if (searchParams.get("q")) {
      setSearch(searchParams.get("q"));
      setQueryKey(searchParams.get("q"));
    } else {
      setSearch("");
      setQueryKey("");
    }
  }, [searchParams.get("q")]);

  useEffect(() => {
    if (searchParams.get("id")) {
      setSearchID(searchParams.get("id"));
      setQueryID(searchParams.get("id"));
    } else {
      setSearchID("");
      setQueryID("");
    }
  }, [searchParams.get("id")]);
  useEffect(() => {
    if (searchParams.get("voucherCode")) {
      setVoucherCode(searchParams.get("voucherCode"));
      setSearchVoucher(searchParams.get("voucherCode"));
    } else {
      setVoucherCode("");
      setSearchVoucher("");
    }
  }, [searchParams.get("voucherCode")]);

  return (
    <div className="app-page">
      <Breadcrumb style={{ marginBottom: 10 }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Orders</Breadcrumb.Item>
        <Breadcrumb.Item>List</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <div className="app-space-between">
          <Space wrap>
            <Search
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onSearch={(val) => {
                searchParams.set("q", val);
                searchParams.set("page_num", 1);
                setSearchParams(searchParams);
                setQueryKey(val);
              }}
              style={{ width: 250 }}
              placeholder="Search in Track Number"
            />

            <Search
              value={searchID}
              onChange={(e) => setSearchID(e.target.value)}
              onSearch={(val) => {
                searchParams.set("id", val);
                searchParams.set("page_num", 1);
                setSearchParams(searchParams);
                setQueryID(val);
              }}
              style={{ width: 250 }}
              placeholder="Search for order id"
            />

            <Search
              value={searchVoucher}
              onChange={(e) => setSearchVoucher(e.target.value)}
              onSearch={(val) => {
                searchParams.set("voucherCode", val);
                searchParams.set("page_num", 1);
                setSearchParams(searchParams);
                setVoucherCode(val);
              }}
              style={{ width: 250 }}
              placeholder="Search for voucher code"
            />

            <OrderFilters />
          </Space>

          {authFunc("ORDER_CREATE") && (
            <Button
              type="primary"
              onClick={() => setIsCreateModal(true)}
              icon={<PlusOutlined />}
            >
              Create New
            </Button>
          )}
        </div>
        <br />

        <PureTable />
        <PureCreate />
        <OrderView />
        <SubProductModal />
      </Card>
    </div>
  );
};
