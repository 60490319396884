import create from "zustand";

export const useOrder = create((set) => ({
  queryKey: "",
  queryID: null,
  voucherCode: null,
  isCreateModal: false,
  client: null,
  vendor: null,
  products: null,
  voucher: null,
  payment: null,
  id: null,
  isFilter: false,
  queryFilter: "",
  isView: false,
  row: null,
  defaultProducts: [],
  delayedQueryFilter: "",
  filterVendor: "",
  platform: "",
  page: 1,

  // Filters States
  vendorID: null,
  cityID: null,
  clientID: null,
  status: null,
  from: null,
  to: null,
  preOrder: null,

  basket: [],
  voucher: null,

  setBasket: (basket) => set({ basket }),
  setVoucher: (voucher) => set({ voucher }),
  setVendorID: (vendorID) => set({ vendorID }),
  setCityID: (cityID) => set({ cityID }),
  setClientID: (clientID) => set({ clientID }),
  setStatus: (status) => set({ status }),
  setFrom: (from) => set({ from }),
  setTo: (to) => set({ to }),
  setPreOrder: (preOrder) => set({ preOrder }),
  // end of filters states

  setFilterVendor: (filterVendor) => set({ filterVendor }),
  setPlatform: (platform) => set({ platform }),
  // vendorID: null,
  // setVendorID: (vendorID) => set({ vendorID }),
  setVoucherCode: (voucherCode) => set({ voucherCode }),
  setDeafultProducts: (defaultProducts) => set({ defaultProducts }),
  setRow: (row) => set({ row }),
  setIsView: (isView) => set({ isView }),
  setQueryFilter: (queryFilter) => set({ queryFilter }),
  setIsFilter: (isFilter) => set({ isFilter }),
  setId: (id) => set({ id }),
  setPayment: (payment) => set({ payment }),
  setVoucher: (voucher) => set({ voucher }),
  setProducts: (products) => set({ products }),
  setVendor: (vendor) => set({ vendor }),
  setClient: (client) => set({ client }),
  setQueryKey: (queryKey) => set({ queryKey }),
  setQueryID: (queryID) => set({ queryID }),
  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
  setDelayedQueryFilter: (delayedQueryFilter) => set({ delayedQueryFilter }),
  setPage: (page) => set({ page }),
}));
