import { Card, Input, Breadcrumb, Space } from "antd";
import { PureTable } from "./table";
import { useBikerReview } from "./store";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import BikerReviewFilters from "./filters";

const { Search } = Input;
export const BikerReviewPage = () => {
  const { setQueryKey, queryKey } = useBikerReview();
  const [search, setSearch] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (queryKey) {
      setSearch(queryKey);
    }
  }, []);

  useEffect(() => {
    if (searchParams.get("q")) {
      setSearch(searchParams.get("q"));
      setQueryKey(searchParams.get("q"));
    } else {
      setSearch("");
      setQueryKey("");
    }
  }, [searchParams.get("q")]);

  return (
    <div className="app-page">
      <Breadcrumb style={{ marginBottom: 10 }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Customers Rate</Breadcrumb.Item>
        <Breadcrumb.Item>Biker Review</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <div className="app-space-between">
          <Space>
            <Search
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onSearch={(val) => {
                searchParams.set("q", val);
                searchParams.set("page_num", 1);
                setSearchParams(searchParams);
                setQueryKey(val);
              }}
              style={{ width: 340 }}
              placeholder="Write To Search For Phone Number..."
            />
            <BikerReviewFilters />
          </Space>
        </div>
        <br />
        <PureTable />
      </Card>
    </div>
  );
};
