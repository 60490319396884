import {
  Table,
  Space,
  Button,
  Typography,
  Pagination,
  Alert,
  message,
  Popconfirm,
  Switch,
  Tooltip,
  Tag,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
import { useQuery, useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import { getTopping, removeData, editTopping, pageSize } from "../../api";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSubProduct } from "./store";
import { ImageTable } from "../../comonents/global";
import authFunc from "../../helper/auth";
import { useSearchParams } from "react-router-dom";

const { Text } = Typography;

export const PureTable = () => {
  const { SubProductGroupID } = useParams();
  const [page, setPage] = useState(1);
  const {
    setIsCreateModal,
    setHistoryData,
    setIsHistoryModal,
    setDescription,
    setEndTime,
    setId,
    setName,
    setPrice,
    setProductGroupID,
    setStatus,
    queryKey,
    setPriority,
  } = useSubProduct();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Image",
      key: "id",
      render: (row) => (
        <ImageTable
          logo={false}
          url={row.img}
          id={row.id}
          pathName={`subproduct/img?id=${row.id}`}
          imgKey={"img"}
          updateStore={"topping"}
        />
      ),
    },

    {
      title: "Recipe Name",
      dataIndex: "name",
      key: "name",
      render: (text, row) => <Text style={{ fontWeight: "bold" }}>{text}</Text>,
    },

    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text) => <Text style={{ fontSize: 12 }}>{text}</Text>,
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      render: (rank) => (
        <Tag color="magenta">{Number(rank).toLocaleString()}</Tag>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text) => (
        <Text style={{ fontSize: 16 }}>
          {Number(text).toLocaleString()} IQD
        </Text>
      ),
    },

    {
      title: "Status",
      key: "id",
      render: (row) => (
        <Switch
          disabled={authFunc("TOPPINGS_UPDATE") ? false : true}
          loading={editLoading}
          checked={row.status}
          onChange={(state) => handleChangeActive(row)}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id, row) => (
        <Space>
          {authFunc("TOPPINGS_UPDATE") && (
            <Button
              onClick={() => handleEdit(row)}
              size="small"
              icon={<EditOutlined />}
            />
          )}
          <Tooltip placement="top" title="Show Actions History">
            <Button
              onClick={() => handleHistory(row.statusHistory)}
              size="small"
              icon={<HistoryOutlined />}
            />
          </Tooltip>
          {authFunc("TOPPINGS_DELETE") && (
            <Popconfirm
              title="Are you sure to delete this task?"
              onConfirm={() => mutate(id)}
              //onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Button size="small" danger icon={<DeleteOutlined />} />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  const handleHistory = (values) => {
    setHistoryData(values);
    setIsHistoryModal(true);
  };

  const handleEdit = (row) => {
    setDescription(row.description);
    setEndTime(row.endTime);
    setId(row.id);
    setName(row.name);
    setPrice(row.price);
    setProductGroupID(row.productGroupID);
    setIsCreateModal(true);
    setStatus(row.status);
    setPriority(row.priority);
  };

  const handleChangeActive = (row) => {
    let _data = {
      id: row.id,
      name: row.name,
      groupID: Number(SubProductGroupID),
      description: row.description || "",
      price: row.price,
      status: (row.status = !row.status),
      priority: row.priority,
    };
    {
      editMutate({ data: JSON.stringify(_data) });
    }
  };

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editTopping,
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          queryClient.invalidateQueries("topping");
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const { mutate } = useMutation((val) => removeData("subproduct", val), {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        queryClient.invalidateQueries("topping");
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(["topping", SubProductGroupID, queryKey, page], getTopping, {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  if (error || (!data?.success && data?.data?.err))
    return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />
    );

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data ? data.data.records : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        defaultPageSize={pageSize}
        total={data && data.data.total}
        showSizeChanger={false}
      />
    </>
  );
};
