import React from "react";
import { Select, Spin } from "antd";
import { useProduct } from "./store";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { getProductGroupByVendor } from "../../api";
import { useQuery } from "react-query";

const { Option } = Select;

export default function ProductFilters({ vendorID }) {
  const { setPage, productGroupIDFilter, setProductGroupIDFilter } =
    useProduct();
  // const [prdctGroupSearch, setPdctGroupSearch] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const { isLoading, data } = useQuery(
    ["productGroupFilter", vendorID, 1, 500],
    getProductGroupByVendor,
    {
      refetchOnWindowFocus: false,
      retry: 1,
      enabled: !!vendorID,
    }
  );

  useEffect(() => {
    let obj = {
      productGroupIDFilter: Number(searchParams.get("productGroupID")) || null,
      page_num: searchParams.get("page_num") || null,
    };

    setProductGroupIDFilter(obj.productGroupIDFilter);
    setPage(obj.page_num);
  }, [searchParams.get("productGroupID"), searchParams.get("page_num")]);

  return (
    <div className="filter-wrapper">
      <div className="item">
        <Select
          filterOption={false}
          dropdownMatchSelectWidth={false}
          notFoundContent={
            isLoading ? (
              <div className="spin-wrapper">
                <Spin size="small" />
              </div>
            ) : null
          }
          allowClear
          // showSearch
          onChange={(val) => {
            if (val != undefined) {
              setProductGroupIDFilter(val);
              searchParams.set("productGroupID", val);
              searchParams.set("page_num", 1);
              setSearchParams(searchParams);
            }
          }}
          onClear={() => {
            setProductGroupIDFilter(null);
            // setPdctGroupSearch(null);
            searchParams.delete("productGroupID");
            searchParams.set("page_num", 1);
            setSearchParams(searchParams);
          }}
          // onSearch={(val) => setPdctGroupSearch(val)}
          placeholder="Select Category"
          style={{ width: "100%", maxWidth: 160 }}
          value={productGroupIDFilter}
        >
          {data &&
            data?.success &&
            data?.data?.records?.map((el) => (
              <Option key={el.id} value={el.id}>
                {el.name}
              </Option>
            ))}
        </Select>
      </div>
    </div>
  );
}
