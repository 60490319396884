import { Typography, Modal, Table } from "antd";
import dayjs from "dayjs";
import { useReferalCodes } from "./store";

const { Title, Text } = Typography;

export const CountModal = () => {
  const { setIsCountModal, isCountModal, countJson } = useReferalCodes();
  let data = JSON.parse(countJson) || [];
  const columns = [
    {
      title: "#",
      dataIndex: "referralSettingID",
      key: "referralSettingID",
      width: 55,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "Count",
      dataIndex: "count",
      key: "count",
      ellipsis: true,
    },
    {
      title: "NO. Of Vouchers",
      dataIndex: "noOfVouchers",
      key: "noOfVouchers",
      ellipsis: true,
      render: (val) => <Text>{val ? val : "..."}</Text>,
    },
    {
      title: "Creation Date",
      dataIndex: "CreatedAt",
      key: "CreatedAt",
      render: (date) => (
        <Text type="secondary">{dayjs(date).format("MMMM D, YYYY")}</Text>
      ),
    },
  ];

  return (
    <Modal
      forceRender
      title={<Title level={5}> Count </Title>}
      className="app-modal"
      centered
      visible={isCountModal}
      onCancel={() => {
        setIsCountModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={700}
    >
      <Table
        columns={columns}
        dataSource={data ? data : []}
        size="small"
        pagination={false}
        scroll={{
          y: 400,
        }}
      />
    </Modal>
  );
};
