import { useState, useEffect } from "react";
import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  message,
  Select,
  Spin,
  Space,
  Checkbox,
  Divider,
  Input,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";

import { useMutation, useQuery } from "react-query";
import { queryClient } from "../../queryClient";
import {
  createAuthGroup,
  getAuthGroup,
  getRolesList,
  updateAuthGroup,
} from "../../api";
import { useAuthGroup } from "./store";

const { Title } = Typography;
const { Option } = Select;
const { Search } = Input;

export const PureCreate = () => {
  const [list, setList] = useState([]);
  const [roleList, setRoleLList] = useState([]);
  const [roleListFilter, setRoleLListFilter] = useState([]);
  const [isFilterd, setIsFilterd] = useState(false);

  const {
    isCreateModal,
    setIsCreateModal,
    id,
    setId,
    name,
    setName,
    setRoles,
    page,
    queryKey,
  } = useAuthGroup();

  const resetState = () => {
    setId(null);
    setName(null);
    setRoles(null);
    setList(null);
    setRoleLListFilter(null);
    setRoleLList(null);
    setIsFilterd(false);
  };

  const { mutate, isLoading } = useMutation(createAuthGroup, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        setIsCreateModal(false);
        queryClient.invalidateQueries("authGroup");
        resetState();
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    updateAuthGroup,
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          setIsCreateModal(false);
          queryClient.invalidateQueries("authGroup");
          resetState();
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const { data } = useQuery(["authGroup", page, queryKey], getAuthGroup, {
    retry: 2,
    refetchOnWindowFocus: false,
    enabled: !!isCreateModal,
  });

  const { data: rolesListData, isLoading: roleListLoading } = useQuery(
    ["roles", page],
    getRolesList,
    {
      retry: 2,
      refetchOnWindowFocus: false,
      enabled: !!isCreateModal,
    }
  );

  const onFinish = (values) => {
    let _data = {
      roles: list?.map((id) => {
        return {
          id: id,
        };
      }),
      name: values.name,
    };
    if (id) {
      _data.id = id;
      editMutate(_data);
    } else {
      mutate(_data);
    }
  };

  useEffect(() => {
    if (rolesListData && rolesListData.data) {
      setRoleLList(rolesListData.data);
      setRoleLListFilter(rolesListData.data);
    }
  }, [rolesListData, id, isCreateModal]);

  useEffect(() => {
    let userRole = data?.data?.records?.find((item) => item.id === id);
    if (id && userRole) {
      setList(userRole?.roles?.map((item) => item.id));
    } else {
      setList([]);
    }
  }, [id, data]);

  return (
    <Modal
      title={<Title level={5}>{id ? "Edit" : "New"} Auth Group</Title>}
      className="app-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetState();
        setIsCreateModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={"80%"}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        initialValues={{
          name: name,
        }}
        onFinish={onFinish}
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <div
              className="app-space-between"
              style={{ alignItems: "flex-end" }}
            >
              <Form.Item
                style={{ width: 300 }}
                label="Auth Group Name"
                name="name"
                rules={[{ required: true, message: "required" }]}
              >
                <Input />
              </Form.Item>
              <Space size={10} align="end">
                <Checkbox
            checked={list?.length === roleList?.length}
                  onChange={(e) => {
                    if (e.target.checked) {
                      let newList;
                      if (list?.length > 0 && !isFilterd) {
                        newList = roleList
                          ?.map((el) => el.id)
                          .filter((obj) => list.indexOf(obj) === -1);
                      } else if (list?.length > 0 && isFilterd) {
                        newList = roleListFilter
                          ?.map((el) => el.id)
                          .filter((obj) => list.indexOf(obj) === -1);
                      } else {
                        newList = roleListFilter.map((el) => el.id);
                      }
                      setList([...list, ...newList]);
                    } else {
                      let newList;
                      if (isFilterd) {
                        newList = list?.filter(
                          (obj) =>
                            roleListFilter.map((el) => el.id).indexOf(obj) ===
                            -1
                        );
                      } else {
                        newList = [];
                      }
                      setList(newList);
                    }
                  }}
                >
                  Select All
                </Checkbox>

                <Divider type="vertical" />
                <Search
                  placeholder={"Roles filter . . ."}
                  onChange={(e) => {
                    let searchKey = e.target.value;
                    let newList = [
                      ...roleList.filter((el) =>
                        el.role.includes(searchKey.toUpperCase())
                      ),
                    ];
                    setRoleLListFilter(newList);
                    setIsFilterd(true);
                  }}
                />
              </Space>
            </div>
          </Col>

          <Col span={24} className="auth-radio">
            <div style={{ height: "62vh", overflow: "auto" }}>
              <Checkbox.Group
                style={{
                  width: "100%",
                }}
                name="radiogroup"
                value={list}
                onChange={(values) => setList(values)}
              >
                <Space wrap>
                  {!isLoading && !roleListLoading ? (
                    id !== 1 ? (
                      roleListFilter !== null && roleListFilter.length > 0 ? (
                        <>
                          {roleListFilter?.map((item) => (
                            <Checkbox
                              key={item.id}
                              id={item.id}
                              value={item.id}
                            >
                              {item.role}
                            </Checkbox>
                          ))}
                          {roleList
                            .filter((value) => !roleListFilter.includes(value))
                            .map((item) => (
                              <Checkbox
                                style={{ display: "none" }}
                                disabled
                                key={item.id}
                                id={item.id}
                                value={item.id}
                              >
                                {item.role}
                              </Checkbox>
                            ))}
                        </>
                      ) : (
                        roleList !== null &&
                        roleList.length > 0 &&
                        roleList?.map((item) => (
                          <Checkbox key={item.id} id={item.id} value={item.id}>
                            {item.role}
                          </Checkbox>
                        ))
                      )
                    ) : (
                      <Option disabled> Admin Role</Option>
                    )
                  ) : (
                    <Spin spinning={true} />
                  )}
                </Space>
              </Checkbox.Group>
            </div>
          </Col>
          <Col span={24}>
            <Form.Item>
              <Button
                loading={isLoading || editLoading}
                type="primary"
                disabled={list?.length !== 0 && list !== null ? false : true}
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
