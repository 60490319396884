import create from "zustand";

export const useWaterfallProcess = create((set) => ({
  isCreateModal: false,
  id: null,
  queryKey: "",
  processName: null,
  cities: [],
  cityIDs: [],
  delieveryfeeID: null,
  delieveryfee: null,
  delieveryfee: null,
  default_buffer_time: null,

  setQueryKey: (queryKey) => set({ queryKey }),
  setId: (id) => set({ id }),
  setProcessName: (processName) => set({ processName }),
  setCities: (cities) => set({ cities }),
  setCityIDs: (cityIDs) => set({ cityIDs }),
  setDelieveryfeeID: (delieveryfeeID) => set({ delieveryfeeID }),
  setDelieveryfee: (delieveryfee) => set({ delieveryfee }),
  setDefault_buffer_time: (default_buffer_time) => set({ default_buffer_time }),
  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
}));
