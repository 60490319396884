import { Form, Button, Row, Col, Divider, Space, Select } from "antd";
import Input from "antd/lib/input/Input";
import { useResturant } from "../store";
import TextArea from "antd/lib/input/TextArea";
import { useQuery } from "react-query";
import { getGovs, getRegions, getZones } from "../../../api";
import { useEffect, useState } from "react";

const { Option } = Select;

export const ResturantInfo = () => {
  const {
    step,
    setStep,
    arName,
    provinceID,
    cityID,
    zoneID,
    address,
    ownerName,
    resturantPhone,
    resturantMgmtPhone,
    description,
    city,
    zone,

    setArName,
    setOwnerName,
    setProvinceID,
    setResturantMgmtPhone,
    setResturantPhone,
    setAddress,
    setDescription,
    setCityID,
    setZoneID,
  } = useResturant();
  const [citySearch, setCitySearch] = useState("");
  const [zoneSearch, setZoneSearch] = useState("");
  const [checkMgmtPhoneErr, setCheckMgmtPhoneErr] = useState(false);
  const [checkRestPhoneErr, setCheckRestPhoneErr] = useState(false);

  const [form] = Form.useForm();

  const {
    isLoading: govsLoading,
    error: govsError,
    data: govsList,
  } = useQuery("provinces", getGovs, {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  const {
    isLoading: regionsLoading,
    error: regionsError,
    data: regionsList,
    refetch,
  } = useQuery(
    [`regionsByProvince`, provinceID, citySearch, , , ""],
    getRegions,
    {
      retry: 2,
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const {
    isLoading: zonesLoading,
    error: zonesError,
    data: zonesList,
  } = useQuery([`zones`, zoneSearch], getZones, {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  useEffect(() => {
    if (cityID && regionsList && regionsList.success) {
      let cuurentList = regionsList.data.records.find((el) => el.id == cityID);
      if (!cuurentList) {
        regionsList.data.records.push({ id: cityID, name: city });
      }
    }
  }, [regionsList]);

  useEffect(() => {
    if (zoneID && zonesList && zonesList.success) {
      let cuurentList = zonesList.data.records.find((el) => el.id == zoneID);
      if (!cuurentList) {
        zonesList.data.records.push({ id: zoneID, name: zone });
      }
    }
  }, [zonesList]);

  useEffect(() => {
    if (provinceID) {
      refetch();
    }
  }, [provinceID, zoneID, citySearch]);

  // useEffect(() => {
  //   if (zonesList && zonesList?.success) {
  //     let _list = zonesList.data.records || [];
  //     if (_list.find((el) => el.id === zoneID)) {
  //       setZoneData(_list);
  //     } else {
  //       _list.push({ id: zoneID, name: zone });
  //       setZoneData(_list)
  //     }
  //   }
  // }, [zonesList]);

  const phoneMgmtValidate = () => {
    const regex = /^07[3-9]\d{8}$/;
    const result = regex.exec(resturantMgmtPhone);
    return result;
  };

  const phoneRestValidate = () => {
    const regex = /^07[3-9]\d{8}$/;
    const result = regex.exec(resturantPhone);
    return result;
  };

  const onFinish = () => {
    if (
      phoneMgmtValidate() &&
      phoneRestValidate() &&
      phoneMgmtValidate()?.index === 0 &&
      phoneRestValidate()?.index === 0
    ) {
      setStep(step + 1);
    } else if (!phoneMgmtValidate()) {
      setCheckMgmtPhoneErr(true);
    }
    if (!phoneRestValidate()) {
      setCheckRestPhoneErr(true);
    }
  };

  return (
    <div className="app-flex-column">
      <Form
        requiredMark={"optional"}
        name="basic"
        form={form}
        initialValues={{
          remember: true,
          arName,
          ownerName,
          description,
          address,
          provinceID,
          resturantMgmtPhone,
          resturantPhone,
          city,
          zone,
        }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={14}>
            <Form.Item
              label="Retsurant Name"
              name="arName"
              rules={[{ required: true, arName }]}
            >
              <Input
                onChange={(e) => setArName(e.target.value)}
                placeholder="Write retsurant name"
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              label="Owner Name	"
              name="ownerName"
              rules={[{ required: true }]}
            >
              <Input
                onChange={(e) => setOwnerName(e.target.value)}
                placeholder="Write owner name"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Description" name="description">
              <TextArea
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Write Description"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Full Address"
              name="address"
              rules={[{ required: true }]}
            >
              <Input
                onChange={(e) => setAddress(e.target.value)}
                placeholder="Write Address"
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Governorate"
              name="provinceID"
              rules={[{ required: true }]}
            >
              <Select
                loading={govsLoading}
                status={govsError ? true : false}
                onChange={(val) => {
                  form.setFieldsValue({
                    city: null,
                    zone: null,
                  });
                  setProvinceID(val);
                  setCityID(null);
                  setZoneSearch(null);
                }}
                placeholder="Choose Governorate"
              >
                {govsList
                  ? govsList.data.records.map((el) => (
                      <Option key={el.id} value={el.id}>
                        {el.name}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Zone" name="zone" rules={[{ required: true }]}>
              <Select
                loading={zonesLoading}
                status={zonesError ? true : false}
                onChange={(val) => {
                  form.setFieldsValue({
                    city: null,
                  });
                  setZoneID(val);
                  setCityID(null);
                }}
                placeholder="Choose Zone"
                showSearch
                filterOption={false}
                onSearch={(val) => {
                  setZoneSearch(val);
                }}
                allowClear
                onClear={() => setZoneSearch(null)}
              >
                {zonesList && zonesList.success
                  ? zonesList.data.records.map((el) => (
                      <Option key={el.id} value={el.id}>
                        {el.name}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="City name"
              name="city"
              rules={[{ required: true }]}
            >
              <Select
                loading={regionsLoading}
                status={regionsError ? true : false}
                onChange={(val) => setCityID(val)}
                placeholder="Choose City"
                showSearch
                filterOption={false}
                onSearch={(val) => {
                  setCitySearch(val);
                }}
              >
                {regionsList && regionsList.success
                  ? regionsList.data.records.map((el) => (
                      <Option key={el.id} value={el.id}>
                        {el.name}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Owner Phone"
              name="resturantMgmtPhone"
              rules={[{ required: true }]}
            >
              <Input
                onChange={(e) => setResturantMgmtPhone(e.target.value)}
                status={checkMgmtPhoneErr ? "error" : ""}
                placeholder="07xxxxxx"
                maxLength="11"
                minLength="11"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Resturant Phone"
              name="resturantPhone"
              rules={[{ required: true }]}
            >
              <Input
                onChange={(e) => setResturantPhone(e.target.value)}
                placeholder="07xxxxxx"
                status={checkRestPhoneErr ? "error" : ""}
                maxLength="11"
                minLength="11"
              />
            </Form.Item>
          </Col>

          <Col span={24} className="resturant-constroll-form">
            <Divider />
            <Space>
              {/* <Button
                onClick={() => {
                  setStep(step - 1);
                }}
              >
                Back
              </Button> */}
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Next
                </Button>
              </Form.Item>
            </Space>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
