import { useEffect, useState } from "react";
import {
  Table,
  Pagination,
  Typography,
  Button,
  message,
  Alert,
  Space,
  Popconfirm,
  Tag,
} from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useQuery, useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import { useResturantCategory } from "./store";
import { ImageTable } from "../../comonents/global";
import authFunc from "../../helper/auth";
import { useSearchParams } from "react-router-dom";
import { getCategories, removeCategory, pageSize } from "../../api";

const { Text } = Typography;

export const PuerTable = () => {
  const [page, setPage] = useState(1);
  const {
    setName,
    setDescription,
    setId,
    setIsCreateModal,
    queryKey,
    setPriority,
  } = useResturantCategory();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const handleEdit = (row) => {
    setId(row.id);
    setName(row.name);
    setDescription(row.description);
    setIsCreateModal(true);
    setPriority(row.priority);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Image",
      key: "id",
      render: (row) => (
        <ImageTable
          logo={false}
          url={row.img}
          id={row.id}
          pathName={"businesscategory/img"}
          imgKey={"img"}
          updateStore={"categories"}
        />
      ),
    },

    {
      title: "Category Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <Text style={{ fontWeight: "bold" }}>{text}</Text>,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      render: (val) => (
        <Tag color="magenta">{Number(val).toLocaleString()}</Tag>
      ),
    },
    {
      title: "Actions",
      key: "id",
      dataIndex: "id",
      render: (id, row) => (
        <Space>
          {authFunc("CATEGORY_UPDATE") && (
            <Button
              size="small"
              onClick={() => handleEdit(row)}
              icon={<EditOutlined />}
            />
          )}
          {authFunc("CATEGORY_DELETE") && (
            <Popconfirm
              title="Are you sure to delete this task?"
              onConfirm={() => mutate(id)}
              //onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Button size="small" danger icon={<DeleteOutlined />} />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  const { mutate } = useMutation(removeCategory, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        queryClient.invalidateQueries("categories");
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(["categories", queryKey, page], getCategories, {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  if (error || (!data?.success && data?.data?.err))
    return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />
    );

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data ? data.data.records : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        defaultPageSize={pageSize}
        total={data && data.data.total}
        showSizeChanger={false}
      />
    </>
  );
};
