import { useRef, useMemo, useState } from "react";
import { useAppBanner } from "./store";

import { MapContainer, TileLayer, Marker, useMapEvent } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import markerIconPng from "leaflet/dist/images/marker-icon.png";

let vendorMarkerIcon = L.icon({
  iconUrl: markerIconPng,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});

export const PureMap = () => {
  const { setLat, setLon, lat, lon } = useAppBanner();
  let center = [lat, lon];
  const [position, setPosition] = useState(center);
  const markerRef = useRef(null);

  const MyComponent = () => {
    const map = useMapEvent("click", (e) => {
      setPosition([e.latlng.lat, e.latlng.lng]);
      setLat(e.latlng.lat);
      setLon(e.latlng.lng);
    });
    return null;
  };
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          setPosition(marker.getLatLng());
          setLat(marker.getLatLng().lat);
          setLon(marker.getLatLng().lng);
        }
      },
    }),
    []
  );

  return (
    <MapContainer
      center={lat ? center : [33.3152, 44.3661]}
      zoomControl={false}
      zoom={15}
      scrollWheelZoom={true}
      attributionControl={false}
      style={{ height: "80vh" }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://osrm.baly.dev/osm/{z}/{x}/{y}.png"
      />
      {center && (
        <Marker
          icon={vendorMarkerIcon}
          position={position}
          ref={markerRef}
          eventHandlers={eventHandlers}
          draggable={true}
        />
      )}
      <MyComponent />
    </MapContainer>
  );
};
