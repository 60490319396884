import { Select, Spin } from "antd";
import { useRegion } from "./store";
import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { getGovs } from "../../api";
import { useSearchParams } from "react-router-dom";

const { Option } = Select;

export default function RegionsFilters() {
  const { setPage, provinceIdFilter, setProvinceIdFilter } = useRegion();

  const [govsSearch, setGovsSearch] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    let obj = {
      provinceIdFilter: Number(searchParams.get("provinceID")) || null,
      page_num: Number(searchParams.get("page_num")) || null,
    };

    setProvinceIdFilter(obj.provinceIdFilter);
    setPage(obj.page_num);
  }, [searchParams.get("provinceID"), searchParams.get("page_num")]);

  const {
    isLoading: isGovsLoading,
    data: govs,
    refetch: govsRefetch,
  } = useQuery(["govs", govsSearch, 1], getGovs, {
    enabled: !!govsSearch,
    retry: 2,
    refetchOnWindowFocus: false,
  });

  return (
    <div className="filter-wrapper">
      <div className="item">
        <Select
          filterOption={false}
          dropdownMatchSelectWidth={false}
          notFoundContent={
            isGovsLoading ? (
              <div className="spin-wrapper">
                <Spin size="small" />
              </div>
            ) : null
          }
          allowClear
          showSearch
          onChange={(val) => {
            if (val !== undefined) {
              setProvinceIdFilter(val);
              searchParams.set("provinceID", val);
              searchParams.set("page_num", 1);
              setSearchParams(searchParams);
            }
          }}
          onClear={() => {
            setProvinceIdFilter(null);
            searchParams.delete("provinceID");
            searchParams.set("page_num", 1);
            setSearchParams(searchParams);
          }}
          onSearch={(val) => setGovsSearch(val)}
          onClick={() => govsRefetch()}
          placeholder="Select Province"
          style={{ width: "100%" }}
          value={provinceIdFilter}
        >
          {govs &&
            govs.success &&
            govs.data.records.map((el) => (
              <Option key={el.id} value={el.id}>
                {el.name}
              </Option>
            ))}
        </Select>
      </div>
    </div>
  );
}
