import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  InputNumber,
} from "antd";
import { message as ANTmessage } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import Input from "antd/lib/input/Input";
import { usePwaSettings } from "./store";
import { useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import { createPwaSettings, editPwaSettings } from "../../api";

const { Title } = Typography;

export const PureCreate = () => {
  const {
    isCreateModal,
    setIsCreateModal,
    id,
    allowedVersion,
    backendVersion,
    message,
    suspendPrice,
    referral,
    orderCancelTime,
    setReferral,
    setAllowedVersion,
    setBackendVersion,
    setMessage,
    setId,
    setSuspendPrice,
    setOrderCancelTime,
  } = usePwaSettings();

  const resetState = () => {
    setAllowedVersion(null);
    setId(null);
    setBackendVersion(null);
    setMessage(null);
    setSuspendPrice(null);
    setReferral(null);
    setOrderCancelTime(null);
  };

  const { mutate, isLoading } = useMutation(createPwaSettings, {
    onSuccess: (data) => {
      if (data.success) {
        ANTmessage.success(data.msg);
        setIsCreateModal(false);
        queryClient.invalidateQueries("pwaSettings");
        resetState();
      } else {
        ANTmessage.error(data.msg);
      }
    },
    onError: (e) => {
      ANTmessage.error(e.message);
    },
  });
  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editPwaSettings,
    {
      onSuccess: (data) => {
        if (data.success) {
          ANTmessage.success(data.msg);
          setIsCreateModal(false);
          queryClient.invalidateQueries("pwaSettings");
          resetState();
        } else {
          ANTmessage.error(data.msg);
        }
      },
      onError: (e) => {
        ANTmessage.error(e.message);
      },
    }
  );

  const onFinish = (values) => {
    if (id) {
      values.id = id;
      editMutate(values);
    } else {
      mutate(values);
    }
  };

  return (
    <Modal
      title={<Title level={5}>{id ? "Edit" : "New"} PWA Setting</Title>}
      className="app-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetState();
        setIsCreateModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={400}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        initialValues={{
          remember: true,
          message,
          backendVersion,
          allowedVersion,
          suspendPrice,
          referral,
          orderCancelTime,
        }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Form.Item
              label="Backend Version"
              name="backendVersion"
              rules={[{ required: true }]}
            >
              <Input placeholder="PWA Backend Version" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Allowed Version"
              name="allowedVersion"
              rules={[{ required: true }]}
            >
              <Input rows={4} placeholder={"PWA Allowed Version"} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Order Cancellation Time"
              name="orderCancelTime"
              rules={[{ required: true }]}
            >
              <InputNumber
                style={{ width: "100%" }}
                rows={4}
                placeholder={"time in seconds..."}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Message"
              name="message"
              rules={[{ required: true }]}
            >
              <Input rows={4} placeholder={"PWA  Message"} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Suspend Price"
              name="suspendPrice"
              rules={[{ required: true }]}
            >
              <InputNumber
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                style={{ width: "100%" }}
                placeholder={"Suspend Price"}
              />
            </Form.Item>
          </Col>
          {/* <Col span={12}>
            <Form.Item
              name="referral"
              valuePropName="checked"
            >
              <Checkbox 
                checked={referral} 
                onChange={(e) => setReferral(e.target.checked)}
              > is Referral ?</Checkbox>
            </Form.Item>
          </Col> */}
          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={isLoading || editLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
