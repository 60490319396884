import { useState, useEffect } from "react";
import {
  Table,
  Button,
  message,
  Alert,
  Space,
  Popconfirm,
  Pagination,
  Tag,
} from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useQuery, useMutation } from "react-query";
import { queryClient } from "../../queryClient";
// import authFunc from "../../helper/auth";
import { useSearchParams } from "react-router-dom";
import { getDistanceSettings, removeDistanceSetting } from "../../api";
import { useDistanceSettings } from "./store";

export const PuerTable = () => {
  const [page, setPage] = useState(1);
  const { setId, setIsCreateModal, setName, setClasses, setIsAddBanners } =
    useDistanceSettings();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const handleEdit = (row) => {
    setId(row.id);
    setName(row.name);
    setClasses(row.classes);
    setIsCreateModal(true);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      width: 50,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Banners",
      dataIndex: "id",
      key: "id",
      render: (id) => (
        <Button
          onClick={() => {
            setId(id);
            setIsAddBanners(true);
          }}
        >
          Banners
        </Button>
      ),
    },
    {
      title: "Classes",
      dataIndex: "classes",
      key: "classes",
      render: (classes) => (
        <Space size={[10, 10]} wrap>
          {classes &&
            classes?.map((el) => (
              <Tag key={el.id} color="geekblue">
                {Number(el.from).toLocaleString()} {"->"}
                {`${Number(el.to).toLocaleString()} KM`}
              </Tag>
            ))}
        </Space>
      ),
    },
    {
      title: "Actions",
      key: "id",
      dataIndex: "id",
      render: (id, row, index) => (
        <Space>
          {/* {authFunc("DISTANCE_SETTINGS_UPDATE") && ( */}
          <Button
            onClick={() => handleEdit(row)}
            size="small"
            icon={<EditOutlined />}
          />
          {/* )} */}
          {/* {authFunc("DISTANCE_SETTINGS_DELETE") && ( */}
          <Popconfirm
            title="Are you sure to delete this setting?"
            onConfirm={() => removeMutate(id)}
            okText="Yes"
            cancelText="No"
          >
            <Button size="small" danger icon={<DeleteOutlined />} />
          </Popconfirm>
          {/* )} */}
        </Space>
      ),
    },
  ];

  const { mutate: removeMutate } = useMutation(removeDistanceSetting, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        queryClient.invalidateQueries("distanceSettings");
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(["distanceSettings", page], getDistanceSettings, {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  if (error || (!data?.success && data?.data?.err))
    return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />
    );

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data ? data?.data : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        total={data && data?.data?.total}
        showSizeChanger={false}
      />
    </>
  );
};
