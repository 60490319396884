import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  InputNumber,
  Select,
  AutoComplete,
  Spin,
} from "antd";
import { message as ANTmessage } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import Input from "antd/lib/input/Input";
import { useWaterfallProcessAction } from "./store";
import { useMutation, useQuery } from "react-query";
import { queryClient } from "../../queryClient";
import {
  createWaterfallProcessAction,
  editWaterfallProcessAction,
  getFee,
  getStatus,
} from "../../api";
import { useParams } from "react-router-dom";
import { useState } from "react";

const { Title } = Typography;
const { Option } = Select;

export const PureCreate = () => {
  const {
    isCreateModal,
    setIsCreateModal,
    id,
    setId,
    // priority,
    // setPriority,
    bufferTime,
    setBufferTime,
    type,
    setType,
    value,
    setValue,
    maxSR,
    setMaxSR,
    minSR,
    setMinSR,
    delieveryfeeID,
    setDelieveryfeeID,
    delieveryfee,
    setDelieveryfee,
    statusCodeID,
    setStatusCodeID,
    minActiveOrders,
    setMinActiveOrders,
  } = useWaterfallProcessAction();

  const [selectedType, setSelectedType] = useState([]);
  const [feeSearch, setFeeSearch] = useState("");
  const [form] = Form.useForm();
  const { id: processID } = useParams();

  const {
    isLoading: feeLoading,
    error: feeError,
    data: feeList,
  } = useQuery(["deliverFee", feeSearch, 1, 1000], getFee, {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  const { data: statusList, isLoading: statusLoading } = useQuery(
    "statusCodes",
    getStatus,
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );

  const resetState = () => {
    setId(null);
    // setPriority(null);
    setBufferTime(null);
    setType([]);
    setMinSR(null);
    setMaxSR(null);
    setValue(null);
    setDelieveryfeeID(null);
    setDelieveryfee(null);
    setStatusCodeID(null);
    setMinActiveOrders(null);
    setSelectedType([]);
  };

  const { mutate, isLoading } = useMutation(
    (e) => createWaterfallProcessAction(processID, e),
    {
      onSuccess: (data) => {
        if (data.success) {
          ANTmessage.success(data.msg);
          setIsCreateModal(false);
          queryClient.invalidateQueries("waterfallProcessAction");
          resetState();
        } else {
          ANTmessage.error(data.msg);
        }
      },
      onError: (e) => {
        ANTmessage.error(e.message);
      },
    }
  );
  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    (e) => editWaterfallProcessAction(processID, e),
    {
      onSuccess: (data) => {
        if (data.success) {
          ANTmessage.success(data.msg);
          setIsCreateModal(false);
          queryClient.invalidateQueries("waterfallProcessAction");
          resetState();
        } else {
          ANTmessage.error(data.msg);
        }
      },
      onError: (e) => {
        ANTmessage.error(e.message);
      },
    }
  );

  const onFinish = (values) => {
    let steps = {};
    if (values.type.includes("CHANGE_RADIUS")) {
      steps = {
        ...steps,
        ...{ CHANGE_RADIUS: String(values.value) },
      };
    }
    if (values.type.includes("CHANGE_DELIVERY_FEE")) {
      steps = {
        ...steps,
        ...{ CHANGE_DELIVERY_FEE: String(values.delieveryfeeID) },
      };
    }
    values.steps = steps;
    if (id) {
      values.id = id;
      editMutate(values);
    } else {
      mutate(values);
    }
  };

  return (
    <Modal
      title={
        <Title level={5}>{id ? "Edit" : "New"} Waterfall Process Action</Title>
      }
      className="app-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetState();
        setIsCreateModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={400}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        form={form}
        initialValues={{
          remember: true,
          value,
          type,
          bufferTime,
          minSR,
          maxSR,
          // priority,
          delieveryfeeID,
          statusCodeID,
          minActiveOrders,
        }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Form.Item label="Type" name="type" rules={[{ required: true }]}>
              <Select
                onChange={(val) => {
                  setSelectedType(val);
                  setType(val);
                }}
                mode="multiple"
              >
                <Option value={"CHANGE_RADIUS"}>CHANGE_RADIUS</Option>
                <Option value={"CHANGE_DELIVERY_FEE"}>
                  CHANGE_DELIVERY_FEE
                </Option>
              </Select>
            </Form.Item>
          </Col>
          {selectedType.includes("CHANGE_RADIUS") ||
          type.includes("CHANGE_RADIUS") ? (
            <Col span={12}>
              <Form.Item
                label="delivery Distance range"
                name="value"
                rules={[{ required: true }]}
              >
                <InputNumber
                  defaultValue={100}
                  min={0}
                  max={100}
                  formatter={(value) => `${value}%`}
                  parser={(value) => value?.replace("%", "")}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          ) : null}
          {selectedType.includes("CHANGE_RADIUS") ||
          type.includes("CHANGE_RADIUS") ? (
            <Col span={12}>
              <Form.Item
                label="Status Code"
                name="statusCodeID"
                rules={[{ required: true }]}
              >
                <Select
                  onChange={(val) => setStatusCodeID(val)}
                  loading={statusLoading}
                  placeholder="Choose Status Code"
                  allowClear
                  filterOption={false}
                >
                  {statusList
                    ? statusList.data.records.map((el) => (
                        <Option key={el.id} value={el.id}>
                          {el.name}
                        </Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
          ) : null}
          {selectedType.includes("CHANGE_DELIVERY_FEE") ||
          type.includes("CHANGE_DELIVERY_FEE") ? (
            <Col span={24}>
              <Form.Item
                label="Delivery Type"
                name="delieveryfeeID"
                rules={[{ required: true }]}
              >
                <Select
                  onChange={(val) => setDelieveryfeeID(val)}
                  loading={feeLoading}
                  error={feeError}
                  placeholder="Choose Delivery Type"
                  onSearch={(v) => {
                    setFeeSearch(v);
                  }}
                  showSearch
                  searchValue={feeSearch}
                  allowClear
                  filterOption={false}
                >
                  {feeList
                    ? feeList.data.records.map((el) => (
                        <Option key={el.id} value={el.id}>
                          {el.name}
                        </Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
          ) : null}
          <Col span={12}>
            <Form.Item
              label="Min Saturation Rate"
              name="minSR"
              rules={[{ required: true }]}
            >
              <InputNumber
                min={0}
                style={{ width: "100%" }}
                rows={4}
                placeholder={"Min Saturation Rate"}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Max Saturation Rate"
              name="maxSR"
              rules={[{ required: true }]}
            >
              <InputNumber
                min={0}
                style={{ width: "100%" }}
                rows={4}
                placeholder={"Max Saturation Rate"}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Buffer Time"
              name="bufferTime"
              rules={[{ required: true }]}
            >
              <InputNumber
                min={0}
                style={{ width: "100%" }}
                rows={4}
                placeholder={"Buffer Time"}
              />
            </Form.Item>
          </Col>
          {/* {id && (
            <Col span={12}>
              <Form.Item
                label="priority"
                name="priority"
                rules={[{ required: true }]}
              >
                <InputNumber
                  min={0}
                  style={{ width: "100%" }}
                  rows={4}
                  placeholder={"Priority"}
                />
              </Form.Item>
            </Col>
          )} */}
          <Col span={12}>
            <Form.Item
              label="Min Active Orders"
              name="minActiveOrders"
              rules={[{ required: true }]}
            >
              <InputNumber
                min={0}
                style={{ width: "100%" }}
                placeholder={"Min Active Orders"}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={isLoading || editLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
