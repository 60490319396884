import { useState, useEffect } from "react";
import {
  Table,
  Typography,
  Button,
  message,
  Alert,
  Space,
  Popconfirm,
  Pagination,
  Switch,
  Tag,
  Tooltip,
} from "antd";
import { EditOutlined, DeleteOutlined, CopyOutlined } from "@ant-design/icons";
import { useQuery, useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import authFunc from "../../helper/auth";
import {
  getReferralSettings,
  removeReferralSettings,
  pageSize,
  editReferralSettingsStatus,
} from "../../api";
import { useReferralSettings } from "./store";
import { useSearchParams } from "react-router-dom";

const copy = require("clipboard-copy");
const { Text, Link } = Typography;

export const PuerTable = () => {
  const [page, setPage] = useState(1);
  const {
    setId,
    setIsCreateModal,
    setName,
    setReferredVoucherID,
    setReferrerVoucherID,
    setInviteCount,
    setMaxUse,
    setMsg,
    queryKey,
    setDeepLink,
    setConfig,
    setType,
  } = useReferralSettings();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const handleEdit = (row) => {
    setId(row.id);
    setName(row.name);
    setReferredVoucherID(row.referredVoucherID);
    setReferrerVoucherID(row.referrerVoucherID);
    setInviteCount(row.inviteCount);
    setMaxUse(row.maxUse);
    setMsg(row.msg);
    setDeepLink(row.deepLink);
    setConfig(row.config);
    setType(row.type);
    setIsCreateModal(true);
  };

  const copyLink = (val) => {
    let successPromise = copy(val);
    if (successPromise) {
      message.success("Copy to clipboard");
    } else {
      message.error("Faild Copy !");
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      width: 60,
      fixed: "left",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 150,
      render: (text) => <Text style={{ fontWeight: "bold" }}>{text}</Text>,
    },
    {
      title: "Referred Voucher",
      dataIndex: "referredVoucher",
      key: "referredVoucher",
      width: 150,
      render: (el) => <Text>{el.voucherTitle}</Text>,
    },
    {
      title: "Referrer Voucher",
      dataIndex: "referrerVoucher",
      key: "referrerVoucher",
      width: 150,
      render: (el) => <Text>{el.voucherTitle}</Text>,
    },
    {
      title: "Invite Count",
      dataIndex: "inviteCount",
      key: "inviteCount",
      width: 100,
    },
    {
      title: "Max Use",
      dataIndex: "maxUse",
      key: "maxUse",
      width: 100,
    },
    {
      title: "Content",
      dataIndex: "msg",
      key: "msg",
      render: (msg) => msg && JSON.parse(msg).content,
    },
    {
      title: "Send By",
      dataIndex: "msg",
      key: "msg",
      width: 150,
      render: (msg) =>
        msg && JSON.parse(msg).sendBy.map((el) => <Tag key={el}>{el}</Tag>),
    },
    {
      title: "Campaign URL",
      dataIndex: "config",
      key: "config",
      render: (config) => (
        <Space className="table-link">
          <Link
            className="app-media-link"
            style={{ textDecoration: "underline" }}
          >
            {config && JSON.parse(config).campaignURL}
          </Link>
          <Tooltip placement="top" title="copy link">
            <Button
              onClick={() => copyLink(config && JSON.parse(config).campaignURL)}
              size="small"
              type="text"
              icon={<CopyOutlined />}
            />
          </Tooltip>
        </Space>
      ),
    },
    {
      title: "API Key",
      dataIndex: "config",
      key: "config",
      render: (config) => <Text>{config && JSON.parse(config).apiKey}</Text>,
    },
    {
      title: "Deep Link",
      dataIndex: "deepLink",
      key: "deepLink",
      render: (text) => (
        <Space className="table-link">
          <Link
            className="app-media-link"
            style={{ textDecoration: "underline" }}
          >
            {text}
          </Link>
          <Tooltip placement="top" title="copy image link">
            <Button
              onClick={() => copyLink(text)}
              size="small"
              type="text"
              icon={<CopyOutlined />}
            />
          </Tooltip>
        </Space>
      ),
    },
    {
      title: "Status",
      key: "id",
      width: 100,
      fixed: "right",
      render: (row) =>
        row?.status !== "[]" ? (
          JSON.parse(row?.status)
            .map((el) => (
              <Switch
                key={row.id}
                disabled={authFunc("REFERRAL_SETTING_UPDATE") ? false : true}
                loading={editLoading}
                checked={el?.status}
                onChange={(state) => handleChangeActive(row)}
              />
            ))
            .slice(-1)
        ) : (
          <Switch
            key={row.id}
            loading={editLoading}
            checked={false}
            onChange={(state) => handleChangeActive(row)}
          />
        ),
    },
    {
      title: "Actions",
      key: "id",
      dataIndex: "id",
      width: 100,
      fixed: "right",
      render: (id, row) => (
        <Space>
          {authFunc("REFERRAL_SETTING_UPDATE") && (
            <Button
              onClick={() => handleEdit(row)}
              size="small"
              icon={<EditOutlined />}
            />
          )}
          {authFunc("REFERRAL_SETTING_DELETE") && (
            <Popconfirm
              title="Are you sure to delete this task?"
              onConfirm={() => mutate(id)}
              //onCancel={cancel}
              disabled={row.mandatory == "true" ? true : false}
              okText="Yes"
              cancelText="No"
            >
              <Button
                disabled={row.mandatory == "true" ? true : false}
                size="small"
                danger
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  const { mutate } = useMutation(removeReferralSettings, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        queryClient.invalidateQueries("referralSettings");
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editReferralSettingsStatus,
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          queryClient.invalidateQueries("referralSettings");
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const handleChangeActive = (row) => {
    {
      if (row.status) {
        let statusJs = JSON.parse(row.status);
        let refStatus = statusJs[statusJs.length - 1];

        editMutate({
          id: row.id,
          referrStatus: !refStatus?.status,
        });
      }
    }
  };

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(["referralSettings", page, queryKey], getReferralSettings, {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  if (error || (!data?.success && data?.data?.err))
    return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />
    );

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data ? data?.data?.records : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
        scroll={{
          x: 2800,
        }}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        defaultPageSize={pageSize}
        total={data && data?.data?.total}
        showSizeChanger={false}
      />
    </>
  );
};
