import create from "zustand";
import moment from "moment";

export const useVendorDiscount = create((set) => ({
  isCreateModal: false,
  queryKey: "",
  id: null,
  discountType: null,
  value: null,
  vendorID: null,
  isAllProduct: false,
  productID: null,
  schedule: null,
  startDate: moment(),
  endDate: moment(),
  totalValue: null,
  balyValue: null,
  vendorValue: null,
  page: 1,
  active: null,

  setActive: (active) => set({ active }),
  setTotalValue: (totalValue) => set({ totalValue }),
  setBalyValue: (balyValue) => set({ balyValue }),
  setVendorValue: (vendorValue) => set({ vendorValue }),
  setId: (id) => set({ id }),
  setQueryKey: (queryKey) => set({ queryKey }),
  setDiscountType: (discountType) => set({ discountType }),
  setValue: (value) => set({ value }),
  setVendorID: (vendorID) => set({ vendorID }),
  setIsAllProduct: (isAllProduct) => set({ isAllProduct }),
  setProductID: (productID) => set({ productID }),
  setSchedule: (schedule) => set({ schedule }),
  setStartDate: (startDate) => set({ startDate }),
  setEndDate: (endDate) => set({ endDate }),
  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
  setPage: (page) => set({ page }),
}));
