import {
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Spin,
  message,
  Select,
  Space,
  Input,
  List,
  Divider,
  Table,
  InputNumber,
  Alert,
  Tooltip,
  Tag,
} from "antd";
import {
  SaveOutlined,
  PercentageOutlined,
  DeliveredProcedureOutlined,
} from "@ant-design/icons";
import { useAppBanner } from "./store";
import { useQuery, useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import {
  getFoodParty,
  editFoodParty,
  createFoodParty,
  getProductGroupByVendor,
  getProductCount,
  editFoodPartyLimit,
  getAppbannersVendor,
} from "../../api";
import { useEffect, useRef, useState } from "react";
import Avatar from "antd/lib/avatar/avatar";
import Text from "antd/lib/typography/Text";
const { Title } = Typography;
const { Option } = Select;
const { Search } = Input;

export const AddProducts = () => {
  const containerRef = useRef(null);
  const scrollPositionRef = useRef(0);
  const {
    setIsAddProduct,
    isAddProduct,
    appBanner_vendor,
    id,
    productLimit,
    orderLimit,
    setOrderLimit,
    setProductLimit,
  } = useAppBanner();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [apiProducts, setApiProducts] = useState([]);
  // const [catSearch, setCatSearch] = useState("");
  const [addedProductsSearch, setAddedProductsSearch] = useState(null);
  const [notAddedProductsSearch, setNotAddedProductsSearch] = useState(null);
  const [selectedCat, setSelectedCat] = useState(null);
  const [vendorID, setVendorID] = useState(null);
  const [selectedInput, setSelectedInput] = useState({ id: 0, key: "" });

  const resetState = () => {
    setSelectedCat(null);
    setVendorID(null);
    setSelectedProducts([]);
    setApiProducts([]);
    // setCatSearch("");
    setAddedProductsSearch(null);
    setNotAddedProductsSearch(null);
    setSelectedInput({ id: 0, key: "" });
    scrollPositionRef.current = 0;
  };

  const hasDiscount = apiProducts?.some((item) =>
    Object.keys(item).includes("totalValue")
  );

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editFoodParty,
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          resetState();
          queryClient.invalidateQueries("appbanners");
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );
  const { mutate: editLimitMutate, isLoading: editLimitLoading } = useMutation(
    editFoodPartyLimit,
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          resetState();
          queryClient.invalidateQueries("appBanner");
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );
  const { mutate: createMutate, isLoading: createLoading } = useMutation(
    createFoodParty,
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          resetState();
          queryClient.invalidateQueries("appbanners");
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const {
    isLoading: loadingCategory,
    data: category,
    refetch: refetchCat,
  } = useQuery(
    ["productGroupFilter", vendorID, 1, 500],
    getProductGroupByVendor,
    {
      refetchOnWindowFocus: false,
      retry: 1,
      enabled: !!vendorID,
    }
  );

  const {
    isLoading: bannerLoading,
    data: bannerVendors,
    refetch: getVendors,
  } = useQuery(["appbanners/vendors", [], id], getAppbannersVendor, {
    refetchOnWindowFocus: false,
    retry: 2,
    enabled: false,
  });

  const {
    isLoading: loadingFoodParty,
    data: foodPartydata,
    refetch,
  } = useQuery(
    ["appbanners", notAddedProductsSearch, 1, 10, selectedCat, vendorID, id],
    getFoodParty,
    {
      refetchOnWindowFocus: false,
      retry: 2,
      enabled: !!vendorID,
    }
  );
  const { data: ProductCount, refetch: refetchProductCount } = useQuery(
    [id],
    getProductCount,
    {
      refetchOnWindowFocus: false,
      retry: 2,
      enabled: !!id,
    }
  );

  useEffect(() => {
    if (id !== null && isAddProduct) {
      refetchProductCount();
    }
  }, [id]);

  useEffect(() => {
    if (vendorID !== null) {
      refetch();
      refetchCat();
    }
  }, [vendorID, notAddedProductsSearch]);

  useEffect(() => {
    setNotAddedProductsSearch(null);
    setSelectedCat(null);
  }, [vendorID]);

  useEffect(() => {
    if (foodPartydata && foodPartydata.success) {
      if (foodPartydata?.data?.foodparty !== null) {
        const existingPartyProductIds =
          selectedProducts?.length === 0
            ? foodPartydata?.data?.foodparty?.foodPartyProducts?.map(
                (product) => product.id
              )
            : selectedProducts?.map((product) => product.id);

        const productsNotInFoodPartyProducts =
          foodPartydata?.data?.vendorProducts?.records?.filter(
            (product) => !existingPartyProductIds?.includes(product.id)
          );

        setApiProducts(productsNotInFoodPartyProducts);
      } else {
        setApiProducts(foodPartydata?.data?.vendorProducts?.records);
      }

      if (notAddedProductsSearch === null && selectedCat === null) {
        setSelectedProducts(
          foodPartydata?.data?.foodparty !== null
            ? foodPartydata?.data?.foodparty?.foodPartyProducts
            : []
        );
      }
    }
  }, [foodPartydata]);

  const removeProductFromList = (productId) => {
    const updatedProducts = apiProducts.filter(
      (product) => product.id !== productId
    );
    setApiProducts(updatedProducts);
  };

  const ProductList = () => {
    const handleAddToCart = (
      product,
      quantity,
      safetyQty,
      priority,
      discount,
      vendorDiscount,
      balyDiscount
    ) => {
      const updatedProduct = {
        ...product,
        qty: quantity || 1,
        prio: priority || 1,
        safetyQty: safetyQty || 0,
        discount: discount || 0,
        vendorDiscount: vendorDiscount || 0,
        balyDiscount: balyDiscount || 0,
      };

      setSelectedProducts((prevSelectedProducts) =>
        prevSelectedProducts
          ? [...prevSelectedProducts, updatedProduct]
          : [updatedProduct]
      );

      removeProductFromList(product.id);
    };

    return (
      <div
        style={{
          overflow: "auto",
          maxHeight: "300px",
        }}
      >
        <List
          itemLayout="horizontal"
          loading={loadingFoodParty}
          dataSource={apiProducts}
          renderItem={(product) => (
            <Tooltip
              placement="left"
              title={product.totalValue ? "Alredy havd a discount" : null}
            >
              <List.Item
                actions={[
                  <Button
                    disabled={product.totalValue ? true : false}
                    type="link"
                    size="small"
                    onClick={() =>
                      handleAddToCart(
                        product,
                        product.qty,
                        product.safetyQty,
                        product.prio,
                        product.discount,
                        product.vendorDiscount,
                        product.balyDiscount
                      )
                    }
                  >
                    add
                  </Button>,
                ]}
              >
                <List.Item.Meta
                  avatar={<Avatar src={product.img} />}
                  title={product.name}
                  description={product.description}
                  style={product.totalValue ? { opacity: "0.5" } : null}
                />
              </List.Item>
            </Tooltip>
          )}
        />
      </div>
    );
  };

  const filteredProducts =
    selectedProducts?.filter((product) =>
      product.name?.toLowerCase().includes(addedProductsSearch?.toLowerCase())
    ) || [];

  const handelAddAll = () => {
    const updatedSelectedProducts =
      selectedProducts?.length > 0 ? [...selectedProducts] : [];

    foodPartydata &&
      foodPartydata?.data?.vendorProducts?.records?.forEach((product) => {
        const isProductAlreadyAdded = selectedProducts?.some(
          (selectedProduct) => selectedProduct.id === product.id
        );

        if (!isProductAlreadyAdded) {
          const updatedProduct = {
            ...product,
            qty: product.quantity || 1,
            safetyQty: product.safetyQty || 0,
            prio: product.priority || 1,
            discount: product.discount || 0,
            balyDiscount: product.balyDiscount || 0,
            vendorDiscount: product.vendorDiscount || 0,
          };

          updatedSelectedProducts.push(updatedProduct);
        }
      });

    setSelectedProducts(updatedSelectedProducts);
    setApiProducts([]);
  };

  const handleRemoveAll = () => {
    let updatedSelectedProducts = [];

    if (filteredProducts.length > 0) {
      updatedSelectedProducts = selectedProducts?.filter(
        (product) => !filteredProducts.includes(product)
      );
      setSelectedProducts(updatedSelectedProducts);
      setApiProducts([...apiProducts, ...filteredProducts]);
    } else {
      setSelectedProducts([]);
      setApiProducts(
        foodPartydata && foodPartydata?.data?.vendorProducts?.records
      );
    }
  };

  const AddedProductList = () => {
    useEffect(() => {
      if (containerRef.current) {
        // Restore the scroll position after re-render
        containerRef.current.scrollTop = scrollPositionRef.current;
      }
    }, []);

    useEffect(() => {
      // Store the scroll position on scroll
      const handleScroll = () => {
        if (containerRef.current) {
          scrollPositionRef.current = containerRef.current.scrollTop;
        }
      };

      if (containerRef.current) {
        containerRef.current.addEventListener("scroll", handleScroll);
      }

      return () => {
        if (containerRef.current) {
          containerRef.current.removeEventListener("scroll", handleScroll);
        }
      };
    }, []);

    const handleRemoveFromCart = (product) => {
      const updatedSelectedProducts = selectedProducts?.filter(
        (item) => item.id !== product.id
      );
      setSelectedProducts(updatedSelectedProducts);
      setApiProducts([product, ...apiProducts]);
    };

    const handleQuantityChange = (value, product, key) => {
      const updatedSelectedProducts = selectedProducts?.map((item) => {
        if (item.id === product.id) {
          return {
            ...item,
            qty: value,
          };
        }
        return item;
      });
      setSelectedInput({
        id: product.id,
        key: key,
      });
      setSelectedProducts(updatedSelectedProducts);
    };

    const handleSafetyQtyChange = (value, product, key) => {
      const updatedSelectedProducts = selectedProducts?.map((item) => {
        if (item.id === product.id) {
          return {
            ...item,
            safetyQty: value,
          };
        }
        return item;
      });
      setSelectedInput({
        id: product.id,
        key: key,
      });
      setSelectedProducts(updatedSelectedProducts);
    };

    const handlePriorityChange = (value, product, key) => {
      const updatedSelectedProducts = selectedProducts?.map((item) => {
        if (item.id === product.id) {
          return {
            ...item,
            prio: value,
          };
        }
        return item;
      });
      setSelectedInput({
        id: product.id,
        key: key,
      });
      setSelectedProducts(updatedSelectedProducts);
    };
    const handleDiscountChange = (value, product, key) => {
      const totalDiscount = value;
      const balyDiscount = product.balyDiscount;
      const vendorDiscount = totalDiscount - balyDiscount;

      const updatedSelectedProducts = selectedProducts?.map((item) => {
        if (item.id === product.id) {
          return {
            ...item,
            discount: totalDiscount,
            vendorDiscount,
          };
        }
        return item;
      });
      setSelectedInput({
        id: product.id,
        key: key,
      });
      setSelectedProducts(updatedSelectedProducts);
    };

    const handleBalyDiscountChange = (value, product, key) => {
      const totalDiscount = product.discount;
      const balyDiscount = value;
      const vendorDiscount = totalDiscount - balyDiscount;

      const updatedSelectedProducts = selectedProducts?.map((item) => {
        if (item.id === product.id) {
          return {
            ...item,
            balyDiscount,
            vendorDiscount,
          };
        }
        return item;
      });
      setSelectedInput({
        id: product.id,
        key: key,
      });
      setSelectedProducts(updatedSelectedProducts);
    };

    const handleVendorDiscountChange = (value, product) => {
      const totalDiscount = product.discount;
      const vendorDiscount = value;
      const balyDiscount = totalDiscount - vendorDiscount;

      const updatedSelectedProducts = selectedProducts?.map((item) => {
        if (item.id === product.id) {
          return {
            ...item,
            vendorDiscount,
            balyDiscount,
          };
        }
        return item;
      });

      setSelectedProducts(updatedSelectedProducts);
    };

    const columns = [
      {
        title: "Image",
        dataIndex: "img",
        key: "img",
        width: 70,
        render: (img) => <Avatar src={img} />,
      },
      {
        title: "Name & Description",
        dataIndex: "name",
        key: "name",
        width: 200,
        render: (name, row) => (
          <Space size={row.description ? 10 : 0} direction="vertical">
            <Text>{name}</Text>
            <Text type="secondary">{row.description}</Text>
          </Space>
        ),
      },
      {
        title: "Current Qty",
        dataIndex: "currentQty",
        key: "currentQty",
        render: (currentQty) => <Tag color="geekblue">{currentQty}</Tag>,
      },
      {
        title: "Qty",
        dataIndex: "qty",
        key: "qty",
        render: (qty, product) => (
          <InputNumber
            autoFocus={
              product.id === selectedInput.id && selectedInput.key === "qty"
                ? "autoFocus"
                : ""
            }
            style={{ width: "70px" }}
            defaultValue={1}
            min={product?.safetyQty + 1 || 1}
            value={qty}
            onChange={(value) => handleQuantityChange(value, product, "qty")}
          />
        ),
      },
      {
        title: "Safety Qty",
        dataIndex: "safetyQty",
        key: "safetyQty",
        render: (safetyQty, product) => (
          <InputNumber
            autoFocus={
              product.id === selectedInput.id &&
              selectedInput.key === "safetyQty"
                ? "autoFocus"
                : ""
            }
            style={{ width: "70px" }}
            defaultValue={0}
            min={0}
            max={product?.qty - 1}
            value={safetyQty}
            onChange={(value) =>
              handleSafetyQtyChange(value, product, "safetyQty")
            }
          />
        ),
      },
      {
        title: "Priority",
        dataIndex: "prio",
        key: "prio",
        render: (prio, product) => (
          <InputNumber
            autoFocus={
              product.id === selectedInput.id && selectedInput.key === "prio"
                ? "autoFocus"
                : ""
            }
            style={{ width: "70px" }}
            min={0}
            defaultValue={1}
            value={prio}
            onChange={(value) => handlePriorityChange(value, product, "prio")}
          />
        ),
      },
      {
        title: "Discount",
        dataIndex: "discount",
        key: "discount",
        render: (discount, product) => (
          <InputNumber
            autoFocus={
              product.id === selectedInput.id &&
              selectedInput.key === "discount"
                ? "autoFocus"
                : ""
            }
            style={{ width: "100px" }}
            min={0}
            max={100}
            defaultValue={0}
            value={discount}
            onChange={(value) =>
              handleDiscountChange(value, product, "discount")
            }
            addonAfter={<PercentageOutlined />}
          />
        ),
      },
      {
        title: "Baly Discount",
        dataIndex: "balyDiscount",
        key: "balyDiscount",
        render: (balyDiscount, product) => (
          <InputNumber
            autoFocus={
              product.id === selectedInput.id &&
              selectedInput.key === "balyDiscount"
                ? "autoFocus"
                : ""
            }
            style={{ width: "100px" }}
            min={0}
            defaultValue={0}
            max={product.discount}
            value={balyDiscount}
            onChange={(value) =>
              handleBalyDiscountChange(value, product, "balyDiscount")
            }
            addonAfter={<PercentageOutlined />}
          />
        ),
      },
      {
        title: "Vendor Discount",
        dataIndex: "vendorDiscount",
        key: "vendorDiscount",
        render: (vendorDiscount, product) => (
          <InputNumber
            style={{ width: "100px" }}
            min={0}
            defaultValue={0}
            readOnly={true}
            value={vendorDiscount}
            max={product.discount}
            onChange={(value) => handleVendorDiscountChange(value, product)}
            addonAfter={<PercentageOutlined />}
          />
        ),
      },
      {
        title: "Actions",
        key: "actions",
        render: (_, product) => (
          <Button
            type="link"
            danger
            size="small"
            onClick={() => handleRemoveFromCart(product)}
          >
            remove
          </Button>
        ),
      },
    ];

    return (
      <div ref={containerRef} style={{ overflow: "auto", maxHeight: "300px" }}>
        <Table
          loading={loadingFoodParty}
          columns={columns}
          dataSource={
            addedProductsSearch !== null && addedProductsSearch.trim() !== ""
              ? filteredProducts
              : selectedProducts && selectedProducts?.length > 0
              ? selectedProducts
              : []
          }
          pagination={false}
          rowKey="id"
        />
      </div>
    );
  };

  function arraysOfObjectsAreEqual(arr1, arr2) {
    //check if only the limit changed so we send only limit no need to send all data again
    if (arr1 === undefined || arr2 === undefined) {
      return true; //return true if onr of arrays undefined
    }
    if (arr1?.length !== arr2?.length) {
      return false;
    }

    for (let i = 0; i < arr1?.length; i++) {
      const obj1 = arr1[i];
      const obj2 = arr2[i];

      if (!objectsAreEqual(obj1, obj2)) {
        return false;
      }
    }

    return true;
  }

  function objectsAreEqual(obj1, obj2) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }

    return true;
  }

  const handleSave = () => {
    if (
      arraysOfObjectsAreEqual(
        foodPartydata?.data?.foodparty !== null
          ? foodPartydata?.data?.foodparty?.foodPartyProducts
          : [],
        selectedProducts
      )
    ) {
      let _data = {
        productLimit: productLimit,
        orderLimit: orderLimit,
        id: id,
      };

      editLimitMutate(_data);
    } else {
      if (foodPartydata?.data?.foodparty !== null) {
        const _products = selectedProducts
          .filter(
            (product) =>
              product.discount !== 0 &&
              (product.balyDiscount !== 0 || product.vendorDiscount !== 0)
          )
          .map((product) => ({
            productID: String(product.id),
            name: product.name,
            description: product.description,
            img: product.img,
            qty: product.qty,
            prio: product.prio,
            safetyQty: product.safetyQty,
            // currentqty: product.currentqty || product.qty,
            discount: product.discount,
            balyDiscount: product.balyDiscount,
            vendorDiscount: product.vendorDiscount,
            soldQty: product.soldQty,
          }));
        const _data = {
          id: foodPartydata?.data?.foodparty?.id,
          bannerID: id,
          ...(productLimit !== null && { productLimit: productLimit }),
          ...(orderLimit !== null && { orderLimit: orderLimit }),
          products: JSON.stringify(_products),
        };
        editMutate(_data);
      } else {
        const _products = selectedProducts
          .filter(
            (product) =>
              product.discount !== 0 &&
              (product.balyDiscount !== 0 || product.vendorDiscount !== 0)
          )
          .map((product) => ({
            productID: String(product.id),
            name: product.name,
            description: product.description,
            img: product.img,
            qty: product.qty,
            prio: product.prio,
            safetyQty: product.safetyQty,
            // currentqty: product.currentqty || product.qty,
            discount: product.discount,
            balyDiscount: product.balyDiscount,
            vendorDiscount: product.vendorDiscount,
          }));
        const _data = {
          bannerID: id,
          vendorID: vendorID,
          ...(productLimit !== null && { productLimit: productLimit }),
          ...(orderLimit !== null && { orderLimit: orderLimit }),
          products: JSON.stringify(_products),
        };
        createMutate(_data);
      }
    }
  };
  return (
    <Modal
      title={<Title level={5}>Products to Banner</Title>}
      className="app-modal"
      centered
      visible={isAddProduct}
      bodyStyle={{ paddingTop: 5 }}
      onCancel={() => {
        setIsAddProduct(false);
        resetState();
      }}
      footer={false}
      destroyOnClose={true}
      width={1700}
    >
      <Row>
        <Col span={5}>
          <Select
            showSearch
            filterOption={true}
            optionFilterProp="children"
            style={{ width: "90%" }}
            placeholder="Select a vendor"
            onChange={(val) => setVendorID(val)}
            value={vendorID}
            onClick={() => getVendors()}
          >
            {bannerVendors?.data?.map((el) => (
              <Option key={el.id} id={el.id} value={el.id}>
                {el.arName}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={5}>
          <Select
            showSearch
            filterOption={true}
            optionFilterProp="children"
            style={{ width: "90%" }}
            // onSearch={(val) => setCatSearch(val)}
            onChange={(val) => setSelectedCat(val)}
            placeholder="Find categories"
            allowClear
            value={selectedCat}
          >
            {!loadingCategory ? (
              category && category?.success ? (
                category?.data?.records?.map((el) => (
                  <Option
                    key={el.id}
                    id={el.id}
                    value={el.id}
                    // disabled={isRepeated(el.id)}
                  >
                    {el.name}
                  </Option>
                ))
              ) : null
            ) : (
              <Spin spinning={true} />
            )}
          </Select>
        </Col>

        <Col span={10}>
          <Space direction="horizontal">
            <Space direction="horizontal">
              <Text style={{ fontStyle: "italic" }}>Product Limit : </Text>
              <InputNumber
                placeholder="product limit"
                style={{ width: "50%" }}
                value={productLimit}
                onChange={(val) => setProductLimit(val)}
              />
            </Space>
            <Space direction="horizontal">
              <Text style={{ fontStyle: "italic" }}>Order Limit : </Text>
              <InputNumber
                placeholder="order limit"
                style={{ width: "50%" }}
                value={orderLimit}
                onChange={(val) => setOrderLimit(val)}
              />
            </Space>
          </Space>
        </Col>
        <Col span={4} style={{ marginTop: "6px" }}>
          <Space direction="horizontal">
            <Tag color="pink">
              {" "}
              Total Vendor :{" "}
              {appBanner_vendor?.length ? appBanner_vendor?.length : 0}
            </Tag>
            <Tag color="green"> Total Products : {ProductCount?.data}</Tag>
          </Space>
        </Col>
      </Row>
      <Divider />
      <Row gutter={[10, 0]}>
        <Col span={5}>
          <div
            style={{
              padding: "30px",
              backgroundColor: "#E8F1F7",
              border: "1px solid #f2f2f2",
              borderRadius: "2px",
              boxShadow: "0 0 2px #f2f2f2",
              height: "400px",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Search
                onSearch={(val) => setNotAddedProductsSearch(val)}
                placeholder="Search..."
                style={{ width: "70%" }}
              />
              <Tooltip
                placement="top"
                title={
                  hasDiscount ? "Some products alredy havd a discount" : null
                }
              >
                <Button
                  type="primary"
                  ghost
                  onClick={() => {
                    handelAddAll();
                  }}
                  disabled={hasDiscount}
                >
                  add all
                </Button>
              </Tooltip>
            </div>

            <Divider />
            <ProductList />
          </div>
        </Col>

        <Col span={19}>
          <div
            style={{
              padding: "30px 15px 15px 15px",
              backgroundColor: "#E8F1F7",
              border: "1px solid #f2f2f2",
              borderRadius: "2px",
              boxShadow: "0 0 2px #f2f2f2",
              height: "400px",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Search
                placeholder="Search..."
                style={{ width: "40%" }}
                onSearch={(val) => setAddedProductsSearch(val)}
              />
              <Button
                type="primary"
                danger
                ghost
                onClick={() => {
                  handleRemoveAll();
                }}
              >
                remove all
              </Button>
            </div>

            <Divider />
            <AddedProductList />
          </div>
          {selectedProducts?.length > 0 && (
            <Alert
              message="Please note that any products with a discount value of 0 or both baly discount and vendor discount values is 0 will not be saved."
              banner
            />
          )}
        </Col>
      </Row>
      <Space>
        <Divider />
      </Space>
      <Col span={18}>
        <Space>
          <Button
            loading={editLoading || createLoading || editLimitLoading}
            type="primary"
            onClick={handleSave}
            icon={<SaveOutlined />}
            // disabled={vendorID === null ? true : false}
          >
            Save
          </Button>

          <Button
            loading={editLoading || createLoading || editLimitLoading}
            type="primary"
            onClick={() => {
              setIsAddProduct(false);
              handleSave();
            }}
            icon={<DeliveredProcedureOutlined />}
            style={{
              backgroundColor:
                // vendorID === null ? "#f5f5f5" :
                "#4d78b6",
              borderColor:
                // vendorID === null ? "#d9d9d9" :
                "#4d78b6",
            }}
            // disabled={vendorID === null ? true : false}
          >
            Save & Close
          </Button>
        </Space>
      </Col>
    </Modal>
  );
};
