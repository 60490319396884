import { Form, Button, Row, Col, Divider, Select, message } from "antd";
import Input from "antd/lib/input/Input";

import { GetClient } from "../forms";
import { useResturant } from "../store";
import { useMutation } from "react-query";
import { queryClient } from "../../../queryClient";
import { createVendors } from "../../../api";

const { Option } = Select;

export const ClientForm = () => {
  const {
    step,
    isNewClient,
    isClientExest,
    setIsClientExest,
    setIsNewClient,
    setIsModalFilter,
    setStep,
    name,
    username,
    email,
    phone,
    setName,
    setEmail,
    setPhone,
    setUsername,
    setRoleID,
  } = useResturant();

  const [form] = Form.useForm();

  const { mutate, isLoading, error } = useMutation(createVendors, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        queryClient.invalidateQueries("employees");
        setName(form.getFieldValue("name"));
        setEmail(form.getFieldValue("email"));
        setPhone(form.getFieldValue("phone"));
        setUsername(form.getFieldValue("username"));
        setRoleID(form.getFieldValue("roleID"));
        setIsClientExest(true);
        setIsNewClient(false);
        setIsModalFilter(false);
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const onFinish = (values) => {
    if (error) {
      message.error(error.message);
    } else {
      mutate(values);
    }
  };

  return (
    <div className="app-flex-column">
      <GetClient />
      <br />
      {isNewClient || isClientExest ? (
        <Form
          requiredMark={"optional"}
          name="basic"
          form={form}
          initialValues={
            isClientExest
              ? { remember: true, name, username, email, phone }
              : { remember: true, name: "", username: "", email: "", phone: "" }
          }
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
        >
          <Row gutter={[20, 20]}>
            <Col span={24}>
              <Form.Item
                label="Client Name"
                name="name"
                rules={[{ required: true }]}
              >
                <Input
                  disabled={isClientExest}
                  placeholder="Murtadha M. Abed"
                />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label="Username"
                name="username"
                rules={[{ required: true }]}
              >
                <Input disabled={isClientExest} placeholder={"baly001"} />
              </Form.Item>
            </Col>
            <Col span={14}>
              <Form.Item
                label="Phone Number"
                name="phone"
                rules={[{ required: true }]}
              >
                <Input disabled={isClientExest} placeholder={"0789887654"} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Email" name="email" rules={[{ type: "email" }]}>
                <Input
                  disabled={isClientExest}
                  placeholder={"example@baly.iq"}
                />
              </Form.Item>
            </Col>

            {!isClientExest ? (
              <Col span={10}>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="******" />
                </Form.Item>
              </Col>
            ) : null}
            <Col span={14}>
              <Form.Item
                label="Role"
                name="roleID"
                rules={[{ required: true }]}
              >
                <Select
                  disabled={isClientExest}
                  showSearch
                  filterOption={false}
                  placeholder="Super Admin"
                >
                  <Option value={1}>Super Admin</Option>
                  <Option value={2}>Manager</Option>
                  <Option value={3}>User</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={24} className="resturant-constroll-form">
              <Divider />
              {isNewClient ? (
                <Form.Item>
                  <Button loading={isLoading} type="primary" htmlType="submit">
                    Save & Continue
                  </Button>
                </Form.Item>
              ) : (
                <Button
                  onClick={() => setStep(step + 1)}
                  type="primary"
                  htmlType="submit"
                >
                  Next
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      ) : null}
    </div>
  );
};
