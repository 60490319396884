import create from "zustand";

export const useReferalCodes = create((set) => ({
  queryKey: "",
  isCountModal: false,
  page: 1,
  countJson: null,
  clientID: null,
  from: null,
  to: null,

  setQueryKey: (queryKey) => set({ queryKey }),
  setClientID: (clientID) => set({ clientID }),
  setFrom: (from) => set({ from }),
  setTo: (to) => set({ to }),
  setPage: (page) => set({ page }),
  setCountJson: (countJson) => set({ countJson }),
  setIsCountModal: (isCountModal) => set({ isCountModal }),
}));
