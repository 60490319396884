import { Card, Breadcrumb } from "antd";
import { PureTable } from "./pureTable";
import VendorPerformanceScoreFilters from "./filters";

export const VendorPerformanceScorePage = () => {
  return (
    <div className="app-page">
      <Breadcrumb style={{ marginBottom: 10 }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Vendor Performance Score</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <div className="app-space-between">
          <VendorPerformanceScoreFilters />

          {/* <Tooltip placement="top" title="export filterd data">
            <Button
              onClick={() => refetch()}
              loading={loading}
              type="primary"
              icon={<FileExcelOutlined />}
            >
              Export
            </Button>
          </Tooltip> */}
        </div>
        <br />
        <PureTable />
      </Card>
    </div>
  );
};
