import { useEffect } from "react";
import {
  Table,
  Tag,
  Space,
  Button,
  Typography,
  Alert,
  message,
  Popconfirm,
  Pagination,
  Switch,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  SwapRightOutlined,
} from "@ant-design/icons";
import { useMutation, useQuery } from "react-query";
import { queryClient } from "../../queryClient";
import {
  getFreeDelivery,
  removeFreeDelivery,
  editFreeDeliveryStatus,
  pageSize,
} from "../../api";
import dayjs from "dayjs";
import moment from "moment";
import { useFreeDelivery } from "./store";
import authFunc from "../../helper/auth";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";

const { Text } = Typography;
export const PureTable = () => {
  const {
    queryKey,
    setEndDate,
    setStartDate,
    setMaxAmount,
    setDayOfWeek,
    setMinAmount,
    setId,
    setIsCreateModal,
    setSchedule,
    setTotalValue,
    from,
    to,
    status,
    setVendor,
    setPage,
    page,
    setVendorValue,
    setBalyValue,
    setVendorID,
  } = useFreeDelivery();

  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedID, setSelectedID] = useState(null);

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const handlEdit = (row) => {
    setEndDate(moment(row.endDate));
    setStartDate(moment(row.startDate));
    setDayOfWeek(row.dayOfWeek);
    setMinAmount(row.minamount);
    setMaxAmount(row.maxamount);
    setId(row.id);
    setSchedule(
      JSON.parse(row.schedule)?.length > 0 ? JSON.parse(row.schedule) : null
    );
    setTotalValue(row.totalValue);
    setIsCreateModal(true);
    setVendor(row.vendor);
    setVendorValue(row.vendorValue);
    setVendorID(row.vendorID);
    setBalyValue(row.balyValue);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Vendor",
      dataIndex: "vendor",
      key: "vendor",
      render: (text) => (
        <Space>
          <Text style={{ fontStyle: "italic", fontSize: 16 }}>{text}</Text>
        </Space>
      ),
    },
    {
      title: "Range Value",
      key: "id",
      render: (row, val) => (
        <Tag>
          {Number(row.minamount).toLocaleString()} <SwapRightOutlined />
          {Number(row.maxamount).toLocaleString()} <b>IQD</b>
        </Tag>
      ),
    },
    {
      title: "Total Value",
      dataIndex: "totalValue",
      key: "totalValue",
      render: (val, row) => (
        <Tag>
          <b>{val + " %"}</b>
        </Tag>
      ),
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (text) => (
        <Text type="secondary">{dayjs(text).format("YYYY-MM-DD")}</Text>
      ),
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (text) => (
        <Text type="secondary">{dayjs(text).format("YYYY-MM-DD")}</Text>
      ),
    },
    {
      title: "Status",
      key: "id",
      render: (row) => (
        <Switch
          loading={selectedID === row.id && editLoading}
          checked={row?.status && row?.status === true ? true : false}
          disabled={!authFunc("DELIVERY_DISCOUNT_UPDATE")}
          onChange={(state) => handleChangeActive(row)}
        />
      ),
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id, row) => (
        <Space size="small">
          {authFunc("DELIVERY_DISCOUNT_UPDATE") && (
            <Button
              onClick={() => handlEdit(row)}
              size="small"
              icon={<EditOutlined />}
            />
          )}
          {authFunc("DELIVERY_DISCOUNT_DELETE") && (
            <Popconfirm
              title="Are you sure to delete this task?"
              onConfirm={() => mutate(id)}
              okText="Yes"
              cancelText="No"
            >
              <Button size="small" danger icon={<DeleteOutlined />} />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  const { mutate } = useMutation((val) => removeFreeDelivery(val), {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        queryClient.invalidateQueries("freeDelivery");
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(
    ["freeDelivery", queryKey, page, from, to, status],
    getFreeDelivery,
    {
      retry: 2,
      refetchOnWindowFocus: false,
    }
  );

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editFreeDeliveryStatus,
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          setSelectedID(null);
          queryClient.invalidateQueries("freeDelivery");
        } else {
          setSelectedID(null);
          message.error(data.err);
        }
      },
      onError: (e) => {
        setSelectedID(null);
        message.error(e.message);
      },
    }
  );

  const handleChangeActive = (row) => {
    setSelectedID(row.id);
    editMutate({
      id: row.id,
      status: !row.status,
      vendorID: row.vendorID,
    });
  };

  if (error || (!data?.success && data?.data?.err))
    return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />
    );

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data ? data.data.records : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        defaultPageSize={pageSize}
        total={data && data.data.total}
        showSizeChanger={false}
      />
    </>
  );
};
