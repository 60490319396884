import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  message,
  InputNumber,
} from "antd";
import { SafetyOutlined } from "@ant-design/icons";
import { useVendorPayment } from "./store";
import { useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import { createPayment } from "../../api";
import { useState } from "react";
import dayjs from "dayjs";

const { Title } = Typography;

const numberForamt = (number) =>
  String(number).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

export const ConfirmPayment = () => {
  const {
    setIsConfirmModal,
    setTotalValue,
    setIsPayModal,
    setPayedAmount,
    setReceiverName,
    isConfirmModal,
    receiverName,
    payedAmount,
    vendorID,
    from,
    to,
  } = useVendorPayment();

  const [isEqual, setIsEqual] = useState(null);

  const resetState = () => {
    setIsConfirmModal(false);
    setIsPayModal(false);
    setIsEqual(null);
    setPayedAmount(null);
    setReceiverName(null);
    setTotalValue(null);
  };

  // const fromDateFormated = moment(date.setHours(9, 0, 0, 0)).format("YYYY-MM-DD HH:mm");
  // const toDateFormated = moment(date.setHours(23, 59, 59, 0)).format("YYYY-MM-DD HH:mm");

  const { mutate } = useMutation(createPayment, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        resetState();
        queryClient.invalidateQueries("vendorPayment");
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const onFinish = (val) => {
    if (val.amount === payedAmount) {
      setIsEqual(true);
      let _data = {
        vendorID: vendorID,
        receiverName: receiverName,
        amount: payedAmount,
        paymentFrom: dayjs(JSON.parse(from)).toISOString(),
        paymentTo: dayjs(JSON.parse(to)).toISOString(),
      };
      mutate(_data);
    } else {
      setIsEqual(false);
    }
  };

  return (
    <Modal
      title={<Title level={5}> Confirm Payment </Title>}
      className="app-modal"
      centered
      visible={isConfirmModal}
      onCancel={() => {
        setIsConfirmModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={400}
    >
      <p>
        {" "}
        Type the amount {numberForamt(payedAmount)} IQD to confirm payment{" "}
      </p>

      <Form
        requiredMark={"optional"}
        name="basic"
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Form.Item
              label="Amount"
              name="amount"
              validateStatus={isEqual === false && "error"}
              help={isEqual === false && "Should be the same amount"}
              rules={[{ required: true }]}
            >
              <InputNumber
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                placeholder={"Amount"}
                addonAfter="IQD"
                style={{ width: "100%" }}
                // min={0}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                icon={<SafetyOutlined />}
              >
                Confirm
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
