import create from "zustand";

export const useWaterfallProcessAction = create((set) => ({
  isCreateModal: false,
  id: null,
  queryKey: "",
  priority: null,
  saturationRate: null,
  bufferTime: null,
  type: [],
  value: null,
  maxSR: null,
  minSR: null,
  steps: null,
  delieveryfee: null,
  delieveryfeeID: null,
  isDelieveryfeeModal: false,
  delieveryModalData: null,
  statusCodeID: null,
  minActiveOrders: null,

  setQueryKey: (queryKey) => set({ queryKey }),
  setId: (id) => set({ id }),
  setPriority: (priority) => set({ priority }),
  setSaturationRate: (saturationRate) => set({ saturationRate }),
  setMaxSR: (maxSR) => set({ maxSR }),
  setMinSR: (minSR) => set({ minSR }),
  setBufferTime: (bufferTime) => set({ bufferTime }),
  setType: (type) => set({ type }),
  setValue: (value) => set({ value }),
  setSteps: (steps) => set({ steps }),
  setDelieveryfee: (delieveryfee) => set({ delieveryfee }),
  setDelieveryfeeID: (delieveryfeeID) => set({ delieveryfeeID }),
  setIsDelieveryfeeModal: (isDelieveryfeeModal) => set({ isDelieveryfeeModal }),
  setDelieveryModalData: (delieveryModalData) => set({ delieveryModalData }),
  setStatusCodeID: (statusCodeID) => set({ statusCodeID }),
  setMinActiveOrders: (minActiveOrders) => set({ minActiveOrders }),
  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
}));
