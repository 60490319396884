import { useState, useEffect } from "react";
import { Button, Row, Col, Divider, message, Select, Tag, Space } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useDiscount } from "../../vendor_discounts/store";
import { useMutation, useQuery } from "react-query";
import { queryClient } from "../../../queryClient";
import { AddToVoucher, getClients } from "../../../api";
const { Option } = Select;

export const PureGetClients = () => {
  const { setIsCitiesModal, id, isUsersModal } = useDiscount();
  const [filter, setFilter] = useState("");

  const [number, setNumber] = useState(null);
  const [numberSelected, setNumberSelected] = useState(null);
  const [numbersList, setNumbersList] = useState([]);

  const [selected, setSelected] = useState(null);
  const [list, setList] = useState([]);

  const { data: clients, refetch } = useQuery(["clients", filter], getClients, {
    retry: 2,
    refetchOnWindowFocus: false,
    enabled: !!isUsersModal,
  });

  useEffect(() => {
    if (filter) {
      refetch();
    }
  }, [filter]);

  const isRepeated = (id) => (list.find((el) => el.id == id) ? true : false);
  const isPhoneRepeated = (phone) =>
    numbersList.find((el) => el.phone == phone) ? true : false;

  useEffect(() => {
    if (selected && clients.success) {
      setList([...list, clients.data.records.find((el) => el.id == selected)]);
      setSelected(null);
    }
  }, [selected]);

  useEffect(() => {
    if (numberSelected) {
      setNumbersList([...numbersList, { phone: numberSelected }]);
      setNumberSelected(null);
    }
  }, [numberSelected]);

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    (values) => AddToVoucher("user", values),
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          setIsCitiesModal(false);
          setList([]);
          queryClient.invalidateQueries("vouchers");
          queryClient.invalidateQueries("clientsVoucher");
        } else {
          message.error(data.err);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const onFinish = () => {
    const phones = [
      ...list.map((el) => el.phone),
      ...numbersList.map((el) => el.phone),
    ];
    let dataForm = {
      id,
      userID: phones,
    };
    editMutate(dataForm);
  };

  const parseName = (el) => {
    return `${el.id} - ${el.firstName + " " + el.lastName} - ${el.phone} `;
  };

  return (
    <div style={{ width: 460 }}>
      <Row gutter={[0, 10]}>
        <Col flex={"100%"}>
          <Select
            showSearch
            value={selected}
            onChange={(val) => setSelected(val)}
            filterOption={true}
            optionFilterProp="children"
            onSearch={(val) => setFilter(val)}
            style={{ width: "100%" }}
            placeholder="Find Clients"
            allowClear
          >
            {clients?.data?.records?.map((el) => (
              <Option
                disabled={isRepeated(el.id)}
                key={el.id}
                id={el.id}
                value={el.id}
              >
                {parseName(el)}
              </Option>
            ))}
          </Select>
        </Col>
        <Col flex={"100%"}>
          <Select
            showSearch
            value={selected}
            onChange={(val) => setNumberSelected(val)}
            optionFilterProp="children"
            onSearch={(val) => setNumber(val)}
            style={{ width: "100%" }}
            placeholder="Add Phone Number"
            allowClear
            filterOption={(input, option) => {
              return /^07[9875][0-9]{8}$/.test(input);
            }}
          >
            {number && (
              <Option disabled={isPhoneRepeated(number)} value={number}>
                {number}
              </Option>
            )}
          </Select>
        </Col>
        <Col span={24}></Col>
        <Col span={24}>
          <Space size={10} wrap>
            {list.length > 0
              ? list.map((el) => (
                  <Tag
                    key={el.id}
                    closable
                    onClose={() => {
                      setList(list.filter((item) => item.id != el.id));
                    }}
                    color="purple"
                  >
                    {parseName(el)}
                  </Tag>
                ))
              : null}
            {numbersList.length > 0
              ? numbersList.map((el) => (
                  <Tag
                    key={el.phone}
                    closable
                    onClose={() => {
                      setNumbersList(
                        numbersList.filter((item) => item.phone != el.phone)
                      );
                    }}
                    color="purple"
                  >
                    {el.phone}
                  </Tag>
                ))
              : null}
          </Space>
        </Col>
        <Col span={24}>
          <Divider />
          <Button
            loading={editLoading}
            type="primary"
            htmlType="submit"
            icon={<SaveOutlined />}
            onClick={onFinish}
          >
            Save
          </Button>
        </Col>
      </Row>
    </div>
  );
};
