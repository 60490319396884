import {
  Form,
  InputNumber,
  Modal,
  Typography,
  message,
  Divider,
  Col,
  Row,
  Button,
} from "antd";
import { useResturant } from "./store";
import { useSearchParams } from "react-router-dom";
import { SaveOutlined } from "@ant-design/icons";
import { editVendorRadius } from "../../api";
import { useMutation } from "react-query";
import { queryClient } from "../../queryClient";
const { Title } = Typography;

export const RadiusModal = () => {
  const { isCreateRadiusModal, setIsCreateRadiusModal } = useResturant();
  const [searchParams] = useSearchParams();

  const { mutate, isLoading: editLoading } = useMutation(editVendorRadius, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        setIsCreateRadiusModal(false);
        queryClient.invalidateQueries("resturants");
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const onFinish = (value) => {
    let _data = {
      cityID: Number(searchParams.get("cityID")),
      provinceID: Number(searchParams.get("provinceID")),
      statusCodeID: Number(searchParams.get("statusCodeID")),
      term: searchParams.get("q"),
      active: searchParams.get("active"),
      businessCategoryIDS: searchParams.get("businessCategoryIDS"),
      radius: value.radius / 1000,
      active_delivery_range: value.radius / 1000,
    };
    mutate(_data);
  };

  return (
    <Modal
      title={<Title level={5}>Change filtered Restaurants Radius</Title>}
      className="app-modal"
      centered
      visible={isCreateRadiusModal}
      onCancel={() => {
        setIsCreateRadiusModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={400}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Form.Item
              label="Radius"
              name="radius"
              rules={[{ required: true }]}
            >
              <InputNumber
                min={0}
                placeholder={"Restaurants radius"}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\\s?|(,*)/g, "")}
                style={{ width: "100%" }}
                addonAfter={"meters"}
              />
            </Form.Item>
          </Col>

          {/* <Col span={24}>
            <Form.Item
              label="Active Delivery Range"
              name="activeDeliveryRange"
              dependencies={["radius"]}
              rules={[
                {
                  required: true,
                  message: "Please input the active delivery range!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("radius") >= value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "Active Delivery Range must be less than Radius!"
                      )
                    );
                  },
                }),
              ]}
            >
              <InputNumber
                min={0}
                placeholder={"active delivery range"}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\\s?|(,*)/g, "")}
                style={{ width: "100%" }}
                addonAfter={"meters"}
              />
            </Form.Item>
          </Col> */}

          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={editLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
