import { Card, Input, Button, Breadcrumb } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { PureTable } from "./table";
import { PureCreate } from "./create";
import { useSubProductGroup } from "./store";
import { useParams, useNavigate } from "react-router-dom";
import authFunc from "../../helper/auth";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getResturantOne } from "../../api";
import { useQuery } from "react-query";

const { Search } = Input;

export const SubProductGroupPage = () => {
  const navigate = useNavigate();
  const { resturantID, productID } = useParams();
  const { setIsCreateModal, setQueryKey, queryKey } = useSubProductGroup();
  const [search, setSearch] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (queryKey) {
      setSearch(queryKey);
    }
  }, []);

  useEffect(() => {
    if (searchParams.get("q")) {
      setSearch(searchParams.get("q"));
      setQueryKey(searchParams.get("q"));
    } else {
      setSearch("");
      setQueryKey("");
    }
  }, [searchParams.get("q")]);

  const { data: resturant, refetch: refetchResturant } = useQuery(
    ["resturantOne", resturantID],
    getResturantOne,
    {
      retry: 2,
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  useEffect(() => {
    if (resturantID) {
      refetchResturant();
    }
  }, [resturantID]);

  return (
    <div className="app-page">
      <Breadcrumb style={{ marginBottom: 10 }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Resturants</Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => navigate(-2)}>
          <a>Resturants List</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {resturant && resturant?.data?.arName}
        </Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => navigate(-1)}>
          <a>Products</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Topping Group</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <div className="app-space-between">
          <Search
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onSearch={(val) => {
              searchParams.set("q", val);
              searchParams.set("page_num", 1);
              setSearchParams(searchParams);
              setQueryKey(val);
            }}
            style={{ width: 340 }}
            placeholder="Write To Search..."
          />

          {authFunc("TOPPINGS_GROUP_CREATE") && (
            <Button
              onClick={() => setIsCreateModal(true)}
              type="primary"
              icon={<PlusOutlined />}
            >
              Create New
            </Button>
          )}
        </div>
        <br />
        <PureTable />
        <PureCreate productID={productID} />
      </Card>
    </div>
  );
};
