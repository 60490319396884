import { Row, Col, Typography, Space, Button, Popover, Pagination } from "antd";
import { CopyOutlined, DeleteOutlined } from "@ant-design/icons";

const { Text, Link } = Typography;

const data = [
  {
    id: 1,
    image: "https://picsum.photos/300/300",
  },
  {
    id: 2,
    image: "https://picsum.photos/300/305",
  },
  {
    id: 3,
    image: "https://picsum.photos/300/304",
  },
  {
    id: 4,
    image: "https://picsum.photos/300/303",
  },
  {
    id: 5,
    image: "https://picsum.photos/300/302",
  },
  {
    id: 6,
    image: "https://picsum.photos/300/301",
  },
  {
    id: 7,
    image: "https://picsum.photos/300/311",
  },
  {
    id: 8,
    image: "https://picsum.photos/300/312",
  },
  {
    id: 9,
    image: "https://picsum.photos/300/313",
  },
  {
    id: 10,
    image: "https://picsum.photos/300/314",
  },
  {
    id: 11,
    image: "https://picsum.photos/300/315",
  },
  {
    id: 12,
    image: "https://picsum.photos/300/316",
  },
];

export const PureGrid = () => {
  return (
    <Row gutter={[30, 30]}>
      {data.map((el) => (
        <Col span={4}>
          <div>
            <img className="app-media-image" src={el.image} />
            <div className="app-space-between">
              <Popover content={<Text type="secondary">{el.image}</Text>}>
                <Link
                  className="app-media-link"
                  href={el.image}
                  target="_blank"
                >
                  {el.image}
                </Link>
              </Popover>
              <Space>
                <Button size="small" type="text" icon={<CopyOutlined />} />
                <Button
                  danger
                  size="small"
                  type="text"
                  icon={<DeleteOutlined />}
                />
              </Space>
            </div>
          </div>
        </Col>
      ))}
      <Col span={24}>
        <Pagination defaultCurrent={1} total={100} />
      </Col>
    </Row>
  );
};
