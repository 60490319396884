import create from "zustand";

export const useReviewReason = create((set) => ({
  queryKey: "",
  id: null,
  isCreateModal: false,
  title: null,
  category: null,
  type: null,
  priority: null,
  page: 1,
  categoryFilter: null,

  setCategoryFilter: (categoryFilter) => set({ categoryFilter }),
  setId: (id) => set({ id }),
  setQueryKey: (queryKey) => set({ queryKey }),
  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
  setTitle: (title) => set({ title }),
  setCategory: (category) => set({ category }),
  setType: (type) => set({ type }),
  setPriority: (priority) => set({ priority }),
  setPage: (page) => set({ page }),
}));
