import React, { useState, useEffect } from "react";
import { Col, Row, Select, message } from "antd";
import { useOrder } from "../store";

import { getValidVoucher, getPaymentType, checkVoucher } from "../../../api";
import { useQuery, useMutation } from "react-query";

const { Option } = Select;

export const MoreInfo = () => {
  const [filterPayment, setFilterPayment] = useState("");
  const [vid] = useState(null);
  const {
    setVoucher,
    setPayment,
    payment,
    voucher,
    client,
    vendor,
    products,
    id,
  } = useOrder();

  const { data: vouchers } = useQuery(
    ["vouchers", client?.id],
    getValidVoucher,
    {
      retry: 2,
    }
  );
  const { isLoading: paymentLoading, data: payments } = useQuery(
    ["paymentTypes", filterPayment],
    getPaymentType,
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );

  // useEffect(()=>{
  //    if(id && vendor.id){
  //       setvId(vendor.id)
  //    }
  // },[id])

  // const {
  //   isLoading: checkLoading,
  //   error: checkError,
  //   data: check,
  // } = useQuery(["checkOrder", ], getVouchers, {
  //   retry: 2,
  //   retry: 2,
  //   refetchOnWindowFocus: false,
  //   enabled: false,
  // });

  const { mutate } = useMutation(checkVoucher, {
    onSuccess: (data) => {
      if (data.success) {
        if (data.data) {
          message.success("Voucher is valid");
          let _voucher = vouchers.data.records.find((el) => el.id == vid);
          setVoucher(_voucher);
        } else {
          message.error("Voucher is not valide!");
        }
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  useEffect(() => {
    if (vid) {
      handleCheckVoucher();
    } else if (!id && !voucher) {
      setVoucher(null);
    }
  }, [vid]);

  const handleCheckVoucher = () => {
    let _voucher = vouchers?.data?.records.find((el) => el.id === vid);
    if (_voucher) {
      mutate({
        vid,
        cid: client.id,
        vnid: vendor.id,
        caid: client.address.id,
        code: _voucher.code,
        orderDetail: products.map((el) => {
          return {
            productID: el.id,
            qty: el.qty,
            subproductIDS: "[]",
          };
        }),
      });
    }
  };

  // if (vouchersError)
  //   return <Alert message={vouchersError.message} type="error" showIcon />;

  // if (!vouchersLoading && !vouchers.success)
  //   return <Alert message={vouchers.msg} type="error" showIcon />;

  return (
    <div className="step-container">
      <Row gutter={[20, 20]}>
        {/* <Col span={16}>
          <Select
            disabled={vouchersError || !vouchers?.success}
            showSearch
            style={{ width: "100%", marginTop: 10 }}
            value={voucher && voucher.id}
            placeholder="Find Voucher..."
            defaultActiveFirstOption={false}
            showArrow={true}
            loading={vouchersLoading || isLoading}
            allowClear
            onClear={() => {
              setvId(null);
              setVoucher(null);
            }}
            //filterOption={false}
            onSearch={(val) => setFilterVoucher(val)}
            onChange={(id) => {
              setvId(id);
            }}
            notFoundContent={null}
          >
            {vouchers &&
              vouchers.success &&
              vouchers.data.records.map((el, index) => (
                <Option key={index} value={el.id}>
                  {el.code}
                </Option>
              ))}
          </Select>
          {!vouchers?.success && (
            <Text style={{ fontSize: 12 }} type="danger">
              {vouchers?.err}
            </Text>
          )}
        </Col> */}
        <Col span={24}>
          <Select
            showSearch
            style={{ width: "100%", marginTop: 10 }}
            value={payment && payment.id}
            // placeholder={this.props.placeholder}
            // style={this.props.style}
            placeholder="Select Type"
            defaultActiveFirstOption={false}
            showArrow={true}
            loading={paymentLoading}
            //filterOption={false}
            onSearch={(val) => setFilterPayment(val)}
            onChange={(id) => {
              let _payment = payments.data.records.find((el) => el.id == id);

              setPayment(_payment);
            }}
            notFoundContent={null}
          >
            {payments &&
              payments.success &&
              payments.data.records.map((el, index) => (
                <Option key={index} value={el.id}>
                  {el.name}
                </Option>
              ))}
          </Select>
        </Col>
        {/* <Col span={24}>
          {voucher ? (
            <Card
              bodyStyle={{ padding: 16 }}
              bordered={false}
              style={{ background: "#f6f6f6", borderRadius: 6 }}
            >
              <div className="app-space-between">
                <Space direction="vertical" size={0}>
                  <small>Code</small>
                  <Space>
                    <Text style={{ fontStyle: "italic" }}>{voucher.code}</Text>
                    <Button
                      onClick={() => copyLink(voucher.code)}
                      size="small"
                      type="text"
                      icon={<CopyOutlined />}
                    />
                  </Space>
                </Space>

                <Space direction="vertical" size={0}>
                  <small>Title</small>
                  <Text>{voucher.voucherTitle}</Text>
                </Space>
                <Space direction="vertical" size={0}>
                  <small>Value</small>
                  <Text>
                    <b>
                      {voucher.totalValue}
                      {voucher.valueType == "AMOUNT" ? "IQD" : "%"}
                    </b>
                  </Text>
                </Space>
                <Space direction="vertical" size={0}>
                  <small>Type</small>
                  <Tag color="purple">{voucher.valueType}</Tag>
                </Space>
              </div>
            </Card>
          ) : null}
        </Col> */}
      </Row>
    </div>
  );
};
