import { Typography, Modal, Button, message } from "antd";
import { useResturant } from "./store";
import { PureMap } from "./map";
import { useMutation } from "react-query";
import { updateData } from "../../api";
import { queryClient } from "../../queryClient";
// import "mapbox-gl/dist/mapbox-gl.css";

const { Title } = Typography;

export const PureMapModal = ({ data }) => {
  const { isMapModal, setIsMapModal, lat, lon, id, setId } = useResturant();

  const { mutate, isLoading } = useMutation(
    (e) => updateData("vendor/location", e),
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          setIsMapModal(false);
          setId(null);
          queryClient.invalidateQueries("resturants");
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const handleSave = () => {
    mutate({
      id,
      lat,
      lon,
    });
  };

  return (
    <Modal
      title={
        <Title style={{ marginBottom: 0 }} level={5}>
          {data && data.arName}
        </Title>
      }
      className="app-modal"
      centered
      visible={isMapModal}
      onCancel={() => {
        setIsMapModal(false);
        setId(null);
      }}
      footer={false}
      destroyOnClose={true}
      width={600}
      bodyStyle={{ paddingTop: 10 }}
      //maskClosable={false}
    >
      {data ? (
        <>
          <PureMap location={[data.lon, data.lat]} />
          <br />
          {lon && lat && (
            <Button loading={isLoading} type="primary" onClick={handleSave}>
              Save Change
            </Button>
          )}
        </>
      ) : null}
    </Modal>
  );
};

// export const Map = ({ center }) => {
//   useEffect(() => {
//     if (mapboxgl.getRTLTextPluginStatus() === "unavailable") {
//       mapboxgl.setRTLTextPlugin(
//         "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js",
//         null,
//         true // Lazy load the plugin
//       );
//     }
//     if (center) {
//       mapboxgl.accessToken =
//         "pk.eyJ1IjoiYW1tYXJhbGFhIiwiYSI6ImNsYmRmc3ZweDAxMHkzcG8wbG42ZWdvbzcifQ.5IKGkPED3_oWw2Ll4K4yGw";

//       var map = new mapboxgl.Map({
//         container: "resturantMapModal",
//         style: "mapbox://styles/mapbox/streets-v11",
//         center: center || [44.3661, 33.3152],
//         zoom: 10,
//       });

//       let marker = new mapboxgl.Marker({
//         color: "#0000ff",
//         draggable: false,
//       });

//       map.on("load", function () {
//         marker.setLngLat(center).addTo(map);
//       });
//     }
//   }, [center]);

//   return (
//     <div id="resturantMapModal" style={{ height: 450, borderRadius: 4 }}></div>
//   );
// };
