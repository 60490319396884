import {
  Row,
  Col,
  Typography,
  Space,
  Button,
  Popover,
  message,
  Spin,
  Empty,
} from "antd";
import { CopyOutlined, DeleteOutlined } from "@ant-design/icons";
const copy = require("clipboard-copy");

const { Text, Link } = Typography;

export const PureGrid = ({ loading, data }) => {
  const copyLink = (val) => {
    let successPromise = copy(val);
    if (successPromise) {
      message.success("Copy to clipboard");
    } else {
      message.error("Faild Copy !");
    }
  };

  return (
    <Spin spinning={loading}>
      {data.length > 0 ? (
        <Row gutter={[30, 30]}>
          {data.map((el) => (
            <Col key={el.id} span={4}>
              <div>
                <img className="app-media-image" src={el.img} />
                <div className="app-space-between">
                  <Popover content={<Text type="secondary">{el.img}</Text>}>
                    <Link
                      className="app-media-link"
                      href={el.img}
                      target="_blank"
                    >
                      {el.img}
                    </Link>
                  </Popover>
                  <Space>
                    <Button
                      onClick={() => copyLink(el.img)}
                      size="small"
                      type="text"
                      icon={<CopyOutlined />}
                    />
                    <Button
                      danger
                      size="small"
                      type="text"
                      icon={<DeleteOutlined />}
                    />
                  </Space>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      ) : (
        <Empty style={{ marginTop: 100, marginBottom: 100 }} />
      )}
    </Spin>
  );
};
