import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  InputNumber,
  message,
  Checkbox,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useSubProductGroup } from "./store";
import Input from "antd/lib/input/Input";
import { editToppingGroup, createToppingGroup } from "../../api";
import { useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import { useEffect } from "react";

const { Title } = Typography;

export const PureCreate = ({ productID }) => {
  const {
    setIsCreateModal,
    setId,
    setName,
    setPriority,
    setMultiSelect,
    setStatus,
    isCreateModal,
    id,
    name,
    priority,
    multiSelect,
    status,
  } = useSubProductGroup();
  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation(createToppingGroup, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        resetState();
        setIsCreateModal(false);
        queryClient.invalidateQueries("subProductGroup");
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editToppingGroup,
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          resetState();
          setIsCreateModal(false);
          queryClient.invalidateQueries("subProductGroup");
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const onFinish = (values) => {
    // values.productID = Number(productID);
    // values.status = true;
    if (id) {
      //values.id = id;
      let formData = {
        id,
        name: values.name,
        price: values.price,
        multiSelect: values.multiSelect,
        productID: Number(productID),
        priority: values.priority,
        status: status,
      };

      editMutate(formData);
    } else {
      let formData = {
        name: values.name,
        price: values.price,
        multiSelect: values.multiSelect,
        productID: Number(productID),
        priority: values.priority,
      };
      mutate(formData);
    }
  };

  let resetState = () => {
    setId(null);
    setId(null);
    setName(null);
    setPriority(null);
    setMultiSelect(null);
    // setProductID(null);
    setStatus(false);
    form.resetFields();
  };

  useEffect(() => {
    if (id) {
      form.setFieldsValue({
        name,
        priority,
        multiSelect,
      });
    } else {
      resetState();
    }
  }, [id]);

  return (
    <Modal
      title={<Title level={5}>{id ? "Edit" : "New"} Topping Group</Title>}
      className="app-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetState();
        setIsCreateModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={400}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        form={form}
        onFinish={onFinish}
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={14}>
            <Form.Item
              label="Group Name"
              name="name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              label="Priority"
              name="priority"
              rules={[{ required: true }]}
            >
              <InputNumber />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="multiSelect" valuePropName="checked">
              <Checkbox>MultiSelect</Checkbox>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={isLoading || editLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
