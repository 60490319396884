import create from "zustand";

export const useSortingSettings = create((set) => ({
  isCreateModal: false,
  id: null,
  pinned_vendors: [],
  name: null,
  distance_setting_id: null,
  pinned_distance_limit: null,
  pinned_vendors_str: null,

  setId: (id) => set({ id }),
  setPinned_vendors: (pinned_vendors) => set({ pinned_vendors }),
  setName: (name) => set({ name }),
  setDistance_setting_id: (distance_setting_id) => set({ distance_setting_id }),
  setPinned_vendors_str: (pinned_vendors_str) => set({ pinned_vendors_str }),
  setPinned_distance_limit: (pinned_distance_limit) =>
    set({ pinned_distance_limit }),
  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
}));
