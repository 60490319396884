import React, { useState, useEffect } from "react";
import { Form, Row, Col, Select, Input } from "antd";
import { useOrder } from "../store";

import { getClients, getInRangeAddress } from "../../../api";
import { useQuery } from "react-query";

const { Option } = Select;

export const GetClient = () => {
  const [form] = Form.useForm();
  const [filter, setFilter] = useState("");
  const { client, setClient, setPlatform } = useOrder();

  const { isLoading, data, refetch } = useQuery(
    ["clients", filter],
    getClients,
    {
      retry: 2,
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );
  const {
    isLoading: addressLoading,
    data: clientAddresses,
    refetch: refetchClientAddresse,
  } = useQuery(["clients", 0, client?.id], getInRangeAddress, {
    retry: 2,
    refetchOnWindowFocus: false,
    enabled: false,
  });

  useEffect(() => {
    if (filter) {
      refetch();
    }
  }, [filter]);

  useEffect(() => {
    if (client) {
      form.setFieldsValue(client);
      refetchClientAddresse();
    }
  }, [client]);

  return (
    <div className="step-container">
      <Select
        showSearch
        style={{ width: "100%", marginTop: 10 }}
        // value={client ? client.id : null}
        // placeholder={this.props.placeholder}
        // style={this.props.style}
        placeholder="Find Client..."
        defaultActiveFirstOption={false}
        showArrow={true}
        loading={isLoading}
        filterOption={false}
        onSearch={(val) => val.length === 11 && setFilter(val)}
        onChange={(id) => {
          let _client = data.data.records.find((el) => el.id == id);

          setClient(_client);
        }}
        notFoundContent={null}
      >
        {data &&
          data.success &&
          data.data.records.map((el, index) => (
            <Option key={index} value={el.id}>
              {el.firstName + " " + el.lastName}
            </Option>
          ))}
      </Select>
      {client && (
        <Form
          requiredMark={"optional"}
          name="basic"
          form={form}
          autoComplete="off"
          layout="vertical"
          style={{ marginTop: 30 }}
        >
          <Row gutter={[20, 20]}>
            <Col span={12}>
              <Form.Item
                label="First Name"
                name="firstName"
                rules={[{ required: true }]}
              >
                <Input readOnly placeholder="First Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="LastName"
                name="lastName"
                rules={[{ required: true }]}
              >
                <Input readOnly placeholder="Last Name" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Username"
                name="username"
                rules={[{ required: true }]}
              >
                <Input readOnly placeholder={"baly001"} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Phone Number"
                name="phone"
                rules={[{ required: true }]}
              >
                <Input readOnly placeholder={"07** *** ****"} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Platform"
                name="platform"
                rules={[{ required: true }]}
              >
                <Select
                  onChange={(val) => setPlatform(val)}
                  defaultValue={null}
                  placeholder="platform"
                >
                  <Option value={"PWA"}>PWA</Option>
                  <Option value={"WEB"}>WEB</Option>
                  <Option value={"IOS_SUPERAPP"}>IOS_SUPERAPP</Option>
                  <Option value={"ANDROID_SUPERAPP"}>ANDROID_SUPERAPP</Option>
                  <Option value={"IOS_SHELL"}>IOS_SHELL</Option>
                  <Option value={"ANDROID_SHELL"}>ANDROID_SHELL</Option>
                  <Option value={"IOS"}>IOS</Option>
                  <Option value={"ANDROID"}>ANDROID</Option>
                  <Option value={"WINDOWS10"}>WINDOWS10</Option>
                  <Option value={"DESKTOP_WEB"}>DESKTOP_WEB</Option>
                  <Option value={"MOBILE_WEB"}>MOBILE_WEB</Option>
                  <Option value={"WEB_IOS"}>WEB_IOS</Option>
                  <Option value={"WEB_MACOS"}>WEB_MACOS</Option>
                  <Option value={"WEB_WINDOWS"}>WEB_WINDOWS</Option>
                  <Option value={"SUPERAPP_ANDROID"}>SUPERAPP_ANDROID</Option>
                  <Option value={"SUPERAPP_IOS"}>SUPERAPP_IOS</Option>
                  <Option value={"WEB_ANDROID"}>WEB_ANDROID</Option>
                  <Option value={"SHELL_IOS"}>SHELL_IOS</Option>
                  <Option value={"SHELL_ANDROID"}>SHELL_ANDROID</Option>
                  <Option value={"OTHER"}>OTHER</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={14}>
              <Form.Item label="Email" name="email" rules={[{ type: "email" }]}>
                <Input readOnly placeholder={"example@baly.iq"} />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label="ِAddress"
                name="address"
                rules={[{ required: true }]}
              >
                <Select
                  onChange={(val) => {
                    client.address = clientAddresses?.data.find(
                      (el) => el.id == val
                    );
                    setClient(client);
                  }}
                  loading={addressLoading}
                >
                  {clientAddresses?.data &&
                    clientAddresses?.data.map((el, index) => (
                      <Option key={index} value={el.id}>
                        {el.address}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </div>
  );
};
