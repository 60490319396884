import { Card, Button, Breadcrumb } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { PuerTable } from "./table";
import { PureCreate } from "./create";
import { useSortingSettings } from "./store";
import authFunc from "../../helper/auth";
// import authFunc from "../../helper/auth";

export const SortingSettingsPage = () => {
  const { setIsCreateModal } = useSortingSettings();

  return (
    <div className="app-page">
      <Breadcrumb style={{ marginBottom: 10 }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Vendors Performance</Breadcrumb.Item>
        <Breadcrumb.Item>Search and Nearby</Breadcrumb.Item>
        <Breadcrumb.Item>Sorting Settings</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <div style={{ display: "flex", justifyContent: "end" }}>
          {authFunc("SORTING_SETTINGS_CREATE") && (
            <Button
              onClick={() => setIsCreateModal(true)}
              type="primary"
              icon={<PlusOutlined />}
            >
              Create New
            </Button>
          )}
        </div>
        <br />
        <PuerTable />
        <PureCreate />
      </Card>
    </div>
  );
};
