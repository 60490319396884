import { useState, useEffect } from "react";
import {
  Table,
  Typography,
  Button,
  Alert,
  InputNumber,
  message,
  Switch,
  Tooltip,
} from "antd";
import { SaveOutlined, PercentageOutlined } from "@ant-design/icons";
import { useQuery, useMutation } from "react-query";
import { queryClient } from "../../queryClient";
// import authFunc from "../../helper/auth";
import {
  getvendorsWeights,
  editWeightsSettings,
  editWeightsSettingsStatus,
} from "../../api";
import authFunc from "../../helper/auth";

const { Text } = Typography;

export const PuerTable = () => {
  const [weights, setWeights] = useState([]);
  const [totalWeight, setTotalWeight] = useState(0);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      width: 100,
      render: (text, record, index) => index + 1,
    },
    {
      title: "Metric",
      dataIndex: "name",
      key: "name",
      width: 500,
      render: (text) => text.replace(/_/g, " "),
    },
    {
      title: "Weight",
      dataIndex: "weight",
      key: "weight",
      render: (text, record) => (
        <InputNumber
          disabled={!data?.data?.status}
          defaultValue={record.weight}
          min={0}
          max={100}
          addonAfter={<PercentageOutlined />}
          onChange={(value) => handleWeightChange(record.name, value)}
        />
      ),
    },
  ];

  const handleWeightChange = (name, value) => {
    const newWeights = weights.map((item) =>
      item.name === name ? { ...item, weight: value } : item
    );
    setWeights(newWeights);
  };

  const calculateTotalWeight = (weights) => {
    return weights.reduce((acc, curr) => acc + curr.weight, 0);
  };

  const { mutate, isLoading } = useMutation(editWeightsSettings, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        queryClient.invalidateQueries("vendorWeights");
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(["vendorWeights"], getvendorsWeights, {
    refetchOnWindowFocus: false,
    retry: 2,
    onSuccess: (data) => {
      if (data.data && data.data.weights) {
        const weightsArray = Object.keys(data.data.weights).map((key) => ({
          name: key,
          weight: data.data.weights[key],
        }));
        setWeights(weightsArray);
      }
    },
  });

  const onEdit = () => {
    const weightsObject = weights.reduce((acc, curr) => {
      acc[curr.name] = curr.weight;
      return acc;
    }, {});
    mutate({ weights: weightsObject });
  };

  useEffect(() => {
    const total = calculateTotalWeight(weights);
    setTotalWeight(total);
    setIsSaveDisabled(total !== 100);
  }, [weights]);

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editWeightsSettingsStatus,
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          queryClient.invalidateQueries("vendorWeights");
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const handleChangeStatus = (status) => {
    editMutate({
      status: status,
    });
  };

  if (error || (!data?.success && data?.data?.err)) {
    return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />
    );
  }

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={weights}
        size="small"
        rowKey={(record) => record.name}
        pagination={false}
        scroll={{ y: 450 }}
        title={() => (
          <div style={{ marginBottom: "10px" }}>
            <Switch
              checkedChildren="Active"
              unCheckedChildren="Disabled"
              checked={data?.data?.status}
              onChange={(val) => handleChangeStatus(val)}
              loading={editLoading}
            />
          </div>
        )}
        footer={() => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px 30px",
            }}
          >
            <Text
              strong
              style={{ color: totalWeight !== 100 ? "red" : "green" }}
            >
              Total Weight {totalWeight}%
            </Text>
            <Tooltip
              title={
                !authFunc("WEIGHTS_SETTINGS_CREATE")
                  ? "You don't have permission to update the weights settings"
                  : null
              }
            >
              <Button
                onClick={onEdit}
                disabled={
                  isSaveDisabled ||
                  !data?.data?.status ||
                  !authFunc("WEIGHTS_SETTINGS_CREATE")
                }
                type="primary"
                icon={<SaveOutlined />}
                loading={isLoading}
              >
                Save
              </Button>
            </Tooltip>
          </div>
        )}
      />
    </>
  );
};
