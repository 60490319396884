import { useEffect, useState } from "react";
import {
  Table,
  Typography,
  Button,
  Alert,
  InputNumber,
  message,
  Tooltip,
} from "antd";
import { SaveOutlined, PercentageOutlined } from "@ant-design/icons";
import { useQuery, useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import { getProductsWeights, editProductsSettings } from "../../api";
import authFunc from "../../helper/auth";

const { Text } = Typography;

export const PuerTable = () => {
  const [frequencyWeight, setFrequencyWeight] = useState(0);
  const [ratingWeight, setRatingWeight] = useState(0);

  const columns = [
    // {
    //   title: "#",
    //   dataIndex: "id",
    //   key: "id",
    //   width: 100,
    //   render: (text, record, index) => index + 1,
    // },
    {
      title: "Frequency Weight",
      dataIndex: "product_frequecy",
      key: "product_frequecy",
      width: 500,
      render: (product_frequecy) => (
        <InputNumber
          defaultValue={product_frequecy}
          min={0}
          max={100}
          addonAfter={<PercentageOutlined />}
          onChange={(value) => setFrequencyWeight(value)}
        />
      ),
    },
    {
      title: "Rating Weight",
      dataIndex: "product_rating",
      key: "product_rating",
      render: (product_rating) => (
        <InputNumber
          defaultValue={product_rating}
          min={0}
          max={100}
          addonAfter={<PercentageOutlined />}
          onChange={(value) => setRatingWeight(value)}
        />
      ),
    },
  ];

  const { mutate, isLoading } = useMutation(editProductsSettings, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        queryClient.invalidateQueries("productWeights");
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(["productWeights"], getProductsWeights, {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  useEffect(() => {
    if (!loading && data?.data) {
      setRatingWeight(data?.data?.product_rating);
      setFrequencyWeight(data?.data?.product_frequecy);
    }
  }, [data]);

  const onEdit = () => {
    mutate({
      id: data?.data?.id,
      product_frequecy: frequencyWeight,
      product_rating: ratingWeight,
    });
  };

  if (error || (!data?.success && data?.data?.err)) {
    return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />
    );
  }

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data && data.data ? [data.data] : []}
        size="small"
        rowKey={(record) => record.name}
        pagination={false}
        scroll={{ y: 450 }}
        footer={() => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px 30px",
            }}
          >
            <Text
              strong
              style={{
                color: frequencyWeight + ratingWeight !== 100 ? "red" : "green",
              }}
            >
              Total Weight {frequencyWeight + ratingWeight}%
            </Text>
            <Tooltip
              title={
                !authFunc("PRODUCT_WEIGHTS_CREATE")
                  ? "You don't have permission to update the products weights"
                  : null
              }
            >
              <Button
                onClick={onEdit}
                disabled={
                  frequencyWeight + ratingWeight !== 100
                  || !authFunc("PRODUCT_WEIGHTS_CREATE")
                }
                type="primary"
                icon={<SaveOutlined />}
                loading={isLoading}
              >
                Save
              </Button>
            </Tooltip>
          </div>
        )}
      />
    </>
  );
};
