import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";

import { useEffect } from "react";

export const PureMap = ({ position }) => {
  useEffect(() => {
    if (mapboxgl.getRTLTextPluginStatus() === "unavailable") {
      mapboxgl.setRTLTextPlugin(
        "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js",
        null,
        true // Lazy load the plugin
      );
    }
    mapboxgl.accessToken =
      "pk.eyJ1IjoiYW1tYXJhbGFhIiwiYSI6ImNsYmRmc3ZweDAxMHkzcG8wbG42ZWdvbzcifQ.5IKGkPED3_oWw2Ll4K4yGw";

    var map = new mapboxgl.Map({
      container: "mapOrderView",
      style: "mapbox://styles/mapbox/streets-v11",
      center: position || [44.3661, 33.3152],
      zoom: 13,
    });

    var Draw = new MapboxDraw({
      displayControlsDefault: true,
      controls: {
        polygon: false,
        trash: false,
        point: false,
        line_string: false,
        combine_features: false,
        uncombine_features: false,
      },
    });

    map.addControl(Draw);
    if (position) {
      new mapboxgl.Marker({
        color: "#0000ff",
        draggable: false,
      })
        .setLngLat(position)
        .addTo(map);
    }

    map.on("load", function () {
      if (position) {
        Draw.add(position);
      }
    });
  }, [position]);

  return (
    <div id="mapOrderView" style={{ height: "80vh", width: "100%" }}></div>
  );
};
