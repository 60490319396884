import { Card, Breadcrumb } from "antd";
import { PuerTable } from "./table";
import { WeightsModal } from "./weightsModal";

export const WeightsLogPage = () => {
  return (
    <div className="app-page">
      <Breadcrumb style={{ marginBottom: 10 }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Logs</Breadcrumb.Item>
        <Breadcrumb.Item>Weights Logs</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <PuerTable />
        <WeightsModal />
      </Card>
    </div>
  );
};
