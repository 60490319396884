import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  message,
  Select,
  InputNumber,
  Spin,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import Input from "antd/lib/input/Input";
import { useCompensationSettings } from "./store";
import { useMutation, useQuery } from "react-query";
import { queryClient } from "../../queryClient";
import {
  createCompensationSetting,
  editCompensationSetting,
  getDeclineReason,
  getReviewReasons,
  getVoucherSettings,
} from "../../api";
import { ColorPicker } from "../../comonents/global";
import { Option } from "antd/lib/mentions";
import { useState } from "react";
const { Title } = Typography;

export const PureCreate = () => {
  const {
    isCreateModal,
    setIsCreateModal,
    id,
    setId,
    type,
    voucherSettingsID,
    paddingTime,
    filterIDs,
    setPaddingTime,
    setVoucherSettingsID,
    setFilterIDs,
    setType,
  } = useCompensationSettings();

  const [voucherSettingsSearch, setVoucherSettingsSearch] = useState("");
  const [declineReasonsSearch, setDeclineReasonsSearch] = useState("");
  const [reviewReasonsSearch, setReviewReasonsSearch] = useState("");

  const resetState = () => {
    setId(null);
    setType(null);
    setPaddingTime(null);
    setVoucherSettingsID(null);
    setFilterIDs([]);
  };

  const { isLoading: loading, data: voucherSettings } = useQuery(
    ["voucherSettings", voucherSettingsSearch, "APOLOGY", 1000, 1],
    getVoucherSettings,
    {
      refetchOnWindowFocus: false,
      retry: 2,
      enabled: !!isCreateModal,
    }
  );

  const { isLoading: declineReasonsLoading, data: declineReasons } = useQuery(
    ["declineReasons", declineReasonsSearch, 1, 1000],
    getDeclineReason,
    {
      refetchOnWindowFocus: false,
      retry: 2,
      enabled: !!isCreateModal,
    }
  );

  const { isLoading: reviewReasonsLoading, data: reviewReasons } = useQuery(
    ["reviewReasons", reviewReasonsSearch, 1, "", 1000],
    getReviewReasons,
    {
      refetchOnWindowFocus: false,
      retry: 2,
      enabled: !!isCreateModal,
    }
  );

  const { mutate, isLoading } = useMutation(createCompensationSetting, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        setIsCreateModal(false);
        queryClient.invalidateQueries("compensation");
        resetState();
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });
  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editCompensationSetting,
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          setIsCreateModal(false);
          queryClient.invalidateQueries("compensation");
          resetState();
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const onFinish = (values) => {
    if (id) {
      values.id = id;
      editMutate(values);
    } else {
      mutate(values);
    }
  };

  return (
    <Modal
      title={
        <Title level={5}>{id ? "Edit" : "New"} Compensation Settings</Title>
      }
      className="app-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetState();
        setIsCreateModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={600}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        initialValues={{
          remember: true,
          type,
          voucherSettingsID,
          paddingTime,
          filterIDs,
        }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={12}>
            <Form.Item label="Type" name="type" rules={[{ required: true }]}>
              <Select
                placeholder="Select Type"
                showSearch
                filterOption={false}
                onSelect={(val) => setType(val)}
              >
                <Option value={"hyper_delays"}>Hyper Delays</Option>
                <Option value={"canceled_orders"}>Canceled Orders</Option>
                <Option value={"bad_biker_ratings"}>Bad Biker Ratings</Option>
                <Option value={"bad_vendor_ratings"}>Bad Vendor Ratings</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Voucher Settings"
              name="voucherSettingsID"
              rules={[{ required: true }]}
            >
              <Select
                loading={loading}
                showSearch
                filterOption={true}
                optionFilterProp="children"
                onSearch={(val) => setVoucherSettingsSearch(val)}
                value={voucherSettingsID}
                style={{ width: "100%" }}
                placeholder="Select Voucher Settings"
                allowClear
              >
                {voucherSettings?.success
                  ? voucherSettings?.data?.records?.map((el) => (
                      <Option key={el.id} id={el.id} value={el.id}>
                        {el.voucherTitle}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          </Col>
          {type === "hyper_delays" && (
            <Col span={12}>
              <Form.Item
                label="Padding Time (minutes)"
                name="paddingTime"
                rules={[{ required: true }]}
              >
                <InputNumber
                  step={1}
                  precision={0}
                  min={0}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          )}
          {type === "canceled_orders" && (
            <Col span={24}>
              <Form.Item
                label="Decline Reasons"
                name="filterIDs"
                rules={[{ required: true }]}
              >
                <Select
                  mode="multiple"
                  loading={declineReasonsLoading}
                  showSearch
                  filterOption={true}
                  optionFilterProp="children"
                  onSearch={(val) => setDeclineReasonsSearch(val)}
                  value={filterIDs}
                  style={{ width: "100%" }}
                  placeholder="Select Decline Reasons"
                  allowClear
                >
                  {declineReasons?.success
                    ? declineReasons?.data?.records?.map((el) => (
                        <Option key={el.id} id={el.id} value={el.id}>
                          {el.arTitle}
                        </Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
          )}

          {(type === "bad_biker_ratings" || type === "bad_vendor_ratings") && (
            <Col span={24}>
              <Form.Item
                label="Review Reasons"
                name="filterIDs"
                rules={[{ required: true }]}
              >
                <Select
                  mode="multiple"
                  loading={reviewReasonsLoading}
                  showSearch
                  filterOption={true}
                  optionFilterProp="children"
                  onSearch={(val) => setReviewReasonsSearch(val)}
                  value={filterIDs}
                  style={{ width: "100%" }}
                  placeholder="Select Review Reasons"
                  allowClear
                >
                  <Option value={-1}>بدون سبب</Option>
                  {reviewReasons?.success
                    ? reviewReasons?.data?.records?.map((el) =>
                        type === "bad_vendor_ratings"
                          ? el.category === "VENDOR" && (
                              <Option key={el.id} id={el.id} value={el.id}>
                                {el.title}
                              </Option>
                            )
                          : el.category === "BIKER" && (
                              <Option key={el.id} id={el.id} value={el.id}>
                                {el.title}
                              </Option>
                            )
                      )
                    : null}
                </Select>
              </Form.Item>
            </Col>
          )}

          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={isLoading || editLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
