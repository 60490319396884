import { useEffect } from "react";
import {
  Table,
  Tag,
  Space,
  Button,
  Typography,
  Alert,
  message,
  Pagination,
} from "antd";
import { DislikeFilled, LikeFilled } from "@ant-design/icons";
import { useQuery, useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import { getReviews, editReviews, pageSize } from "../../api";
import { useBikerReview } from "./store";
import { useApp } from "../../store";
import authFunc from "../../helper/auth";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

const { Text } = Typography;
const statusColor = {
  ACCEPTED: "success",
  REJECTED: "error",
  PENDING: "orange",
};

export const PureTable = () => {
  const { setIsLogin } = useApp();
  const { queryKey, bikerPage, setBikerPage, bikerRate } = useBikerReview();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setBikerPage(searchParams.get("page_num"));
    } else {
      setBikerPage(1);
    }
  }, [searchParams.get("page_num")]);

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(
    ["bikerReveiw", "bikerreview", queryKey, bikerPage, bikerRate],
    getReviews,
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );

  const { mutate, isLoading: editLoading } = useMutation(
    (val) => editReviews("bikerreview", val),
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          queryClient.invalidateQueries("bikerReveiw");
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const handleEdit = (row, status) => {
    row.status = status;
    mutate(row);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Biker",
      dataIndex: "bikerID",
      key: "bikerID",
      render: (text, row) =>
        text ? (
          <Space direction="vertical">
            <Text>
              {" "}
              <i>ID:</i> <b>{text}</b>
            </Text>
            <Text>
              <i>Phone:</i> <b>{row.phoneNo}</b>
            </Text>
          </Space>
        ) : (
          <small>...</small>
        ),
    },
    {
      title: "Order",
      dataIndex: "orderID",
      key: "orderID",
      render: (text, row) =>
        text ? (
          <Space direction="vertical">
            <Text>
              {" "}
              <i>Order ID:</i> <b>{text}</b>
            </Text>
            <Text>
              <i>Biker Order ID:</i> <b>{row.bikerOrderID}</b>
            </Text>
          </Space>
        ) : (
          <small>...</small>
        ),
    },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
      render: (rate) => (
        <Tag color={rate < 2 ? "red" : "blue"}>
          {rate < 2 ? <DislikeFilled /> : <LikeFilled />}
        </Tag>
      ),
    },
    {
      title: "Customer",
      dataIndex: "client",
      key: "client",
      render: (text, row) =>
        text ? (
          <Space direction="vertical">
            <Text>
              <i>ID:</i> <b>{row.clientID}</b>
            </Text>
            <Text>
              {" "}
              <i>Phone: </i>
              <b>{text}</b>
            </Text>
          </Space>
        ) : (
          <small>No Client yet..</small>
        ),
    },
    {
      title: "Customer reviews",
      dataIndex: "comment",
      key: "comment",
      width: 10,
      render: (text) => <Text style={{ fontSize: 12 }}>{text}</Text>,
    },
    {
      title: "Creation Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => (
        <Text type="secondary">{moment(date).format(" DD/ MM/ YYYY")}</Text>
      ),
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      render: (reason) => {
        let reasons = JSON.parse(reason);
        return (
          <Text style={{ fontSize: 12 }}>
            {reasons?.length > 0
              ? reasons?.map((el, index) => (
                  <Tag color="geekblue" key={index}>
                    {el?.title}
                  </Tag>
                ))
              : "..."}
          </Text>
        );
      },
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (val) => <Tag color={statusColor[val]}>{val}</Tag>,
    },

    {
      title: "Action",
      key: "id",
      render: (row) =>
        row.status == "PENDING"
          ? authFunc("BIKER_REVIEW_UPDATE") && (
              <Space>
                <Button
                  loading={editLoading}
                  onClick={() => handleEdit(row, "ACCEPTED")}
                  type="primary"
                  size="small"
                  color="red"
                >
                  Accept
                </Button>
                <Button
                  loading={editLoading}
                  onClick={() => handleEdit(row, "REJECTED")}
                  danger
                  type="primary"
                  size="small"
                >
                  Reject
                </Button>
              </Space>
            )
          : row.status == "REJECTED"
          ? authFunc("BIKER_REVIEW_UPDATE") && (
              <Button
                loading={editLoading}
                onClick={() => handleEdit(row, "ACCEPTED")}
                type="primary"
                size="small"
              >
                Accept
              </Button>
            )
          : authFunc("BIKER_REVIEW_UPDATE") && (
              <Button
                loading={editLoading}
                onClick={() => handleEdit(row, "REJECTED")}
                danger
                type="primary"
                size="small"
              >
                Reject
              </Button>
            ),
    },
  ];

  if (error || (!data?.success && data?.data?.err))
  return (
    <Alert
      message={data?.data?.err || error?.message}
      type="error"
      showIcon
    />
  );

  return  (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data ? data?.data?.records : []}
        size="small"
        rowKey={(record) => record?.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setBikerPage(val);
        }}
        current={Number(searchParams.get("page_num")) || bikerPage}
        defaultPageSize={pageSize}
        total={data && data?.data?.total}
        showSizeChanger={false}
      />
    </>
  );
};
