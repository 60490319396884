import { Typography, Alert } from "antd";
import {
  LoadingOutlined,
  SearchOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useState, useEffect } from "react";
import { useResturant } from "../store";
import { useQuery } from "react-query";
import { getVendors } from "../../../api";

const { Text } = Typography;

export const GetClient = () => {
  const [clients, setClients] = useState([]);
  const {
    isNewClient,
    setIsNewClient,
    setName,
    setEmail,
    setPhone,
    setUsername,
    setPassword,
    setIsClientExest,
    setIsModalFilter,
    isModalFilter,
    setModalSearch,
    modalSearch,
    setRoleID,
  } = useResturant();

  const { isLoading, error, data, refetch } = useQuery(
    ["employees", modalSearch],
    getVendors,
    {
      retry: 2,
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  useEffect(() => {
    if (data) {
      setClients(data.data.records);
    }
  }, [data]);

  useEffect(() => {
    if (modalSearch) {
      setIsNewClient(false);
      setIsModalFilter(true);

      refetch();
    } else {
      setClients([]);
    }
  }, [modalSearch]);

  useEffect(() => {
    if (isModalFilter) {
      setIsClientExest(false);
      setIsNewClient(false);
    }
  }, [isModalFilter]);

  const resetState = () => {
    setName("");
    setPhone("");
    setEmail("");
    setUsername("");
    setPassword("");
    setRoleID(null);
  };

  return (
    <div>
      <Text>Search by full name, email and phone number:</Text>
      {error ? (
        <Alert
          message={"An error has occurred: " + error.message}
          type="error"
          showIcon
        />
      ) : null}
      <div className="search-box">
        <section className="search-input">
          <input
            value={modalSearch}
            placeholder="Find vendor user"
            onChange={(e) => {
              setModalSearch(e.target.value);
            }}
          />
          <div>{isLoading ? <LoadingOutlined /> : <SearchOutlined />} </div>
        </section>

        {isModalFilter && !isNewClient && (
          <>
            <ul>
              {clients.map((item, index) => (
                <li
                  key={index}
                  onClick={() => {
                    setModalSearch("");
                    setIsClientExest(true);
                    setIsModalFilter(false);
                    setIsNewClient(false);
                    setName(item.name);
                    setPhone(item.phone);
                    setEmail(item.email);
                    setUsername(item.username);
                  }}
                >
                  {item.name}
                </li>
              ))}
            </ul>
            <section
              className="add-btn"
              onClick={() => {
                setIsNewClient(true);
                setIsClientExest(false);
                resetState();
              }}
            >
              <PlusOutlined /> <span>Create new client</span>
            </section>
          </>
        )}
      </div>
    </div>
  );
};
