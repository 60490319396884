import { useEffect } from "react";
import { DatePicker, Space } from "antd";
import { useVendorLog } from "./store";
import "../../App.css";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

const { RangePicker } = DatePicker;
const date = new Date();

export default function OrderFilters() {
  const { setPage, setFrom, setTo, from, to } = useVendorLog();

  const fromDateFormated = moment(date.setHours(8, 0, 0, 0)).format(
    "YYYY-MM-DD HH:mm"
  );
  const toDateFormated = moment(date.setHours(23, 59, 59, 0)).format(
    "YYYY-MM-DD HH:mm"
  );

  // GENERAL STATES
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    let obj = {
      from: searchParams.get("from") || null,
      to: searchParams.get("to") || null,
      page_num: searchParams.get("page_num") || null,
    };

    setFrom(obj.from);
    setTo(obj.to);
    setPage(obj.page_num);
  }, [
    searchParams.get("from"),
    searchParams.get("to"),
    searchParams.get("page_num"),
  ]);

  useEffect(() => {
    if (!searchParams.get("from")) {
      searchParams.set("from", JSON.stringify(fromDateFormated));
      searchParams.set("to", JSON.stringify(toDateFormated));
      setSearchParams(searchParams);
    }
  }, [searchParams.get("from")]);

  const SelectTimeDate = (value, dateString) => {
    let from = searchParams.get("from")
      ? JSON.stringify(dateString[0])
      : JSON.stringify(fromDateFormated);
    let to = searchParams.get("from")
      ? JSON.stringify(dateString[1])
      : JSON.stringify(fromDateFormated);

    if (value) {
      setFrom(from);
      setTo(to);
      searchParams.set("from", from);
      searchParams.set("to", to);
      setSearchParams(searchParams);
    }
    // else {
    //     setFrom(null);
    //     setTo(null);
    //     makeFilter({ from: JSON.stringify(fromDateFormated), to: JSON.stringify(toDateFormated),
    //         vendorID, voucherID, cityID, clientID, status, page_num: 1 });
    // }
  };

  return (
    <Space wrap>
      <Space direction="vertical" size={5}>
        <RangePicker
          showTime={{
            format: "HH:mm",
          }}
          value={
            from
              ? [
                  moment(from, "YYYY-MM-DD HH:mm"),
                  moment(to, "YYYY-MM-DD HH:mm"),
                ]
              : [moment(fromDateFormated), moment(toDateFormated)]
          }
          defaultValue={[moment(fromDateFormated), moment(toDateFormated)]}
          format="YYYY-MM-DD HH:mm"
          onChange={SelectTimeDate}
        />
      </Space>
    </Space>
  );
}
