import {  useState } from "react";
import {
  Table,
  Space,
  Typography,
  Alert,
  Pagination,
  message,
  Button,
  Popconfirm,
} from "antd";
import {
  WomanOutlined,
  ManOutlined,
} from "@ant-design/icons";
import {  useQuery, useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import {  pageSize, getClientsAppbanner, DeleteFromAppBanner } from "../../api";
import { DeleteOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

const { Text } = Typography;

export const ClientTable = ({ id, filter }) => {
  const [page, setPage] = useState(1);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      key: "id",
      render: (row) => (
        <Space>
          {row.gender == "female" ? (
            <WomanOutlined style={{ color: "#eb2f96" }} />
          ) : (
            <ManOutlined style={{ color: "#2196f3" }} />
          )}
          <Text style={{ fontWeight: "bold" }}>
            {row.firstName} {row.lastName}
          </Text>
        </Space>
      ),
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },

    {
      title: "Creation Date",
      dataIndex: "date",
      key: "date",
      render: (date) => (
        <Text type="secondary">{dayjs(date).format("MMM D, YYYY")}</Text>
      ),
    },

    {
      title: "",
      key: "id",
      render: (row) => (
        <Popconfirm
          title="Are you sure to delete this client?"
          onConfirm={() =>
            mutate({
              id,
              userID: row.id,
            })
          }
          //onCancel={cancel}
          okText="Yes"
          cancelText="No"
          placement="rightBottom"
        >
          <Button size="small" danger icon={<DeleteOutlined />} />
        </Popconfirm>
      ),
    },
  ];

  const { isLoading: loading, error, data } = useQuery(
    ["clientsAppbanner", filter, page, id],
    getClientsAppbanner,
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );
  const { mutate, isLoading: deleteLoading } = useMutation(
    (values) => DeleteFromAppBanner("user", values),
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          queryClient.invalidateQueries("appBanner");
          queryClient.invalidateQueries("clientsAppbanner");
        } else {
          message.error(data.err);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );


  if (error) return <Alert message={error.message} type="error" showIcon />;

  if (!loading && !data.success)
    return <Alert message={data.msg} type="error" showIcon />;

  return (
    <>
      <Table
        loading={loading || deleteLoading}
        columns={columns}
        dataSource={data ? data.data.records : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => setPage(val)}
        current={page}
        defaultPageSize={pageSize}
        total={data && data.data.total}
        showSizeChanger={false}
      />
    </>
  );
};
