import React from "react";
import { Select, Spin, Space, Checkbox, Tooltip } from "antd";
import { useResturant } from "./store";
import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { getGovs, getRegions, getStatusCode, getCategories } from "../../api";
import "../../App.css";
import { useSearchParams } from "react-router-dom";

const { Option } = Select;

export default function ResturantFilters() {
  const {
    setPage,
    provinceIdFilter,
    cityIdFilter,
    active,
    statusCodeIdFilter,
    businessCategoryIDsFilter,
    rating,
    asc,

    setProvinceIdFilter,
    setCityIdFilter,
    setActive,
    setStatusCodeIdFilter,
    setBusinessCategoryIDsFilter,
    setRating,
    setAsc,
  } = useResturant();

  const [govsSearch, setGovsSearch] = useState("");
  const [citySearch, setCitySearch] = useState("");
  const [statusSearch, setStatusSearch] = useState("");
  const [categorySearch, setCategorySearch] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();

  const {
    isLoading: catLoading,
    data: catList,
    refetch: catRefetch,
  } = useQuery(["categories", categorySearch, 1, 100], getCategories, {
    enabled: !!categorySearch,
    refetchOnWindowFocus: false,
    retry: 2,
  });

  const {
    isLoading: isGovsLoading,
    data: govs,
    refetch: govsRefetch,
  } = useQuery(["govs", govsSearch, 1], getGovs, {
    enabled: !!govsSearch,
    retry: 2,
    refetchOnWindowFocus: false,
  });

  const {
    isLoading: isCityLoading,
    data: cities,
    refetch: citiesRefetch,
  } = useQuery(["regions", "", citySearch, 1], getRegions, {
    enabled: !!citySearch,
    retry: 2,
    refetchOnWindowFocus: false,
  });

  const {
    isLoading: isStatusLoading,
    data: status,
    refetch: statusRefetch,
  } = useQuery(["status", statusSearch], getStatusCode, {
    enabled: !!statusSearch,
    retry: 2,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    let obj = {
      cityIdFilter: Number(searchParams.get("cityID")) || null,
      statusCodeIdFilter: Number(searchParams.get("statusCodeID")) || null,
      businessCategoryIDsFilter:
        Number(searchParams.get("businessCategoryIDS")) || null,
      rating: searchParams.get("searchRating") || null,
      asc: searchParams.get("asc") || null,
      provinceIdFilter: Number(searchParams.get("provinceID")) || null,
      active: searchParams.get("active") || null,
      page_num: searchParams.get("page_num") || null,
    };

    setCityIdFilter(obj.cityIdFilter);
    setProvinceIdFilter(obj.provinceIdFilter);
    setStatusCodeIdFilter(obj.statusCodeIdFilter);
    setBusinessCategoryIDsFilter(obj.businessCategoryIDsFilter);
    setRating(obj.rating);
    setAsc(obj.asc);
    setActive(obj.active);
    setPage(obj.page_num);
  }, [
    searchParams.get("cityID"),
    searchParams.get("statusCodeID"),
    searchParams.get("businessCategoryIDS"),
    searchParams.get("searchRating"),
    searchParams.get("asc"),
    searchParams.get("provinceID"),
    searchParams.get("active"),
    searchParams.get("page_num"),
  ]);

  return (
    <Space>
      <Select
        filterOption={false}
        value={provinceIdFilter}
        dropdownMatchSelectWidth={false}
        notFoundContent={
          isGovsLoading ? (
            <div className="spin-wrapper">
              <Spin size="small" />
            </div>
          ) : null
        }
        allowClear
        showSearch
        onChange={(val) => {
          if (val !== undefined) {
            setProvinceIdFilter(val);
            searchParams.set("provinceID", val);
            searchParams.set("page_num", 1);
            setSearchParams(searchParams);
          }
        }}
        onClear={() => {
          setProvinceIdFilter(null);
          setGovsSearch(null);
          searchParams.delete("provinceID");
          searchParams.set("page_num", 1);
          setSearchParams(searchParams);
        }}
        onSearch={(val) => setGovsSearch(val)}
        onClick={() => govsRefetch()}
        placeholder="Select Province"
        style={{ width: "100%" }}
      >
        {govs &&
          govs.success &&
          govs.data.records.map((el) => (
            <Option key={el.id} value={el.id}>
              {el.name}
            </Option>
          ))}
      </Select>

      <Select
        filterOption={false}
        dropdownMatchSelectWidth={false}
        notFoundContent={
          isCityLoading ? (
            <div className="spin-wrapper">
              <Spin size="small" />
            </div>
          ) : null
        }
        allowClear
        showSearch
        value={cityIdFilter}
        onChange={(val) => {
          if (val !== undefined) {
            setCityIdFilter(val);
            searchParams.set("cityID", val);
            searchParams.set("page_num", 1);
            setSearchParams(searchParams);
          }
        }}
        onClear={() => {
          setCityIdFilter(null);
          searchParams.delete("cityID");
          searchParams.set("page_num", 1);
          setSearchParams(searchParams);
        }}
        onSearch={(val) => setCitySearch(val)}
        onClick={() => citiesRefetch()}
        placeholder="Select City"
        style={{ width: "100%" }}
      >
        {cities &&
          cities.success &&
          cities.data.records.map((el) => (
            <Option key={el.id} value={el.id}>
              {el.name}
            </Option>
          ))}
      </Select>

      <Select
        filterOption={false}
        dropdownMatchSelectWidth={false}
        notFoundContent={
          isStatusLoading ? (
            <div className="spin-wrapper">
              <Spin size="small" />
            </div>
          ) : null
        }
        allowClear
        showSearch
        value={statusCodeIdFilter}
        onChange={(val) => {
          if (val !== undefined) {
            setStatusCodeIdFilter(val);
            searchParams.set("statusCodeID", val);
            searchParams.set("page_num", 1);
            setSearchParams(searchParams);
          }
        }}
        onClear={() => {
          setStatusCodeIdFilter(null);
          searchParams.delete("statusCodeID");
          searchParams.set("page_num", 1);
          setSearchParams(searchParams);
        }}
        onSearch={(val) => setStatusSearch(val)}
        onClick={() => statusRefetch()}
        placeholder="Select Status"
        style={{ width: "100%" }}
      >
        {status &&
          status.success &&
          status.data.records.map((el) => (
            <Option key={el.id} value={el.id}>
              {el.name}
            </Option>
          ))}
      </Select>

      <Select
        filterOption={false}
        dropdownMatchSelectWidth={false}
        notFoundContent={
          catLoading ? (
            <div className="spin-wrapper">
              <Spin size="small" />
            </div>
          ) : null
        }
        allowClear
        showSearch
        value={businessCategoryIDsFilter}
        onChange={(val) => {
          if (val !== undefined) {
            setBusinessCategoryIDsFilter(val);
            searchParams.set("businessCategoryIDS", val);
            searchParams.set("page_num", 1);
            setSearchParams(searchParams);
          }
        }}
        onClear={() => {
          setBusinessCategoryIDsFilter(null);
          setCategorySearch(null);
          searchParams.delete("businessCategoryIDS");
          searchParams.set("page_num", 1);
          setSearchParams(searchParams);
        }}
        onSearch={(val) => setCategorySearch(val)}
        onClick={() => catRefetch()}
        placeholder="Select Category"
        style={{ width: "100%" }}
      >
        {catList &&
          catList.success &&
          catList.data.records.map((el) => (
            <Option key={el.id} value={el.id}>
              {el.name}
            </Option>
          ))}
      </Select>

      <Select
        filterOption={false}
        dropdownMatchSelectWidth={false}
        allowClear
        onChange={(val) => {
          if (val !== undefined) {
            setActive(val);
            searchParams.set("active", val);
            searchParams.set("page_num", 1);
            setSearchParams(searchParams);
          }
        }}
        value={active}
        onClear={() => {
          setActive(null);
          searchParams.delete("active");
          searchParams.set("page_num", 1);
          setSearchParams(searchParams);
        }}
        placeholder="Select State"
        style={{ width: "100%" }}
      >
        <Option value={"true"}>Active</Option>
        <Option value={"false"}>InActive</Option>
      </Select>

      <Checkbox
        checked={rating}
        onChange={(e) => {
          if (e.target.checked === true) {
            setRating(e.target.checked);
            searchParams.set("searchRating", e.target.checked);
            searchParams.set("page_num", 1);
            setSearchParams(searchParams);
          } else {
            searchParams.delete("searchRating");
            searchParams.set("page_num", 1);
            setSearchParams(searchParams);
          }
        }}
      >
        <Tooltip placement="topLeft" title={"From highest to lowest"}>
          Order By Rating
        </Tooltip>
      </Checkbox>

      <Checkbox
        checked={asc}
        onChange={(e) => {
          if (e.target.checked === true) {
            setAsc(e.target.checked);
            searchParams.set("asc", e.target.checked);
            searchParams.set("page_num", 1);
            setSearchParams(searchParams);
          } else {
            searchParams.delete("asc");
            searchParams.set("page_num", 1);
            setSearchParams(searchParams);
          }
        }}
      >
        <Tooltip placement="topLeft" title={"Asynchronous ordering"}>
          Order By asynchronous
        </Tooltip>
      </Checkbox>
    </Space>
  );
}
