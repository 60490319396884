import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  InputNumber,
} from "antd";
import { message as ANTmessage } from "antd";
import { SaveOutlined, DeleteOutlined } from "@ant-design/icons";
import Input from "antd/lib/input/Input";
import { useDistanceSettings } from "./store";
import { useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import { createDistanceSetting, editDistanceSetting } from "../../api";

const { Title, Link } = Typography;

export const PureCreate = () => {
  const {
    isCreateModal,
    setIsCreateModal,
    id,
    setId,
    setClasses,
    setName,
    classes,
    name,
  } = useDistanceSettings();

  const resetState = () => {
    setId(null);
    setName(null);
    setClasses([]);
  };

  const { mutate, isLoading } = useMutation(createDistanceSetting, {
    onSuccess: (data) => {
      if (data.success) {
        ANTmessage.success(data.msg);
        setIsCreateModal(false);
        queryClient.invalidateQueries("distanceSettings");
        resetState();
      } else {
        ANTmessage.error(data.msg);
      }
    },
    onError: (e) => {
      ANTmessage.error(e.message);
    },
  });
  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editDistanceSetting,
    {
      onSuccess: (data) => {
        if (data.success) {
          ANTmessage.success(data.msg);
          setIsCreateModal(false);
          queryClient.invalidateQueries("distanceSettings");
          resetState();
        } else {
          ANTmessage.error(data.msg);
        }
      },
      onError: (e) => {
        ANTmessage.error(e.message);
      },
    }
  );

  const onFinish = (values) => {
    if (id) {
      editMutate({
        id: id,
        name: values.name,
        classes: values.classes,
      });
    } else {
      mutate({
        name: values.name,
        classes: values.classes,
      });
    }
  };

  return (
    <Modal
      title={<Title level={5}>{id ? "Edit" : "New"} Distance Settings</Title>}
      className="app-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetState();
        setIsCreateModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={400}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        initialValues={{
          remember: true,
          name,
          classes: id ? classes : [{}],
        }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
              <Input rows={4} placeholder={"Setting Name"} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Divider />
            <Form.List name="classes">
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <Form.Item
                      className="distanc-list"
                      label={index === 0 ? "Distance (KM)" : ""}
                      required={true}
                      key={field.key}
                    >
                      <Row gutter={[10, 20]} style={{ marginTop: 10 }}>
                        <Col span={6}>
                          <Form.Item
                            name={[field.name, "from"]}
                            rules={[
                              {
                                required: true,
                                message: "Please input the 'From' value",
                              },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  const toValue = getFieldValue([
                                    "classes",
                                    field.name,
                                    "to",
                                  ]);
                                  if (value > toValue) {
                                    return Promise.reject(
                                      new Error(
                                        "'From' value should not be greater than 'To' value."
                                      )
                                    );
                                  }
                                  if (index > 0) {
                                    const prevToValue = getFieldValue([
                                      "classes",
                                      fields[index - 1].name,
                                      "to",
                                    ]);
                                    if (value < prevToValue) {
                                      return Promise.reject(
                                        new Error(
                                          "'From' value should not be less than the 'To' value of the previous distance."
                                        )
                                      );
                                    }
                                  }
                                  return Promise.resolve();
                                },
                              }),
                            ]}
                          >
                            <InputNumber
                              formatter={(value) =>
                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              }
                              parser={(value) => value.replace(/\s?|(,*)/g, "")}
                              placeholder="From"
                              style={{ width: "100%" }}
                              min={0}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            name={[field.name, "to"]}
                            rules={[
                              {
                                required: true,
                                message: "Please input the 'To' value",
                              },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  const fromValue = getFieldValue([
                                    "classes",
                                    field.name,
                                    "from",
                                  ]);
                                  if (value < fromValue) {
                                    return Promise.reject(
                                      new Error(
                                        "'To' value should not be less than 'From' value."
                                      )
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              }),
                            ]}
                          >
                            <InputNumber
                              formatter={(value) =>
                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              }
                              parser={(value) => value.replace(/\s?|(,*)/g, "")}
                              placeholder="To"
                              style={{ width: "100%" }}
                              min={0}
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          span={3}
                          style={{
                            textAlign: "end",
                          }}
                        >
                          <Button
                            disabled={index === 0}
                            type="primary"
                            danger
                            icon={<DeleteOutlined />}
                            onClick={() => remove(field.name)}
                          />
                        </Col>
                      </Row>
                    </Form.Item>
                  ))}
                  <Form.Item>
                    <br />
                    <Link onClick={() => add()}>+ New Distance</Link>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>

          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={isLoading || editLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
