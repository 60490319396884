import { useEffect } from "react";
import {
  Table,
  Alert,
  Space,
  Button,
  Typography,
  message,
  Popconfirm,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useQuery, useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import dayjs from "dayjs";
import { getGovs, removeGovs } from "../../api";
import { useGovernorates } from "./store";
import authFunc from "../../helper/auth";

const { Text } = Typography;

export const PureTable = () => {
  const { queryKey } = useGovernorates();
  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (value) => <Text style={{ fontWeight: "bold" }}>{value}</Text>,
    },
    {
      title: "Latitude",
      dataIndex: "lat",
      key: "lat",
    },
    {
      title: "Longitude",
      dataIndex: "lon",
      key: "lon",
    },
    {
      title: "Creation Date",
      dataIndex: "CreatedAt",
      key: "CreatedAt",
      render: (date) => (
        <Text type="secondary">{dayjs(date).format("MMMM D, YYYY")}</Text>
      ),
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id) => (
        <Space>
          {authFunc("AREA_DELETE") && (
            <Popconfirm
              title="Are you sure to delete this task?"
              onConfirm={() => mutate(id)}
              okText="Yes"
              cancelText="No"
            >
              <Button size="small" danger icon={<DeleteOutlined />} />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  const { mutate, isLoading: addLoading } = useMutation(
    (val) => removeGovs(val),
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          queryClient.invalidateQueries("governorates");
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const {
    isLoading: loading,
    error,
    data,
    refetch,
  } = useQuery(["governorates", queryKey], getGovs, {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  useEffect(() => {
    if (queryKey) {
      refetch();
    }
  }, [queryKey]);

  if (error || (!data?.success && data?.data?.err))
    return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />
    );

  return (
    <Table
      rowKey={(record) => record.id}
      loading={loading || addLoading}
      columns={columns}
      dataSource={data ? data.data.records : []}
      size="small"
      pagination={false}
    />
  );
};
