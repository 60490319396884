import { useEffect } from "react";
import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Select,
  Divider,
  InputNumber,
  Checkbox,
  DatePicker,
  Input,
  message,
  Tag,
  Space,
  Spin,
} from "antd";
import { SaveOutlined, DeleteOutlined } from "@ant-design/icons";
import { useCompoundVoucher } from "./store";
import { useMutation, useQuery } from "react-query";
import { queryClient } from "../../queryClient";
import { creatVoucher, editVoucher, getVoucher } from "../../api";
import { CustomeCalender } from "../../comonents/global";
import dayjs from "dayjs";
import moment from "moment";

const { Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;
export const PureCreate = () => {
  const { id, setId, isCreateModal, setIsCreateModal, schedule, setSchedule } =
    useCompoundVoucher();

  const [form] = Form.useForm();

  let voucherSteps = [
    {
      priority: null,
      voucherType: null,
      minAmount: null,
      totalValue: null,
      maxAmount: null,
      vendorValue: null,
      balyValue: null,
    },
    {
      priority: null,
      voucherType: null,
      minAmount: null,
      totalValue: null,
      maxAmount: null,
      vendorValue: null,
      balyValue: null,
    },
  ];
  const resetState = () => {
    setId(null);
    queryClient.clear();
    form.resetFields();
  };

  const { isLoading: getVoucherLoading, data: voucherOne } = useQuery(
    "voucherOne",
    () => getVoucher(id),
    {
      retry: 2,
      refetchOnWindowFocus: false,
      enabled: !!id,
    }
  );

  useEffect(() => {
    if (id) {
      form.setFieldsValue({
        code: voucherOne?.data?.code,
        voucherTitle: voucherOne?.data?.voucherTitle,
        totalQty: voucherOne?.data?.totalQty,
        startDate: moment(voucherOne?.data?.startDate),
        endDate: moment(voucherOne?.data?.endDate),
        platform: voucherOne?.data?.platform
          ? JSON.parse(voucherOne?.data?.platform)
          : [],
        fisrtOrderOnly: voucherOne?.data?.fisrtOrderOnly,
        newUsersOnly: voucherOne?.data?.newUsersOnly,
        UDID: voucherOne?.data?.UDID,
        applyOverDiscount: voucherOne?.data?.applyOverDiscount,
        avToCustomers: voucherOne?.data?.avToCustomers,
        customerDesc: voucherOne?.data?.customerDesc,
        voucherSteps: voucherOne?.data?.voucherSteps,
        schedule: voucherOne?.data?.schedule
          ? JSON.parse(voucherOne?.data?.schedule)?.length > 0
            ? JSON.parse(voucherOne?.data?.schedule)
            : null
          : [],
      });
    } else {
      resetState();
    }
  }, [voucherOne]);

  const { mutate, isLoading } = useMutation(creatVoucher, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        resetState();
        setIsCreateModal(false);
        queryClient.invalidateQueries("vouchers");
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editVoucher,
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          resetState();
          setIsCreateModal(false);
          queryClient.invalidateQueries("vouchers");
          queryClient.invalidateQueries("bulkVouchers");
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const getVendorValue = (index) => {
    const fields = form.getFieldsValue();
    const { voucherSteps } = fields;
    Object.assign(voucherSteps[index], {
      vendorValue:
        voucherSteps[index].totalValue - voucherSteps[index].balyValue,
    });
    form.setFieldsValue({ voucherSteps });
  };

  const onFinish = (values) => {
    values.voucherSteps.map((_, index) => {
      values.voucherSteps[index].priority = index;
    });
    values.noOfUsage = 1;
    values.platform = JSON.stringify(values.platform);
    values.schedule = JSON.stringify(schedule);
    values.startDate = dayjs(values.startDate).startOf("day").toDate();
    values.endDate = dayjs(values.endDate).startOf("day").toDate();
    values.userIDs = "[]";
    values.cityIDs = "[]";
    values.BusinessCategoryIDs = "[]";
    values.vendorIDs = "[]";
    values.voucherType = "COMPOUND";
    values.ValueType = "PERCENTAGE";
    values.tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (id) {
      values.id = id;
      editMutate(values);
    } else {
      mutate(values);
    }
  };

  return (
    <Modal
      forceRender
      title={<Title level={5}>{id ? "Edit" : "New"} Compound Voucher</Title>}
      className="app-modal voucher-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetState();
        setIsCreateModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      maskClosable={false}
      width={1480}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        form={form}
        onFinish={onFinish}
        initialValues={{ remember: true, voucherSteps: voucherSteps }}
        layout="vertical"
      >
        <Spin spinning={getVoucherLoading}>
          <Row gutter={[16, 16]}>
            <Col span={16}>
              <Row gutter={[10, 10]}>
                <Col span={24}>
                  <Form.List name="voucherSteps">
                    {(fields, { add, remove }) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Button
                          style={{ width: "100px", marginLeft: "auto" }}
                          type="dashed"
                          onClick={() => add()}
                          block
                          disabled={
                            form.getFieldValue("voucherSteps")?.length >= 5
                          }
                        >
                          Add Step
                        </Button>
                        <Space direction="vertical" />
                        <Row
                          style={{
                            maxHeight: "125px",
                            overflow: "auto",
                            overflowX: "hidden",
                            marginTop: "10px",
                          }}
                        >
                          {fields.map((field, index) => (
                            <Form.Item
                              className="distanc-list"
                              required={true}
                              key={field.key}
                            >
                              <Row gutter={[5, 10]}>
                                <Col span={2}>
                                  <Form.Item
                                    label={"Priority"}
                                    name={[field.name, "priority"]}
                                    requiredMark={false}
                                    width={"100%"}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Tag color="blue">{index + 1}</Tag>
                                  </Form.Item>
                                </Col>
                                <Col span={4}>
                                  <Form.Item
                                    label="Voucher Type"
                                    name={[field.name, "voucherType"]}
                                    rules={[
                                      { required: true, message: "required" },
                                    ]}
                                  >
                                    <Select
                                      placeholder="Voucher Type"
                                      style={{
                                        maxHeight: "80px",
                                        overflow: "auto",
                                      }}
                                    >
                                      <Option value={"DELIVERY"}>
                                        Free Delivery
                                      </Option>
                                      <Option value={"DISCOUNT"}>
                                        Discount
                                      </Option>
                                      <Option value={"COMBINED"}>
                                        Combined
                                      </Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={4}>
                                  <Form.Item
                                    label="Min Basket Value"
                                    name={[field.name, "minAmount"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "required",
                                      },
                                    ]}
                                  >
                                    <InputNumber
                                      formatter={(value) =>
                                        `${value}`.replace(
                                          /\B(?=(\d{3})+(?!\d))/g,
                                          ","
                                        )
                                      }
                                      parser={(value) =>
                                        value.replace(/\$\s?|(,*)/g, "")
                                      }
                                      style={{ width: "100%" }}
                                      max={100000}
                                    />
                                  </Form.Item>
                                </Col>

                                <Col span={4}>
                                  <Form.Item
                                    label="Max Order Value(IQD)"
                                    name={[field.name, "maxAmount"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "required",
                                      },
                                    ]}
                                  >
                                    <InputNumber
                                      formatter={(value) =>
                                        `${value}`.replace(
                                          /\B(?=(\d{3})+(?!\d))/g,
                                          ","
                                        )
                                      }
                                      parser={(value) =>
                                        value.replace(/\$\s?|(,*)/g, "")
                                      }
                                      style={{ width: "100%" }}
                                      max={100000}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={3}>
                                  <Form.Item
                                    label="Total Value"
                                    name={[field.name, "totalValue"]}
                                    rules={[
                                      { required: true, message: "required" },
                                      {
                                        type: "number",
                                        min: 0,
                                        max: 100,
                                        message: "must be percentage",
                                      },
                                    ]}
                                  >
                                    <InputNumber
                                      min={0}
                                      max={100}
                                      addonAfter="%"
                                      parser={(value) => value.replace("%", "")}
                                      onChange={() => getVendorValue(index)}
                                      style={{ width: "100%" }}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={3}>
                                  <Form.Item
                                    label="Baly Value"
                                    name={[field.name, "balyValue"]}
                                    rules={[
                                      { required: true, message: "required" },
                                      {
                                        type: "number",
                                        min: 0,
                                        max: 100,
                                        message: "must be percentage",
                                      },
                                    ]}
                                  >
                                    <InputNumber
                                      min={0}
                                      max={100}
                                      addonAfter="%"
                                      parser={(value) => value.replace("%", "")}
                                      onChange={() => getVendorValue(index)}
                                      style={{ width: "100%" }}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={3}>
                                  <Form.Item
                                    label="Vendor Value"
                                    name={[field.name, "vendorValue"]}
                                    rules={[
                                      { required: true, message: "required" },
                                      {
                                        type: "number",
                                        min: 0,
                                        max: 100,
                                        message: "must be percentage",
                                      },
                                    ]}
                                  >
                                    <InputNumber
                                      min={0}
                                      max={100}
                                      addonAfter="%"
                                      parser={(value) => value.replace("%", "")}
                                      style={{ width: "100%" }}
                                      readOnly={true}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={1}>
                                  <Form.Item label={"   "} requiredMark={false}>
                                    <Button
                                      type="primary"
                                      danger
                                      icon={<DeleteOutlined />}
                                      onClick={() => remove(field.name)}
                                      disabled={fields.length === 2}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Form.Item>
                          ))}
                        </Row>
                      </div>
                    )}
                  </Form.List>
                </Col>
                <Divider />
                <Col span={6}>
                  <Form.Item
                    label="Title"
                    name="voucherTitle"
                    rules={[{ required: true, message: "required" }]}
                  >
                    <Input placeholder="Voucher Title" />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item
                    label="Code"
                    name="code"
                    rules={[{ required: true, message: "required" }]}
                  >
                    <Input placeholder={"Balyf70"} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Quantity"
                    name="totalQty"
                    rules={[{ required: true, message: "required" }]}
                  >
                    <InputNumber
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    />
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Col span={24}>
                    <Form.Item
                      label="Customer Description"
                      name="customerDesc"
                      rules={[{ required: true, message: "required" }]}
                    >
                      <TextArea
                        autoSize={{
                          minRows: 4,
                        }}
                        placeholder={"Customer Description"}
                      />
                    </Form.Item>
                  </Col>
                </Col>
                <Col span={14}>
                  <Form.Item
                    label="Platforms"
                    name="platform"
                    rules={[{ required: true, message: "required" }]}
                  >
                    <Select
                      mode="tags"
                      placeholder="Choose platform"
                      style={{
                        maxHeight: "80px",
                        overflow: "auto",
                      }}
                    >
                      <Option value={"PWA"}>PWA</Option>
                      <Option value={"WEB"}>WEB</Option>
                      <Option value={"IOS_SUPERAPP"}>IOS_SUPERAPP</Option>
                      <Option value={"ANDROID_SUPERAPP"}>
                        ANDROID_SUPERAPP
                      </Option>
                      <Option value={"IOS_SHELL"}>IOS_SHELL</Option>
                      <Option value={"ANDROID_SHELL"}>ANDROID_SHELL</Option>
                      <Option value={"IOS"}>IOS</Option>
                      <Option value={"ANDROID"}>ANDROID</Option>
                      <Option value={"WINDOWS10"}>WINDOWS10</Option>
                      <Option value={"DESKTOP_WEB"}>DESKTOP_WEB</Option>
                      <Option value={"MOBILE_WEB"}>MOBILE_WEB</Option>
                      <Option value={"WEB_IOS"}>WEB_IOS</Option>
                      <Option value={"WEB_MACOS"}>WEB_MACOS</Option>
                      <Option value={"WEB_WINDOWS"}>WEB_WINDOWS</Option>
                      <Option value={"SUPERAPP_ANDROID"}>
                        SUPERAPP_ANDROID
                      </Option>
                      <Option value={"SUPERAPP_IOS"}>SUPERAPP_IOS</Option>
                      <Option value={"WEB_ANDROID"}>WEB_ANDROID</Option>
                      <Option value={"SHELL_IOS"}>SHELL_IOS</Option>
                      <Option value={"SHELL_ANDROID"}>SHELL_ANDROID</Option>
                      <Option value={"OTHER"}>OTHER</Option>
                    </Select>
                  </Form.Item>

                  <Col>
                    <Row>
                      <Form.Item name="fisrtOrderOnly" valuePropName="checked">
                        <Checkbox>First Order Only?</Checkbox>
                      </Form.Item>

                      <Form.Item name="newUsersOnly" valuePropName="checked">
                        <Checkbox>New Users Only?</Checkbox>
                      </Form.Item>

                      <Form.Item name="UDID" valuePropName="checked">
                        <Checkbox> Devices Only?</Checkbox>
                      </Form.Item>
                    </Row>
                    <Row>
                      <Form.Item
                        name="applyOverDiscount"
                        valuePropName="checked"
                      >
                        <Checkbox>Apply over discount ?</Checkbox>
                      </Form.Item>

                      <Form.Item name="avToCustomers" valuePropName="checked">
                        <Checkbox> Available to Customers ?</Checkbox>
                      </Form.Item>
                    </Row>
                  </Col>
                </Col>
                <Col span={24}>
                  <Divider />
                  <Form.Item>
                    <Button
                      loading={isLoading || editLoading}
                      type="primary"
                      htmlType="submit"
                      icon={<SaveOutlined />}
                    >
                      Save
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={1}>
              <Divider type="vertical" style={{ height: "100%" }} />
            </Col>
            <Col span={7}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    // value={moment(startDate)}
                    label="Start Date"
                    name="startDate"
                    rules={[{ required: true, message: "required" }]}
                  >
                    <DatePicker style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    // value={moment(endDate)}
                    label="End Date"
                    name="endDate"
                    rules={[{ required: true, message: "required" }]}
                  >
                    <DatePicker style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Schedule"
                    name="schedule"
                    rules={[
                      { required: true, message: "schedule is required" },
                    ]}
                  >
                    <CustomeCalender
                      value={id ? schedule : null}
                      onChange={(val) => {
                        setSchedule(val);
                      }}
                      isAll={() => null}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Spin>
      </Form>
    </Modal>
  );
};
