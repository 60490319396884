import { Typography, Modal, Table } from "antd";
import { useSubProduct } from "./store";
import dayjs from "dayjs";

const { Title, Text } = Typography;

export const HistoryModal = () => {
  const { historyData, isHistoryModal, setIsHistoryModal } = useSubProduct();

  let data = JSON.parse(historyData);
  const columns = [
    {
      title: "User ID",
      dataIndex: "userID",
      key: "userID",
    },
    {
      title: "User Name",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Action",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "At",
      dataIndex: "time",
      key: "time",
      render: (time) => (
        <Text type="secondary">{dayjs(time).format("YYYY-MM-DD hh:mm A")}</Text>
      ),
    },
  ];

  return (
    <Modal
      forceRender
      title={<Title level={5}> Status History</Title>}
      className="app-modal"
      centered
      visible={isHistoryModal}
      onCancel={() => {
        setIsHistoryModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={550}
    >
      <Table
        columns={columns}
        dataSource={data ? data : []}
        size="small"
        pagination={false}
        scroll={{
          y: 400,
        }}
      />
    </Modal>
  );
};
