const authFunc = (role) => {
  let user = JSON.parse(localStorage.getItem("bo_user"));
  let isAuth = false;
  if (user && user.roles) {
    let isRole = user.roles.find((_role) => _role == role);
    if (isRole) return (isAuth = true);
  } else {
    isAuth = false;
  }

  return isAuth;
};

export default authFunc;
