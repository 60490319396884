import { Card, Input, Space, Button, Breadcrumb, Tooltip } from "antd";
import { PlusOutlined, RadarChartOutlined } from "@ant-design/icons";
import { PureTable } from "./table";
import { PureCreate } from "./create";
import { RadiusModal } from "./radiusModal";
import { CloseTimeModal } from "./closeTimeModal";
import { useResturant } from "./store";
import ResturantFilters from "./filters";
import authFunc from "../../helper/auth";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const { Search } = Input;

export const ResturantsPage = () => {
  const {
    setIsCreateModal,
    setQueryKey,
    queryKey,
    setIsCreateRadiusModal,
    isData,
  } = useResturant();
  const [search, setSearch] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (queryKey) {
      setSearch(queryKey);
    }
  }, []);

  useEffect(() => {
    if (searchParams.get("q")) {
      setSearch(searchParams.get("q"));
      setQueryKey(searchParams.get("q"));
    } else {
      setSearch("");
      setQueryKey("");
    }
  }, [searchParams.get("q")]);

  const disabledRadiusBtn = () => {
    if (
      (searchParams.get("q") ||
        searchParams.get("provinceID") ||
        searchParams.get("cityID") ||
        searchParams.get("statusCodeID") ||
        searchParams.get("businessCategoryIDS") ||
        searchParams.get("active")) &&
      isData
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className="app-page">
      <Breadcrumb style={{ marginBottom: 10 }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Restaurants</Breadcrumb.Item>
        <Breadcrumb.Item>Restaurant List</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <div className="app-space-between">
          <Space wrap>
            <Search
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onSearch={(val) => {
                searchParams.set("q", val);
                searchParams.set("page_num", 1);
                setSearchParams(searchParams);
                setQueryKey(val);
              }}
              style={{ width: 160 }}
              placeholder="Write To Search..."
            />
            <ResturantFilters />
          </Space>
          <Space>
            {authFunc("VENDOR_UPDATE") && (
              <Tooltip
                placement="top"
                title={
                  disabledRadiusBtn()
                    ? "find restaurants using filters & search first"
                    : "change filtered restaurants radius"
                }
              >
                <Button
                  onClick={() => setIsCreateRadiusModal(true)}
                  type="primary"
                  icon={<RadarChartOutlined />}
                  disabled={disabledRadiusBtn()}
                >
                  Change Radius
                </Button>
              </Tooltip>
            )}
            {authFunc("VENDOR_CREATE") && (
              <Button
                onClick={() => setIsCreateModal(true)}
                type="primary"
                icon={<PlusOutlined />}
              >
                Create New
              </Button>
            )}
          </Space>
        </div>
        <br />
        {/* <FilterModal/> */}
        <PureTable />
        <PureCreate />
        <RadiusModal />
        <CloseTimeModal />
      </Card>
    </div>
  );
};
