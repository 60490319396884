import { useEffect, useState } from "react";
import { Table, Typography, Alert, Pagination, Tooltip, Button } from "antd";
import { HistoryOutlined } from "@ant-design/icons";
import { useQuery } from "react-query";
import { getVendorLog, getWeightsLogs, pageSize } from "../../api";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import { useWeightsLogs } from "./store";
const { Text } = Typography;

export const PuerTable = () => {
  const { setHistoryData, setIsHistoryModal } = useWeightsLogs();
  const [page, setPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const handleHistory = (val) => {
    setHistoryData(val);
    setIsHistoryModal(true);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      width: 100,
      render: (text, record, index) => index + 1,
    },
    {
      title: "Weights",
      dataIndex: "weights",
      key: "weights",
      render: (val) => (
        <>
          <Tooltip placement="top" title="Show weights">
            <Button
              onClick={() => handleHistory(val)}
              size="small"
              icon={<HistoryOutlined />}
            >
              Weights
            </Button>
          </Tooltip>
        </>
      ),
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (el) => <Text>{dayjs(el).format("YYYY-MM-DD hh:mm A")}</Text>,
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      key: "created_by",
    },
  ];

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(["weightsLogs", page], getWeightsLogs, {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  if (error || (!data?.success && data?.data?.err))
    return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />
    );

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data ? data?.data?.records : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        defaultPageSize={pageSize}
        total={data && data?.data?.total}
        showSizeChanger={false}
      />
    </>
  );
};
