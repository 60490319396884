import create from "zustand";

export const useBulk = create((set) => ({
  isCreateModal: false,
  isExport: false,
  queryKey: "",
  row: null,

  setQueryKey: (queryKey) => set({ queryKey }),
  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
  setRow: (row) => set({ row }),
  setIsExport: (isExport) => set({ isExport }),
}));
