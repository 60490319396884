import {
  Table,
  Button,
  message,
  Alert,
  Space,
  Popconfirm,
  Pagination
} from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useQuery, useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import {
  getInternalProduct,
  removeInternalProduct,
  pageSize
} from "../../api";
import { useInternalProduct } from "./store";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import authFunc from "../../helper/auth";


export const PuerTable = () => {
  const {
    setId,
    setIsCreateModal,
    setInternalName
  } = useInternalProduct();

  const [page, setPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleEdit = (row) => {
    setId(row.id);
    setInternalName(row.internal_name)
    setIsCreateModal(true);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      width: "10%",
    },

    {
      title: "Internal Product name",
      dataIndex: "internal_name",
      key: "internal_name",
      width: "70%",

    },


    {
      title: "Actions",
      key: "id",
      dataIndex: "id",
      fixed: "right",

      render: (id, row) => (
        <Space>
          <Button
            onClick={() => handleEdit(row)}
            size="small"
            icon={<EditOutlined />}
            disabled={!authFunc("INTERNAL_PRODUCT_GROUP_CREATE")}
          />
          <Popconfirm
            title="Are you sure to delete this task?"
            onConfirm={() => mutate(id)}
            //onCancel={cancel}
            disabled={!authFunc("INTERNAL_PRODUCT_GROUP_CREATE")}
            okText="Yes"
            cancelText="No"
          >
            <Button
              disabled={!authFunc("INTERNAL_PRODUCT_GROUP_CREATE")}
              size="small"
              danger
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const { mutate } = useMutation(removeInternalProduct, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        queryClient.invalidateQueries("InternalProduct");
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(["InternalProduct", page], getInternalProduct, {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  if (error || (!data?.success && data?.data?.err))
    return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />
    );

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data?.data.records ? data?.data.records : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        defaultPageSize={pageSize}
        total={data && data.data.total}
        showSizeChanger={false}
      />
    </>
  );
};
