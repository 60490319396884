import { useEffect, useState } from "react";
import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  Upload,
  InputNumber,
  message,
  DatePicker,
} from "antd";
import { SaveOutlined, InboxOutlined } from "@ant-design/icons";
import { useContract } from "./store";
import Input from "antd/lib/input/Input";
import { createContract, editeContract } from "../../api";
import { useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import moment from "moment";
import { useParams } from "react-router-dom";

const { Title } = Typography;
const { Dragger } = Upload;

export const PureCreate = () => {
  const [phoneValidation, setPhoneValidation] = useState(false);
  const [phoneState, setPhoneState] = useState(null);

  const {
    isCreateModal,
    setIsCreateModal,
    resturantOwnerName,
    resturantOwnerPhoneNo,
    resturantOwnerEmail,
    startDate,
    endDate,
    percenage,
    id,
    setId,
    setResturantOwnerEmail,
    setResturantOwnerName,
    setResturantOwnerPhoneNo,
    setStartDate,
    setEndDate,
    setPercenage,
  } = useContract();
  const [form] = Form.useForm();
  const { id: vendorID } = useParams();

  const { mutate, isLoading } = useMutation(createContract, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        resetState();
        setIsCreateModal(false);
        queryClient.invalidateQueries("contracts");
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editeContract,
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          resetState();
          setIsCreateModal(false);
          queryClient.invalidateQueries("contracts");
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const phoneValidate = () => {
    const regex = /^07[3-9]\d{8}$/;
    const result = regex.exec(phoneState);
    return result;
  };

  const onFinish = (values) => {
    if (id && phoneValidate() && phoneValidate()?.index === 0) {
      values.resturantOwnerPhoneNo = phoneState;
      let formData = {
        data: JSON.stringify({
          resturantOwnerName: values.resturantOwnerName,
          resturantOwnerPhoneNo: values.resturantOwnerPhoneNo,
          resturantOwnerEmail: values.resturantOwnerEmail,
          startDate: values.startDate,
          endDate: values.endDate,
          percenage: values.percenage,
          vendorID: Number(vendorID),
          id,
        }),
      };
      editMutate(formData);
    } else if (phoneValidate() && phoneValidate()?.index === 0) {
      values.resturantOwnerPhoneNo = phoneState;
      let formData = {
        contractImg: values.contractImg.file,
        data: JSON.stringify({
          resturantOwnerName: values.resturantOwnerName,
          resturantOwnerPhoneNo: values.resturantOwnerPhoneNo,
          resturantOwnerEmail: values.resturantOwnerEmail,
          startDate: values.startDate,
          endDate: values.endDate,
          percenage: values.percenage,
          vendorID: Number(vendorID),
          id,
        }),
      };
      mutate(formData);
    } else setPhoneValidation(true);
  };

  const props = {
    name: "file",
    multiple: false,
    maxCount: 1,
    beforeUpload: () => {
      return false;
    },
  };

  let resetState = () => {
    setResturantOwnerEmail(null);
    setResturantOwnerName(null);
    setResturantOwnerPhoneNo(null);
    setStartDate(null);
    setEndDate(null);
    setPercenage(null);
    setId(null);
    form.resetFields();
  };

  useEffect(() => {
    if (id) {
      form.setFieldsValue({
        remember: true,
        resturantOwnerName,
        resturantOwnerPhoneNo,
        resturantOwnerEmail,
        percenage,
        startDate: startDate ? moment(startDate) : null,
        endDate: endDate ? moment(endDate) : null,
      });
    } else {
      resetState();
    }
  }, [id]);

  return (
    <Modal
      forceRender
      title={<Title level={5}>{id ? "Edit" : "New"} Contract</Title>}
      className="app-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetState();
        setIsCreateModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={550}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={14}>
            <Form.Item
              label="Owner Name"
              name="resturantOwnerName"
              rules={[{ required: true }]}
            >
              <Input placeholder={"Write a Resturant Owner Name"} />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              label="Phone"
              name="resturantOwnerPhoneNo"
              rules={[{ required: true }]}
            >
              <Input
                placeholder={"Write Phone Number"}
                status={phoneValidation ? "error" : ""}
                maxLength="11"
                minLength="11"
                onChange={(e) => setPhoneState(e.target.value)}
                value={phoneState}
              />
            </Form.Item>
          </Col>
          <Col span={18}>
            <Form.Item label="Email" name="resturantOwnerEmail">
              <Input placeholder="example@mail.co" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Percenage (.%)"
              name="percenage"
              rules={[{ required: true }]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Start Date"
              name="startDate"
              rules={[{ required: true }]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="End Date"
              name="endDate"
              rules={[{ required: true }]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>

          {!id ? (
            <Col span={24}>
              <Form.Item name="contractImg" rules={[{ required: true }]}>
                <Dragger {...props}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag File to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    This File will save in Resturant details
                  </p>
                </Dragger>
              </Form.Item>
            </Col>
          ) : null}
          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={isLoading || editLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
