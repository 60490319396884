import { useEffect, useState } from "react";
import {
  Table,
  Tag,
  Typography,
  Button,
  message,
  Alert,
  Space,
  Popconfirm,
  Pagination,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
} from "@ant-design/icons";
import { useQuery, useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import authFunc from "../../helper/auth";
import dayjs from "dayjs";
import { getDeclineReason, removeData, pageSize } from "../../api";
import { useDeclineReason } from "./store";
import { useApp } from "../../store";
import { useSearchParams } from "react-router-dom";

const { Text } = Typography;

const userTypeColor = {
  VENDOR: "green",
  CLIENT: "magenta",
  BIKER: "purple",
  BACKOFFICE_USER: "geekblue",
};

export const PureTable = () => {
  const { setIsLogin } = useApp();
  const [page, setPage] = useState(1);
  const {
    queryKey,
    setIsCreateModal,
    setId,
    setArTitle,
    setCode,
    setUserType,
    setFloating,
    setType,
    setCommission,
    setRefund,
  } = useDeclineReason();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const handleEdit = (row) => {
    setId(row.id);
    setArTitle(row.arTitle);
    setCode(row.code);
    setUserType(row.userType);
    setIsCreateModal(true);
    setFloating(row.floating);
    setType(row.type);
    setCommission(row.commission);
    setRefund(row.refund);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Title",
      dataIndex: "arTitle",
      key: "arTitle",
      render: (text) => <Text style={{ fontWeight: "bold" }}>{text}</Text>,
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Refund",
      dataIndex: "refund",
      key: "refund",
      render: (val) => Number(val).toLocaleString(),
    },
    {
      title: "Comission",
      dataIndex: "commission",
      key: "commission",
      render: (val) =>
        val ? (
          <CheckCircleFilled style={{ color: "green" }} />
        ) : (
          <CloseCircleFilled style={{ color: "red" }} />
        ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (type) => <Tag color="default">{type}</Tag>,
    },
    {
      title: "User Type",
      dataIndex: "userType",
      key: "userType",
      render: (type) => <Tag color={userTypeColor[type]}>{type}</Tag>,
    },
    {
      title: "Floating",
      dataIndex: "floating",
      key: "floating",
      render: (val) =>
        val ? (
          <CheckCircleFilled style={{ color: "green" }} />
        ) : (
          <CloseCircleFilled style={{ color: "red" }} />
        ),
    },
    {
      title: "Creation Date",
      dataIndex: "CreatedAt",
      key: "CreatedAt",
      render: (date) => (
        <Text type="secondary">{dayjs(date).format("MMMM D, YYYY")}</Text>
      ),
    },
    {
      title: "Actions",
      key: "id",
      dataIndex: "id",
      render: (id, row) => (
        <Space>
          {authFunc("REASON_UPDATE") && (
            <Button
              onClick={() => handleEdit(row)}
              size="small"
              icon={<EditOutlined />}
            />
          )}
          {authFunc("REASON_DELETE") && (
            <Popconfirm
              title="Are you sure to delete this task?"
              onConfirm={() => mutate(id)}
              okText="Yes"
              cancelText="No"
            >
              <Button size="small" danger icon={<DeleteOutlined />} />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  const { mutate } = useMutation((data) => removeData("declinereason", data), {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        queryClient.invalidateQueries("declineReasons");
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(["declineReasons", queryKey, page], getDeclineReason, {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  if (error || (!data?.success && data?.data?.err))
    return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />
    );

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data ? data.data.records : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        defaultPageSize={pageSize}
        total={data && data.data.total}
        showSizeChanger={false}
      />
    </>
  );
};
