import React, { useState } from "react";
import { Button, Typography, Modal, message, Steps } from "antd";
import { useOrder } from "./store";
import { useApp } from "../../store";
import { useOrderView } from "../../pages/order_view/store";
import { GetClient, GetVendor, GetProducts } from "./steps";
import { useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import { createOrder } from "../../api";

const { Title } = Typography;

const { Step } = Steps;

export const PureCreate = () => {
  const {
    isCreateModal,
    setIsCreateModal,
    client,
    vendor,
    vendorID,
    payment,
    platform,
    setClient,
    setVendor,
    setPayment,
    setProducts,
    setId,
    setDeafultProducts,
    setFilterVendor,
    basket,
    setBasket,
    setVoucher,
    voucher,
  } = useOrder();
  const { setSubProductSelected } = useApp();

  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const steps = [
    {
      title: "Get Client",
      content: <GetClient />,
    },
    {
      title: "Choose Resturant",
      content: <GetVendor />,
    },
    {
      title: "Select Products",
      content: <GetProducts />,
    },
    // {
    //   title: "Final",
    //   content: <MoreInfo />,
    // },
  ];

  const isNext = () => {
    let disabled = true;
    if (current == 0 && client && client.address && platform) {
      disabled = false;
    } else if (current == 1 && vendorID) {
      disabled = false;
    } else if (current == 2 && basket.length != 0 && payment) {
      disabled = false;
    }

    return disabled;
  };

  // const { data: cities } = useQuery(["regions", "", ""], getRegions, {
  //   refetchOnWindowFocus: false,
  //   retry: 2,
  // });

  const resetState = () => {
    setBasket([]);
    setClient(null);
    setVendor(null);
    setPayment(null);
    setProducts(null);
    setFilterVendor("");
    setVoucher(null);
    setId(null);
    setCurrent(0);
    setDeafultProducts([]);
    setSubProductSelected(null);
  };

  const { mutate, isLoading } = useMutation(createOrder, {
    onSuccess: (data) => {
      if (data.success) {
        resetState();
        message.success(data.msg);
        setIsCreateModal(false);
        queryClient.invalidateQueries("orders");
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const handleFinesh = () => {
    let order = {
      orderDetail: basket.map((el) => {
        return {
          productID: el.product.id,
          qty: el.qty,
          subProductGroup: el?.product?.subProductGroup?.map((group) => {
            return {
              id: group.id,
              multiSelect: group.multiSelect,
              subProductsIDS: group?.subProductsIDS?.map((item) => {
                return {
                  id: item.id,
                  price: item.price,
                  name: item.name,
                };
              }),
            };
          }),
        };
      }),
      clientID: client.id,
      clientAddressID: client?.address?.id,
      vendorID: vendor.id,
      CityID: vendor.cityID,
      paymentTypeID: payment.id,
      voucherID: voucher ? voucher.id : 0,
      voucherCode: voucher ? voucher.code : "",
      discountCapID: vendor?.discountCap?.id || 0,
      declineReason: "{}",
      clientComment: "",
      clientCommentAddress: "",
      freeGift: false,
      platform: platform,
      preOrder: false,
      preOrderDeliveryTime: "2016-06-22 19:10:25-07",
      expectedDeliveryTime: "2016-06-22 19:10:25-07",
      bikerPickupDateTime: "2016-06-22 19:10:25-07",
    };
    // if (id) {
    //   order.id = id;
    // }
    mutate(order);
  };

  // useEffect(()=>{
  //   queryClient.clear()
  // },[isCreateModal])

  const prev = () => {
    setCurrent(current - 1);
  };
  return (
    <Modal
      title={<Title level={5}>New Order</Title>}
      className="app-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetState();
        setIsCreateModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={850}
      bodyStyle={{ paddingTop: 6 }}
    >
      <Steps size="small" current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">{steps[current].content}</div>
      <div className="steps-action">
        {current < steps.length - 1 && (
          <Button disabled={isNext()} type="primary" onClick={() => next()}>
            Next
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button
            loading={isLoading}
            disabled={isNext()}
            type="primary"
            onClick={handleFinesh}
          >
            Done
          </Button>
        )}
        {current > 0 && (
          <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
            Previous
          </Button>
        )}
      </div>
    </Modal>
  );
};
