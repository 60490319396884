import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  message,
  Select,
  Divider,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import Input from "antd/lib/input/Input";
import { useMutation, useQuery } from "react-query";
import { queryClient } from "../../queryClient";
import { editMsgTemplate, createMsgTemplate, getOrderStatus } from "../../api";
import { useMsgTemplate } from "./store";
import { KeysTable } from "./keysTable";

const { Title } = Typography;
const { Option } = Select;

export const PureCreate = () => {
  const {
    isCreateModal,
    setIsCreateModal,
    id,
    statusCodeID,
    msg,
    config,
    deepLink,
    setId,
    setMsg,
    setStatusCodeID,
    setDeepLink,
    setConfig,
  } = useMsgTemplate();

  const resetState = () => {
    setMsg(null);
    setId(null);
    setStatusCodeID(null);
    setConfig(null);
    setDeepLink(null);
  };

  const { mutate, isLoading } = useMutation(createMsgTemplate, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        setIsCreateModal(false);
        queryClient.invalidateQueries("msgTemplate");
        resetState();
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });
  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editMsgTemplate,
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          setIsCreateModal(false);
          queryClient.invalidateQueries("msgTemplate");
          resetState();
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const { isLoading: statusloading, data: statusData } = useQuery(
    ["orderStatusForMSG", "", 1],
    getOrderStatus,
    {
      refetchOnWindowFocus: false,
      retry: 2,
      enabled: !!isCreateModal,
    }
  );

  const onFinish = (values) => {
    let _data = {
      statusCodeID: values.statusCodeID,
      msg: JSON.stringify({
        content: values.content,
        sendBy: values.sendBy,
      }),
      config: JSON.stringify({
        campaignURL: values.campaignURL,
        apiKey: values.apiKey,
      }),
      deepLink: values.deepLink,
    };
    if (id) {
      _data.id = id;
      editMutate(_data);
    } else {
      mutate(_data);
    }
  };

  return (
    <Modal
      title={<Title level={5}>{id ? "Edit" : "New"} Message template</Title>}
      className="app-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetState();
        setIsCreateModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={980}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        initialValues={{
          statusCodeID,
          content: msg ? JSON.parse(msg).content : null,
          sendBy: msg ? JSON.parse(msg).sendBy : [],
          deepLink,
          campaignURL: config ? JSON.parse(config).campaignURL : null,
          apiKey: config ? JSON.parse(config).apiKey : null,
        }}
        onFinish={onFinish}
        layout="vertical"
      >
        <Row gutter={[16, 16]}>
          <Col span={10}>
            <Row gutter={[20, 20]}>
              <Col span={24}>
                <Form.Item
                  label="Message"
                  name="content"
                  rules={[{ required: true, message: "required" }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Send By"
                  name="sendBy"
                  rules={[{ required: true, message: "required" }]}
                >
                  <Select
                    mode="multiple"
                    defaultValue={null}
                    placeholder="Message Type"
                  >
                    <Option value={"Email"}>Email</Option>
                    <Option value={"SMS"}>SMS</Option>
                    <Option value={"Notification"}>Notification</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Campaign URL"
                  name="campaignURL"
                  // rules={[{ required: true, message: "required" }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="API Key"
                  name="apiKey"
                  // rules={[{ required: true, message: "required" }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Deep Link"
                  name="deepLink"
                  // rules={[{ required: true, message: "required" }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Order state"
                  name="statusCodeID"
                  loading={statusloading}
                  rules={[{ required: true }]}
                >
                  <Select placeholder="Select Status Code">
                    {statusData &&
                      statusData?.success &&
                      statusData?.data?.records?.map((el, index) => (
                        <Option key={index} value={el.id}>
                          {el.arTitle}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item>
                  <Button
                    loading={isLoading || editLoading}
                    type="primary"
                    htmlType="submit"
                    icon={<SaveOutlined />}
                  >
                    Create
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={1}>
            <Divider type="vertical" style={{ height: "100%" }} />
          </Col>
          <Col span={13}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <KeysTable />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
