import {
  Typography,
  Modal,
  Table,
  Tag,
  Popover,
  Tooltip,
  Space,
  Button,
} from "antd";
import { useVendorLog } from "./store";
import { ImageTable } from "../../comonents/global";

const { Title, Text } = Typography;

export const HistoryModal = () => {
  const {
    historyData,
    isStatusModal,
    setIsStatusModal,
    setIsSchedule,
    setWorkingSchedule,
    setRow,
  } = useVendorLog();

  let data = [];
  data.push(JSON.parse(historyData));

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      width: 55,
    },
    {
      title: "Logo",
      key: "id",
      width: 65,
      render: (row) => (
        <ImageTable
          logo={true}
          url={row?.logoImg}
          id={row?.id}
          pathName={"vendor/logo"}
          imgKey={"logoFile"}
          // updateStore={"resturants"}
        />
      ),
    },
    {
      title: "Banner",
      key: "id",
      width: 100,
      render: (row) => (
        <ImageTable
          logo={false}
          url={row?.bannerImg}
          id={row?.id}
          pathName={"vendor/banner"}
          imgKey={"bannerFile"}
          // updateStore={"resturants"}
        />
      ),
    },

    {
      title: "Name",
      dataIndex: "arName",
      key: "arName",
      ellipsis: true,
      render: (text, row) => (
        <Popover
          placement="topLeft"
          content={
            <Space direction="vertical" size={0}>
              <Text>
                <b>{row?.arName}</b>
              </Text>
              <Text type="secondary">{row?.description || "....."}</Text>
            </Space>
          }
        >
          <Text style={{ fontWeight: "bold" }}>{text}</Text>
        </Popover>
      ),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      ellipsis: true,
      render: (text) => (
        <Tooltip title={text}>
          <Text type="secondary" style={{ fontSize: 12 }}>
            {text}
          </Text>
        </Tooltip>
      ),
    },

    {
      title: "Owner Name",
      dataIndex: "ownerName",
      key: "ownerName",
      ellipsis: true,
    },
    {
      title: "Owner Phone",
      dataIndex: "resturantMgmtPhone",
      key: "resturantMgmtPhone",
      ellipsis: true,
      //width: 130,
      render: (text, row) => (
        <Text style={{ fontSize: 12 }}>{row?.resturantMgmtPhone}</Text>
      ),
    },
    {
      title: "Resturant Phone",
      dataIndex: "resturantPhone",
      key: "resturantPhone",
      ellipsis: true,
      render: (text, row) => (
        <Text style={{ fontSize: 12 }}>{row?.resturantPhone}</Text>
      ),
    },
    {
      title: "Min-Max Amount",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      render: (id, row) => (
        <Text style={{ fontSize: 12 }}>
          {row?.minamount}-{row?.maxamount}
        </Text>
      ),
    },
    {
      title: "State",
      dataIndex: "StatusCode",
      key: "StatusCode",
      ellipsis: true,
      render: (val) => <Tag style={{ fontSize: 12 }}>{val}</Tag>,
    },
    {
      title: "Discount Cap",
      dataIndex: "discountCap",
      key: "discountCap",
      ellipsis: true,
      render: (val) => (
        <Popover
          content={
            val?.balyValue !== 0 ? (
              <Tag color="geekblue">
                {val?.balyValue}-{val?.discountType}
              </Tag>
            ) : (
              "..."
            )
          }
        >
          {val?.balyValue !== 0 ? (
            <Tag color="geekblue">
              {val?.balyValue}-{val?.discountType}
            </Tag>
          ) : (
            "..."
          )}
        </Popover>
      ),
    },
    {
      title: "Rank",
      dataIndex: "rank",
      key: "rank",
      width: 50,
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      ellipsis: true,
      width: 55,
    },
    {
      title: "Delivery Range Distance",
      dataIndex: "deliveryRangeDistance",
      key: "deliveryRangeDistance",
      ellipsis: true,
      width: 55,
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      width: 65,
      render: (val) => <Tag>{val ? "active" : "inactive"}</Tag>,
    },
    {
      title: "Schedule",
      key: "id",
      dataIndex: "id",
      render: (id, row) => (
        <>
          <Button
            size="small"
            // disabled={!row?.workingSchedule}
            onClick={() => {
              setIsSchedule(true);
              setWorkingSchedule(
                row?.workingSchedule == "{}"
                  ? null
                  : JSON.parse(row?.workingSchedule)
              );
              setRow(row && row);
            }}
          >
            Working Days
          </Button>
        </>
      ),
    },
  ];

  return (
    <Modal
      forceRender
      title={<Title level={5}> Status Log</Title>}
      className="app-modal"
      centered
      visible={isStatusModal}
      onCancel={() => {
        setIsStatusModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={1500}
    >
      <Table
        columns={columns}
        dataSource={data ? data : []}
        size="small"
        pagination={false}
        scroll={{
          y: 400,
        }}
      />
    </Modal>
  );
};
