import React from "react";
import {
  Alert,
  List,
  Typography,
  Card,
  Input,
  Space,
  Popover,
  Avatar,
} from "antd";
import { useOrder } from "../store";
import { getResturantsByLatLon } from "../../../api";
import { useQuery } from "react-query";

const { Search } = Input;
const { Text } = Typography;

export const GetVendor = () => {
  const {
    // vendor,
    // setVendor,
    // id,
    client,
    filterVendor,
    setFilterVendor,
    vendorID,
    setVendorID,
  } = useOrder();

  const { isLoading, error, data } = useQuery(
    [
      "resturantsByLatLon",
      client?.address?.lat,
      client?.address?.lon,
      filterVendor,
    ],
    getResturantsByLatLon,
    {
      refetchOnWindowFocus: false,
      retry: 2,
      client: !!client,
    }
  );

  // useEffect(() => {
  //   if (id && vendor) {
  //     setFilterVendor(vendor.arName);
  //   }
  // }, [id]);

  if (error) return <Alert message={error.message} type="error" showIcon />;

  if (!isLoading && !data?.success)
    return <Alert message={data.msg} type="error" showIcon />;

  return (
    <div className="step-container">
      <Search
        value={filterVendor}
        onChange={(e) => setFilterVendor(e.target.value)}
        style={{ width: "100%", marginTop: 10, marginBottom: 20 }}
        placeholder="Write To Search..."
      />
      <List
        loading={isLoading}
        grid={{ gutter: 10, column: 3 }}
        dataSource={
          data && data?.data?.total !== 0
            ? data?.data?.records
              ? data?.data?.records
              : []
            : []
        }
        renderItem={(item) => (
          <List.Item>
            <Card
              onClick={() => setVendorID(item.id)}
              hoverable
              bodyStyle={{ padding: 6 }}
              style={{
                background: "#f6f6f6",
                borderRadius: 6,
                borderColor:
                  vendorID && vendorID == item.id ? "#0000ff" : "transparent",
              }}
            >
              <Space>
                <Avatar size="large" src={item.logoImg} />
                <div style={{ display: "grid" }}>
                  <Popover
                    content={
                      <Text
                        style={{
                          fontSize: 14,
                        }}
                      >
                        {item.arName}
                      </Text>
                    }
                  >
                    <Text
                      style={{
                        fontSize: 14,
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {item.arName}
                    </Text>
                  </Popover>
                  <Text style={{ fontSize: 10 }} type="secondary">
                    {item.resturantPhone}
                  </Text>
                </div>
              </Space>
            </Card>
          </List.Item>
        )}
      />
    </div>
  );
};
