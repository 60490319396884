import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  message,
  Input,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useInternalProduct } from "./store";
import { useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import {
  createInternalProduct,
  editInternalProduct,
} from "../../api";

const { Title } = Typography;

export const PureCreate = () => {
  const {
    isCreateModal,
    id,
    internal_name,
    setId,
    setInternalName,
    setIsCreateModal
  } = useInternalProduct();



  const resetState = () => {
    setId(null);
    setInternalName(null)
  };


  const { mutate, isLoading } = useMutation(createInternalProduct, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        setIsCreateModal(false);
        queryClient.invalidateQueries("InternalProduct");
        resetState();
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });
  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editInternalProduct,
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          setIsCreateModal(false);
          queryClient.invalidateQueries("compensation");
          resetState();
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const onFinish = (values) => {
    if (id) {
      values.id = id;
      editMutate(values);
    } else {
      mutate(values);
    }
  };

  return (
    <Modal
      title={
        <Title level={5}>{id ? "Edit" : "New"} Internal Product</Title>
      }
      className="app-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetState();
        setIsCreateModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={600}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        initialValues={{ remember: true, internal_name }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Form.Item
              label="Internal Product name "
              name="internal_name"
              rules={[{ required: true }]}
            >
              <Input placeholder="Internal Product name" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={isLoading || editLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
