import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Select,
  Divider,
  message,
  Alert,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import Input from "antd/lib/input/Input";
import { createRegion, getGovs, editRegion } from "../../api";
import { PureMapDrow } from "./mapDrow";
import { useMutation, useQuery } from "react-query";
import { queryClient } from "../../queryClient";
import { useRegion } from "./store";

const { Title } = Typography;
const { Option } = Select;
export const PureCreate = () => {
  let {
    isCreateModal,
    setIsCreateModal,
    name,
    provinceID,
    id,
    area,
    setId,
    setArea,
    setName,
    setProvinceID,
    setCreateMapCenter,
  } = useRegion();

  const [form] = Form.useForm();

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery("governorates", getGovs, {
    refetchOnWindowFocus: false,
    retry: 2,
    enabled: !!isCreateModal,
  });

  let resetState = () => {
    setId(null);
    setArea(null);
    setName(null);
    setProvinceID(null);
  };

  const { mutate, isLoading: addLoagin } = useMutation(createRegion, {
    onSuccess: (data) => {
      if (data?.success) {
        message.success(data?.msg);
        queryClient.invalidateQueries("regions");
        form.setFieldsValue({
          name: null,
          provinceID: null,
        });
        resetState();
        setIsCreateModal(false);
      } else {
        message.error(data?.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const { mutate: editeMutate, isLoading: editLoading } = useMutation(
    editRegion,
    {
      onSuccess: (data) => {
        if (data?.success) {
          message.success(data?.msg);
          queryClient.invalidateQueries("regions");
          form.setFieldsValue({
            name: null,
            provinceID: null,
          });
          resetState();
          setIsCreateModal(false);
        } else {
          message.error(data?.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const onFinish = (values) => {
    values.area = JSON.stringify(area);
    //values.provinceID = JSON.parse(values.provinceID).id;
    if (id) {
      values.id = id;
      editeMutate(values);
    } else {
      mutate(values);
    }
  };

  return (
    <Modal
      title={<Title level={5}>{id ? "Edite" : "Create"} Region</Title>}
      className="app-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetState();
        setIsCreateModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={700}
    >
      {error ? (
        <Alert
          style={{ marginBottom: 16 }}
          message={"An error has occurred: " + error.message}
          type="error"
          showIcon
        />
      ) : null}
      <Form
        requiredMark={"optional"}
        name="basic"
        initialValues={{ remember: true, name, provinceID, area }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={14}>
            <Form.Item
              label="City Name"
              name="name"
              value={name}
              onChange={(val) => setName(val)}
              rules={[{ required: true }]}
            >
              <Input placeholder="Write City Name" />
            </Form.Item>
          </Col>

          <Col span={10}>
            <Form.Item
              label="Governorate"
              name="provinceID"
              rules={[{ required: true }]}
            >
              <Select
                value={provinceID}
                onChange={(val) => {
                  // let el = JSON.parse(val);
                  setProvinceID(val);
                  //setCreateMapCenter([el.lon, el.lat]);
                  if (!loading && data?.success) {
                    setCreateMapCenter(
                      data?.data?.records?.find((el) => el.id == val)
                    );
                  }
                }}
                loading={loading}
                placeholder="Choose Governorate"
              >
                {!loading && data?.success
                  ? data?.data?.records?.map((el) => (
                      <Option key={el.id} value={el.id}>
                        {el.name}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <PureMapDrow
              onDrow={(val) => {
                setArea(val.features[0].geometry);
              }}
            />
          </Col>
          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={addLoagin || editLoading}
                disabled={area && name && provinceID ? false : true}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
