import { Card, Breadcrumb } from "antd";
import { PuerTable } from "./table";

export const ProductWeightsPage = () => {
  return (
    <div className="app-page">
      <Breadcrumb style={{ marginBottom: 10 }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Product Weight List</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <PuerTable />
      </Card>
    </div>
  );
};
