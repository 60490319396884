import { useState, useEffect } from "react";
import { Select, Spin, DatePicker, Space } from "antd";
import { getResturants } from "../../api";
import { useVendorPayment } from "./store";
import "../../App.css";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import moment from "moment";

const { RangePicker } = DatePicker;
const { Option } = Select;
const date = new Date();

export default function PaymentFilters() {
  const {
    setPage,
    vendorID,
    setVendorID,
    setTotalValue,
    setTo,
    setFrom,
    to,
    from,
  } = useVendorPayment();

  const fromDateFormated = moment(date.setHours(8, 0, 0, 0)).format(
    "YYYY-MM-DD hh:mm a"
  );
  const toDateFormated = moment(date.setHours(23, 59, 0, 0)).format(
    "YYYY-MM-DD hh:mm a"
  );

  const [resturantSearch, setResturantSearch] = useState("");

  const { isLoading: isResturantLoading, data: resturants } = useQuery(
    ["resturants", resturantSearch, 1, "", 10],
    getResturants,
    {
      retry: 2,
      refetchOnWindowFocus: false,
    }
  );

  // GENERAL STATES
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    let obj = {
      vendorID: Number(searchParams.get("vendorID")) || null,
      from: searchParams.get("from") || null,
      to: searchParams.get("to") || null,
      page_num: searchParams.get("page_num") || null,
    };

    setFrom(obj.from);
    setTo(obj.to);
    setVendorID(obj.vendorID);
    setPage(obj.page_num);
  }, [
    searchParams.get("from"),
    searchParams.get("to"),
    searchParams.get("vendorID"),
    searchParams.get("page_num"),
  ]);

  useEffect(() => {
    if (!searchParams.get("from")) {
      searchParams.set("from", JSON.stringify(fromDateFormated));
      searchParams.set("to", JSON.stringify(toDateFormated));
      setSearchParams(searchParams);
    }
  }, [searchParams.get("from")]);

  const SelectTimeDate = (value, dateString) => {
    let from = searchParams.get("from")
      ? JSON.stringify(dateString[0])
      : JSON.stringify(fromDateFormated);
    let to = searchParams.get("from")
      ? JSON.stringify(dateString[1])
      : JSON.stringify(fromDateFormated);

    if (value) {
      setFrom(from);
      setTo(to);
      searchParams.set("from", from);
      searchParams.set("to", to);
      searchParams.set("page_num", 1);
      setSearchParams(searchParams);
    }
  };

  return (
    <Space wrap>
      <Select
        filterOption={false}
        dropdownMatchSelectWidth={false}
        notFoundContent={
          isResturantLoading ? (
            <div className="spin-wrapper">
              <Spin size="small" />
            </div>
          ) : null
        }
        allowClear
        showSearch
        onChange={(val) => {
          if (val !== undefined) {
            setVendorID(val);
            searchParams.set("vendorID", val);
            searchParams.set("page_num", 1);
            setSearchParams(searchParams);
          }
        }}
        onClear={() => {
          setVendorID(null);
          setTotalValue(0);
          setResturantSearch(null);
          searchParams.delete("vendorID");
          searchParams.set("page_num", 1);
          setSearchParams(searchParams);
        }}
        onSearch={(val) => setResturantSearch(val)}
        placeholder="Select Vendors"
        style={{ width: "100%", maxWidth: 300 }}
        value={vendorID}
      >
        {resturants &&
          resturants.success &&
          resturants.data.records.map((el) => (
            <Option key={el.id} value={el.id}>
              {el.arName}
            </Option>
          ))}
      </Select>
      <Space direction="vertical" size={5}>
        <RangePicker
          showTime={{
            format: "hh:mm a",
          }}
          value={
            from
              ? [
                  moment(from, "YYYY-MM-DD hh:mm a"),
                  moment(to, "YYYY-MM-DD hh:mm a"),
                ]
              : [moment(fromDateFormated), moment(toDateFormated)]
          }
          defaultValue={[moment(fromDateFormated), moment(toDateFormated)]}
          format="YYYY-MM-DD hh:mm a"
          onChange={SelectTimeDate}
        />
      </Space>
    </Space>
  );
}
