import { useState, useEffect } from "react";
import {
  Table,
  Typography,
  Button,
  message,
  Alert,
  Space,
  Popconfirm,
  Pagination,
  Switch,
  Popover,
  Tag,
} from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useQuery, useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import authFunc from "../../helper/auth";
import {
  getCompensationSettings,
  removeCompensationSetting,
  pageSize,
  editCompensationSettingStatus,
} from "../../api";
import { useCompensationSettings } from "./store";
import { useSearchParams } from "react-router-dom";

const { Text } = Typography;

export const PuerTable = () => {
  const {
    setId,
    setIsCreateModal,
    setType,
    setPaddingTime,
    setVoucherSettingsID,
    setFilterIDs,
    page,
    setPage,
    typeFilter,
  } = useCompensationSettings();

  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedID, setSelectedID] = useState(null);

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const handleEdit = (row) => {
    setId(row.id);
    setType(row.type);
    setPaddingTime(row.paddingTime);
    setVoucherSettingsID(row.voucherSettingsID);
    setFilterIDs(row.filterIDs);
    setIsCreateModal(true);
  };

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editCompensationSettingStatus,
    {
      onSuccess: (data) => {
        if (data.success) {
          setSelectedID(null);
          message.success(data.msg);
          queryClient.invalidateQueries("compensation");
        } else {
          setSelectedID(null);
          message.error(data.err);
        }
      },
      onError: (e) => {
        setSelectedID(null);
        message.error(e.message);
      },
    }
  );

  const handleChangeStatus = (row) => {
    setSelectedID(row.id);
    editMutate({
      id: row.id,
      status: !row.status,
    });
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      width: 50,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text) => <Text style={{ fontWeight: "bold" }}>{text}</Text>,
    },
    {
      title: "Padding Time",
      dataIndex: "paddingTime",
      key: "paddingTime",
    },
    {
      title: "Voucher Settings Name",
      dataIndex: "voucherSettingsName",
      key: "voucherSettingsName",
    },
    {
      title: "Filters",
      dataIndex: "filters",
      key: "filters",
      width: 600,
      ellipsis: true,
      render: (filters) => {
        console.log(filters);
        return (
          <Popover
            content={filters?.map((el, index) => (
              <Tag key={index}>{el.title}</Tag>
            ))}
          >
            {filters?.map((el, index) => (
              <Tag key={index}>{el.title}</Tag>
            ))}
          </Popover>
        );
      },
    },
    {
      title: "Status",
      key: "id",
      render: (row) => (
        <Switch
          // disabled={authFunc("COMPENSATION_UPDATE") ? false : true}
          loading={selectedID === row.id && editLoading}
          checked={row?.status && row?.status === true ? true : false}
          onChange={() => handleChangeStatus(row)}
        />
      ),
    },
    {
      title: "Actions",
      key: "id",
      dataIndex: "id",
      render: (id, row) => (
        <Space>
          {/* {authFunc("COMPENSATION_UPDATE") && ( */}
          <Button
            onClick={() => handleEdit(row)}
            size="small"
            icon={<EditOutlined />}
          />
          {/* // )} */}
          {/* {authFunc("COMPENSATION_DELETE") && ( */}
          <Popconfirm
            title="Are you sure to delete this task?"
            onConfirm={() => mutate(id)}
            //onCancel={cancel}
            disabled={row.mandatory == "true" ? true : false}
            okText="Yes"
            cancelText="No"
          >
            <Button
              disabled={row.mandatory == "true" ? true : false}
              size="small"
              danger
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
          {/* )} */}
        </Space>
      ),
    },
  ];

  const { mutate } = useMutation(removeCompensationSetting, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        queryClient.invalidateQueries("compensation");
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(["compensation", page, typeFilter], getCompensationSettings, {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  if (error || (!data?.success && data?.data?.err))
    return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />
    );

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data && data?.data?.records ? data?.data?.records : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        defaultPageSize={pageSize}
        total={data && data?.data?.total && data?.data?.total}
        showSizeChanger={false}
      />
    </>
  );
};
