import create from "zustand";

export const useProductGroup = create((set) => ({
  id: null,
  name: null,
  isCreateModal: false,
  queryKey: "",
  priority: null,
  queryFilter: "",
  page: 1,
  internal_name: null,
  internal_product_groups_id: null,
  setInternalProductId: (internal_product_groups_id) => set({ internal_product_groups_id }),

  setInternalName: (internal_name) => set({ internal_name }),
  setQueryFilter: (queryFilter) => set({ queryFilter }),
  setQueryKey: (queryKey) => set({ queryKey }),
  setPriority: (priority) => set({ priority }),
  setName: (name) => set({ name }),
  setId: (id) => set({ id }),
  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
  setPage: (page) => set({ page }),
}));
