import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  message,
  DatePicker,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useProduct } from "./store";
import { editProductDisable } from "../../api";
import { useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import { useEffect } from "react";
import dayjs from "dayjs";
import moment from "moment";

const { Title } = Typography;
export const DisableTime = () => {
  const {
    isDisableModal,
    setIsDisableModal,
    endTime,
    startTime,
    setEndTime,
    id,
    disableRule,
  } = useProduct();
  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation(editProductDisable, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        setIsDisableModal(false);
        queryClient.invalidateQueries("productsDisable");
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const onFinish = () => {
    let disableValues = {
      id,
      endTime: dayjs(endTime).toISOString(),
      startTime: dayjs(startTime).toISOString(),
      disableRule,
    };
    if (id) mutate(disableValues);
  };

  useEffect(() => {
    if (id) {
      form.setFieldsValue({
        disableRule,
        endTime: endTime ? moment(endTime) : null,
        startTime: startTime ? moment(startTime) : null,
      });
    }
  }, [id]);

  return (
    <Modal
      forceRender
      title={<Title level={5}>Product Disable Time </Title>}
      className="app-modal"
      centered
      visible={isDisableModal}
      onCancel={() => {
        setIsDisableModal(false);
      }}
      footer={false}
      width="80%"
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        form={form}
        onFinish={onFinish}
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          {/* <Col span={12}>
                        <Form.Item
                            label='Start Time'
                            name='startTime'
                            rules={[{required:"true", message:"Start time is required"}]}
                        >
                            <DatePicker
                                style={{ width: "100%" }}
                                // value={startTime}
                                onChange={(v) => setStartTime(v)}
                                showTime
                            />
                        </Form.Item>
                    </Col> */}
          <Col span={24}>
            <Form.Item
              label="Select Time"
              name="endTime"
              rules={[{ required: "true", message: "End time is required" }]}
            >
              <DatePicker
                style={{ width: "100%" }}
                // value={endTime}
                onChange={(v) => setEndTime(v)}
                showTime
              />
            </Form.Item>
          </Col>
          <Col span={18}>
            <Form.Item>
              <Button
                loading={isLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
