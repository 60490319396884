import create from "zustand";

export const useReviews = create((set) => ({
  queryKey: "",
  phoneSearch: "",

  //biker review
  bikerRate: "",
  bikerPage: 1,
  setBikerRate: (bikerRate) => set({ bikerRate }),
  setBikerPage: (bikerPage) => set({ bikerPage }),

  //products review
  productsStatus: null,
  productsPage: 1,
  setProductsPage: (productsPage) => set({ productsPage }),
  setProductsStatus: (productsStatus) => set({ productsStatus }),

  //restaurants review
  resturantsStatus: null,
  resturantsPage: 1,
  setResturantsPage: (resturantsPage) => set({ resturantsPage }),
  setResturantsStatus: (resturantsStatus) => set({ resturantsStatus }),
  setPhoneSearch: (phoneSearch) => set({ phoneSearch }),

  setQueryKey: (queryKey) => set({ queryKey }),
}));
