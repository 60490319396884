import React from "react";
import { Select, Spin } from "antd";
import { useBikerDispatch } from "./store";
import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { getRegions } from "../../api";
import { useSearchParams } from "react-router-dom";

const { Option } = Select;

export default function VendorFilters() {
  const { setPage, cityIdFilter, setCityIdFilter, } = useBikerDispatch();
  
  const [searchParams, setSearchParams] = useSearchParams();
  const [citySearch, setCitySearch] = useState("");

  useEffect(() => {
    let obj = {
      cityIdFilter: Number(searchParams.get("cityID")) || null,
   
      page_num: searchParams.get("page_num") || null,
    };

    setCityIdFilter(obj.cityIdFilter);

  }, [
    searchParams.get("cityID"),

    searchParams.get("page_num"),
  ]);

  const {
    isLoading: isCityLoading,
    data: cities,
    refetch: citiesRefetch,
  } = useQuery(["regions", "", citySearch, 1], getRegions, {
    enabled: !!citySearch,
    retry: 2,
    refetchOnWindowFocus: false,
  });

  return (
    <div className="filter-wrapper">
      <div className="item">
      <Select
        filterOption={false}
        dropdownMatchSelectWidth={false}
        notFoundContent={
          isCityLoading ? (
            <div className="spin-wrapper">
              <Spin size="small" />
            </div>
          ) : null
        }
        allowClear
        showSearch
        value={cityIdFilter}
        onChange={(val) => {
          if (val !== undefined) {
            setCityIdFilter(val);
            searchParams.set("cityID", val);
            searchParams.set("page_num", 1);
            searchParams.delete('q')
            setSearchParams(searchParams);
          }
        }}
        onClear={() => {
          setCityIdFilter(null);
          searchParams.delete("cityID");
          searchParams.set("page_num", 1);
          setSearchParams(searchParams);
        }}
        onSearch={(val) => setCitySearch(val)}
        onClick={() => citiesRefetch()}
        placeholder="Select City"
        style={{ width: "100%" }}
      >
        {cities &&
          cities.success &&
          cities.data.records.map((el) => (
            <Option key={el.id} value={el.id}>
              {el.name}
            </Option>
          ))}
      </Select>
      </div>
    </div>
  );
}
