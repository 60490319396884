import create from "zustand";

export const useDistanceSettings = create((set) => ({
  isCreateModal: false,
  id: null,
  classes: [],
  name: null,
  isAddBanners: false,
  selectedBanners: [],

  setId: (id) => set({ id }),
  setClasses: (classes) => set({ classes }),
  setSelectedBanners: (selectedBanners) => set({ selectedBanners }),
  setIsAddBanners: (isAddBanners) => set({ isAddBanners }),
  setName: (name) => set({ name }),
  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
}));
