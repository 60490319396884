import { Alert, Pagination, Popover, Table } from "antd";
import { useQuery } from "react-query";
import { getVendorPerformanceScoring, pageSize } from "../../api";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useVendorPerformanceScore } from "./store";

export const PureTable = () => {
  const { vendorID, cityID, from, to } = useVendorPerformanceScore();
  const [page, setPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.get("page_num")]);

  const {
    isLoading: loading,
    isFetching: fetching,
    error,
    data,
  } = useQuery(
    [
      "performanceScore",
      page,
      vendorID,
      cityID,
      from,
      to,
      searchParams.get("page_size"),
    ],
    getVendorPerformanceScoring,
    {
      refetchOnWindowFocus: false,
      retry: 2,
      enabled: !!from && !!to,
    }
  );

  const metrics = data ? data?.data?.records?.[0] : [];

  const metricColumns = () => {
    return metrics?.scores
      ?.sort((a, b) => a.metric_name.localeCompare(b.metric_name))
      .map((metric) => {
        return {
          title: () => (
            <Popover
              title={metric.negative ? "Negative Metric" : "Positive Metric"}
              content={
                metric.negative
                  ? `
                  The lower the score, the better the performance.
                `
                  : `
                  The higher the score, the better the performance.
                `
              }
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 5,
                }}
              >
                <div
                  style={{
                    width: 10,
                    height: 10,
                    backgroundColor: metric.negative ? "#FF0000" : "#00FF00",
                    borderRadius: 50,
                  }}
                ></div>
                <div
                  id={metric.metric_name}
                  style={{
                    fontWeight: "bold",
                    color: "#000000",
                  }}
                >
                  {metric.metric_name.replace(/_/g, " ").toUpperCase()}
                </div>
              </div>
            </Popover>
          ),
          dataIndex: metric.metric_name,
          key: metric.metric_name,
          colSpan: 4,
          className: "metric-column",

          children: [
            {
              title: "Rank",
              dataIndex: "rank",
              key: "rank",
              render: (_, record) => {
                return (
                  record.scores
                    .find((m) => m.metric_name === metric.metric_name)
                    ?.rank?.toLocaleString() || 0
                );
              },
              width: 200,
            },
            {
              title: "Raw Score",
              dataIndex: "raw_score",
              key: "raw_score",
              render: (_, record) => {
                return (
                  parseFloat(
                    record.scores
                      .find((m) => m.metric_name === metric.metric_name)
                      ?.raw_score?.toFixed(1)
                  )?.toLocaleString() || 0
                );
              },
              width: 200,
            },
            {
              title: "Metric Score",
              dataIndex: "metric_score",
              key: "metric_score",
              width: 200,
              render: (_, record) => {
                return (
                  parseFloat(
                    record.scores
                      .find((m) => m.metric_name === metric.metric_name)
                      ?.metric_score?.toFixed(1)
                  )?.toLocaleString() || 0
                );
              },
            },
            {
              title: "Weighted Score",
              dataIndex: "weighted_score",
              key: "weighted_score",
              width: 200,
              render: (_, record) => {
                return (
                  parseFloat(
                    record.scores
                      .find((m) => m.metric_name === metric.metric_name)
                      ?.weighted_score?.toFixed(1)
                  )?.toLocaleString() || 0
                );
              },
            },
          ],
        };
      });
  };

  const columns = [
    {
      title: "Vendor ID",
      dataIndex: "vendorid",
      key: "vendorid",
      colSpan: 1,
      fixed: "left",
      width: 100,
    },
    {
      title: "Vendor",
      dataIndex: "vendor",
      key: "vendor",
      colSpan: 1,
      fixed: "left",
      width: 120,
    },
    {
      title: "Metrics",

      children: metricColumns(),
    },
    {
      title: "Final Score",
      dataIndex: "finalscore",
      key: "finalscore",
      colSpan: 1,
      fixed: "right",
      width: 100,
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      colSpan: 1,
      fixed: "right",
      width: 100,
      render: (text) => {
        return new Date(text).toLocaleString();
      },
    },
  ];

  if (error || (!data?.success && data?.data?.err))
    return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />
    );

  return (
    <>
      <Table
        loading={{
          spinning: loading || fetching,
          indicator: "Loading...",
          style: {
            width: "100%",
            minHeight: window.innerHeight - 450,
          },
        }}
        style={{
          width: "100%",
          height: window.innerHeight - 325,
        }}
        columns={columns}
        dataSource={data ? data?.data?.records : []}
        size="small"
        rowKey={(record) => record.id}
        scroll={{ x: 1300, y: window.innerHeight - 450 }}
        bordered
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        defaultPageSize={pageSize}
        total={data?.data?.total || 0}
        showSizeChanger={true}
        pageSizeOptions={["10", "15", "20", "25", "50", "100", "200"]}
        showTotal={() => {
          const totalRecords = data?.data?.total || 0;
          const pageSize = searchParams.get("page_size") || 10;
          const current = searchParams.get("page_num") || 1;
          const start = pageSize * (current - 1) + 1;
          const end = Math.min(pageSize * current, totalRecords);
          return `Showing ${start} to ${end} of ${totalRecords} entries`;
        }}
        onShowSizeChange={(current, size) => {
          searchParams.set("page_num", current);
          searchParams.set("page_size", size);
          setSearchParams(searchParams);
          setPage(current);
        }}
      />
    </>
  );
};
