import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  message,
  InputNumber,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import Input from "antd/lib/input/Input";
import { useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import { createTrendingVendorPage, editTrendingVendorPage } from "../../api";
import { useInsideVendor } from "./store";
import { useEffect } from "react";

const { Title } = Typography;

export const PureCreate = () => {
  const {
    isCreateModal,
    filterData,
    setIsVendorModal,
    setIsInternalModal,
    setIsCreateModal,
    setFilterData,
  } = useInsideVendor();
  const [form] = Form.useForm();

  const resetState = () => {
    setFilterData(null)
    setIsVendorModal(false)
    setIsInternalModal(false)
    setIsCreateModal(false)
  };

  const { mutate, isLoading } = useMutation(createTrendingVendorPage, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);

        queryClient.invalidateQueries("productTrendingCarousels");
        resetState();
        setIsCreateModal(false);
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });
  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editTrendingVendorPage,
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          queryClient.invalidateQueries("productTrendingCarousels");
          resetState();
          setIsCreateModal(false);
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );



  const onFinish = (values) => {
    if (filterData && filterData.id) {
      values.id = filterData.id
      values.vendors = filterData.vendors
      values.internal_product_groups = filterData.internal_product_groups
      editMutate(values);
    } else {
      values.status = false;
      values.vendors = []
      values.internal_product_groups = []
      mutate(values);
    }
  };

  useEffect(() => {
    if (filterData && filterData.id) {
      form.setFieldsValue({
        name: filterData.name,
        limit: filterData.limit
      });

    } else {
      resetState();
      form.resetFields()
    }
  }, [filterData && filterData.id]);

  return (
    <Modal
      forceRender
      title={<Title level={5}>{filterData && filterData.id ? "Edit" : "New"} Trending Vendor Page</Title>}
      className="app-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetState();
        setIsCreateModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={460}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        form={form}
        initialValues={{ remember: true, }}
        onFinish={onFinish}
        layout="vertical"
      >
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <Form.Item
              label="Trending carousels name"
              name="name"
              rules={[{ required: true }]}
            >
              <Input placeholder="write the name" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Limit"
              name="limit"
              rules={[{ required: true }]}
            >
              <InputNumber placeholder="write the limit" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={isLoading || editLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>

      </Form>
    </Modal>
  );
};
