import {
  Table,
  Tag,
  Space,
  Button,
  Typography,
  Alert,
  message,
  Popconfirm,
  Switch,
  Pagination,
  Tooltip,
  Checkbox,
  Badge,
  Dropdown,
  Card,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  HistoryOutlined,
  MoreOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import { useQuery, useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import { getProducts, removeProduct, editProduct, pageSize } from "../../api";
import { useParams } from "react-router-dom";
import { useProduct } from "./store";
import { useNavigate } from "react-router-dom";
import { ImageTable } from "../../comonents/global";
import { useState, useEffect } from "react";
import authFunc from "../../helper/auth";
import { useSearchParams } from "react-router-dom";

const { Text } = Typography;

export const PureTable = () => {
  const [page, setPage] = useState(1);
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    setIsCreateModal,
    setIsHistoryModal,
    setHistoryData,
    setDescription,
    setId,
    setName,
    setPrice,
    setProductGroupID,
    productGroupIDFilter,
    queryKey,
    setStatus,
    setPreparationTime,
    setPriority,
    setSchedule,
    setStartTime,
    setEndTime,
    setIsDisableModal,
    setIsScheduleModal,
  } = useProduct();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Image",
      key: "id",
      render: (row) => (
        <ImageTable
          logo={false}
          url={row.img}
          id={row.id}
          pathName={"product/img"}
          imgKey={"imgFile"}
          updateStore={"products"}
        />
      ),
    },

    {
      title: "Recipe Name",
      dataIndex: "name",
      key: "name",
      render: (text, row) => <Text style={{ fontWeight: "bold" }}>{text}</Text>,
    },

    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text) => <Text style={{ fontSize: 12 }}>{text}</Text>,
    },

    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text) => (
        <Text style={{ fontSize: 16 }}>
          {Number(text).toLocaleString()} IQD
        </Text>
      ),
    },

    {
      title: "Group Name",
      dataIndex: "productGroup",
      key: "productGroup",
      render: (text) => <Tag color={"geekblue"}>{text}</Tag>,
    },
    {
      title: "Preparation Time",
      dataIndex: "preparationTime",
      key: "preparationTime",
      render: (val) => (val ? <Text>{val} Minute</Text> : "..."),
    },
    {
      title: "Status",
      key: "id",
      render: (row) => (
        <Switch
          disabled={authFunc("PRODUCT_UPDATE") ? false : true}
          loading={editLoading}
          checked={row.status}
          onChange={(state) => handleChangeActive(row)}
        />
      ),
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      render: (rank) => (
        <Tag color="magenta">{Number(rank).toLocaleString()}</Tag>
      ),
    },
    {
      title: "Action",
      key: "id",
      render: (row) => (
        <Dropdown
          overlay={
            <Card bodyStyle={{ padding: 0 }}>
              <div
                onClick={() =>
                  authFunc("TOPPINGS_GROUP_READ") &&
                  navigate(
                    `/resturants/${id}/products/${row.id}/SubProductGroup`
                  )
                }
                className="resturant-action-item"
              >
                <Space>
                  <Tooltip
                    placement="top"
                    title="view topping for this product"
                  >
                    <Button
                      size="small"
                      type="link"
                      disabled={!authFunc("TOPPINGS_GROUP_READ")}
                    >
                      <Badge
                        color={row.subProductGroupCount ? "green" : "gray"}
                        text={`Topping Group ${row?.subProductGroupCount || 0}`}
                      />
                    </Button>
                  </Tooltip>
                </Space>
              </div>

              <div className="resturant-action-item">
                <Tooltip placement="top" title="Show Actions History">
                  <Button
                    onClick={() => handleHistory(row.statusHistory)}
                    size="small"
                    icon={<HistoryOutlined />}
                    type="text"
                  >
                    History
                  </Button>
                </Tooltip>
              </div>

              {authFunc("PRODUCT_UPDATE") && (
                <div className="resturant-action-item">
                  <Button
                    onClick={() => handleDisableTime(row)}
                    size="small"
                    icon={<ClockCircleOutlined />}
                    type="text"
                  >
                    Start/end time disable
                  </Button>
                </div>
              )}
              {authFunc("PRODUCT_UPDATE") && (
                <div className="resturant-action-item">
                  <Button
                    onClick={() => handleSchedule(row)}
                    size="small"
                    icon={<CalendarOutlined />}
                    type="text"
                  >
                    Product Schedule
                  </Button>
                </div>
              )}
              {authFunc("PRODUCT_UPDATE") && (
                <div className="resturant-action-item">
                  <Button
                    onClick={() => handleEdit(row)}
                    size="small"
                    icon={<EditOutlined />}
                    type="text"
                  >
                    Edit Products
                  </Button>
                </div>
              )}
              {authFunc("PRODUCT_DELETE") && (
                <div className="resturant-action-item resturant-action-item-danger">
                  <Popconfirm
                    title={
                      <div>
                        Are you sure to delete this task? <br />
                        <Checkbox
                          onChange={(val) => {
                            row.sync = val.target.checked;
                          }}
                        >
                          is sync ?
                        </Checkbox>
                      </div>
                    }
                    onConfirm={() =>
                      mutate({
                        id: row.id,
                        vendorID: Number(id),
                        sync: row.sync ? true : false,
                      })
                    }
                    //onCancel={cancel}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      size="small"
                      danger
                      icon={<DeleteOutlined />}
                      type="text"
                    >
                      Delete Product
                    </Button>
                  </Popconfirm>
                </div>
              )}
            </Card>
          }
          placement="bottomRight"
        >
          <Button
            style={{ fontSize: 20 }}
            type="text"
            icon={<MoreOutlined />}
          />
        </Dropdown>
      ),
    },
  ];

  const handleHistory = (values) => {
    setHistoryData(values);
    setIsHistoryModal(true);
  };
  const handleDisableTime = (values) => {
    setId(values.id);
    setStartTime(values.startTime);
    setEndTime(values.endTime);
    setIsDisableModal(true);
  };
  const handleSchedule = (values) => {
    setId(values.id);
    setSchedule(values.schedule === "{}" ? null : JSON.parse(values.schedule));
    setIsScheduleModal(true);
  };
  const handleEdit = (row) => {
    setDescription(row.description);
    setId(row.id);
    setName(row.name);
    setPrice(row.price);
    setProductGroupID(row.productGroupID);
    setStatus(row.status);
    setIsCreateModal(true);
    setPreparationTime(row.preparationTime);
    setPriority(row.priority);
    setStartTime(row.startTime);
    setEndTime(row.endTime);
    setSchedule(row.schedule === "{}" ? null : JSON.parse(row.schedule));
  };

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editProduct,
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          queryClient.invalidateQueries("products");
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const handleChangeActive = (row) => {
    {
      row.status = !row.status;
      editMutate(row);
    }
  };

  const { mutate } = useMutation(removeProduct, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        queryClient.invalidateQueries("products");
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(
    ["products", id, queryKey, page, productGroupIDFilter],
    getProducts,
    {
      refetchOnWindowFocus: false,
      retry: 2,
      enabled: !!id,
    }
  );

  if (error || (!data?.success && data?.data?.err))
    return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />
    );

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data ? data.data.records : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        defaultPageSize={pageSize}
        total={data && data.data.total}
        showSizeChanger={false}
      />
    </>
  );
};
