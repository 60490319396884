import { Card, Input, Space, Button, Breadcrumb } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { PureGrid } from "../comonents/media";

const { Search } = Input;

export const MediaPage = () => {
  return (
    <div className="app-page">
      <Breadcrumb style={{ marginBottom: 10 }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>

        <Breadcrumb.Item>Settings</Breadcrumb.Item>
        <Breadcrumb.Item>Media</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <div className="app-space-between">
          <Space>
            <Search style={{ width: 340 }} placeholder="Write To Search..." />
            {/* <Button icon={<FilterOutlined />} /> */}
          </Space>
          <Space>
            <Button type="primary" icon={<UploadOutlined />}>
              Upload Image
            </Button>
          </Space>
        </div>
        <br />
        <PureGrid />
      </Card>
    </div>
  );
};
