import { useEffect } from "react";
import {
  Table,
  message,
  Space,
  Button,
  Typography,
  Alert,
  Pagination,
  Tag,
} from "antd";
import { useQuery, useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import { getReviews, editReviews, pageSize } from "../../api";
import { useReviews } from "../../store";
import { Rating } from "react-simple-star-rating";
import authFunc from "../../helper/auth";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

const { Text } = Typography;

export const PureTable = () => {
  const {
    queryKey,
    phoneSearch,
    resturantsStatus,
    setResturantsPage,
    resturantsPage,
  } = useReviews();

  const [searchParams, setSearchParams] = useSearchParams();

  const statusColor = {
    ACCEPTED: "success",
    REJECTED: "error",
    PENDING: "orange",
  };

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setResturantsPage(searchParams.get("page_num"));
    } else {
      setResturantsPage(1);
    }
  }, [searchParams.get("page_num")]);

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(
    [
      "resturantReveiw",
      "vendorreview",
      queryKey,
      resturantsPage,
      "",
      phoneSearch,
      resturantsStatus,
    ],
    getReviews,
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );

  const { mutate, isLoading: editLoading } = useMutation(
    (val) => editReviews("vendorreview", val),
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          queryClient.invalidateQueries("resturantReveiw");
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const handleEdit = (row, status) => {
    let formData = {
      id: row.id,
      vendorID: row.vendorID,
      status,
    };
    mutate(formData);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Restaurant Name",
      dataIndex: "vendor",
      key: "vendor",
      render: (name) => <Text style={{ fontWeight: "bold" }}>{name}</Text>,
    },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
      render: (value, row) => (
        <Rating
          allowHalfIcon={true}
          transition={true}
          rtl={true}
          size={18}
          readonly
          emptyColor="#ddd"
          initialValue={value}
        />
      ),
    },
    {
      title: "Customer Name",
      dataIndex: "client",
      key: "client",
      render: (text, row) =>
        text ? (
          <Text style={{ fontWeight: "bold" }}>{text}</Text>
        ) : (
          <small>No Customer yet..</small>
        ),
    },
    {
      title: "Customer reviews",
      dataIndex: "comment",
      key: "comment",
      render: (text) => <Text style={{ fontSize: 12 }}>{text}</Text>,
    },
    {
      title: "Order ID",
      dataIndex: "orderID",
      key: "orderID",
    },
    {
      title: "Creation Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => (
        <Text type="secondary">{moment(date).format("DD/ MM/ YYYY")}</Text>
      ),
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      render: (reason) => {
        let reasons = JSON.parse(reason);
        return (
          <Text style={{ fontSize: 12 }}>
            {reasons.length > 0
              ? reasons.map((el, index) => (
                  <Tag color="geekblue" key={index}>
                    {el?.title}
                  </Tag>
                ))
              : "...."}
          </Text>
        );
      },
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (val) => <Tag color={statusColor[val]}>{val}</Tag>,
    },

    {
      title: "Action",
      key: "id",
      render: (row) =>
        row.status == "PENDING"
          ? authFunc("VENDOR_REVIEW_READ") && (
              <Space>
                <Button
                  loading={editLoading}
                  onClick={() => handleEdit(row, "ACCEPTED")}
                  type="primary"
                  size="small"
                  color="red"
                >
                  Accept
                </Button>
                <Button
                  loading={editLoading}
                  onClick={() => handleEdit(row, "REJECTED")}
                  danger
                  type="primary"
                  size="small"
                >
                  Reject
                </Button>
              </Space>
            )
          : row.status == "REJECTED"
          ? authFunc("VENDOR_REVIEW_READ") && (
              <Button
                loading={editLoading}
                onClick={() => handleEdit(row, "ACCEPTED")}
                type="primary"
                size="small"
              >
                Accept
              </Button>
            )
          : authFunc("VENDOR_REVIEW_READ") && (
              <Button
                loading={editLoading}
                onClick={() => handleEdit(row, "REJECTED")}
                danger
                type="primary"
                size="small"
              >
                Reject
              </Button>
            ),
    },
  ];

  if (error || (!data?.success && data?.data?.err))
    return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />
    );

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data ? data.data.records : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setResturantsPage(val);
        }}
        current={Number(searchParams.get("page_num")) || resturantsPage}
        defaultPageSize={pageSize}
        total={data && data.data.total}
        showSizeChanger={false}
      />
    </>
  );
};
