import { useEffect, useState } from "react";
import {
  Table,
  Space,
  Button,
  Typography,
  Alert,
  message,
  Popconfirm,
  Pagination,
  Switch,
  Tooltip,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  HistoryOutlined,

} from "@ant-design/icons";
import { useMutation, useQuery } from "react-query";
import { queryClient } from "../../queryClient";
import { getTrendingVendorPage, removeTrendingVendorPage, pageSize, editTrendingVendorPage, editTrendingVendorPageStatus } from "../../api";
import { useInsideVendor } from "./store";
import authFunc from "../../helper/auth";
import { useSearchParams } from "react-router-dom";


export const PureTable = () => {
  const {
    isCreateModal,
    isVendorModal,
    isInternalModal,
    filterData,
    setIsVendorModal,
    setIsInternalModal,
    setIsCreateModal,
    setFilterData,
    queryKey
  } = useInsideVendor();
  const [page, setPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const handleEditVendors = (row) => {
    setFilterData(row)
    setIsVendorModal(true)
    setIsInternalModal(false)
    setIsCreateModal(false)
  };

  const handleEditInternalCategory = (row) => {
    setFilterData(row)
    setIsVendorModal(false)
    setIsInternalModal(true)
    setIsCreateModal(false)
  };
  const handleEdit = (row) => {
    setFilterData(row)
    setIsVendorModal(false)
    setIsInternalModal(false)
    setIsCreateModal(true)
  };



  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      width: 200

    },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 200
    },
    {
      title: "Limit",
      dataIndex: "limit",
      key: "limit",
      width: 200

    },


    {
      title: "Status",
      key: "id",
      render: (row) => (
        <Switch
          disabled={authFunc("PRODUCT_CAROUSEL_CREATE") ? false : true}
          loading={editLoading}
          checked={row.status}
          onChange={() => handleChangeActive(row)}
        />
      ),
    },

    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      width: 200,
      render: (id, row) => (
        <Space size="small">
          <Button
            onClick={() => handleEdit(row)}
            size="small"
            icon={<EditOutlined />}
            disabled={!authFunc("PRODUCT_CAROUSEL_CREATE")}
          />
          <Tooltip placement="top" title="Add Vendors">
            <Button
              onClick={() => handleEditVendors(row)}
              size="small"
              disabled={!authFunc("PRODUCT_CAROUSEL_CREATE")}
            >
              Vendors
            </Button>
          </Tooltip>
          <Tooltip placement="top" title="Add Internal Product Category">
            <Button
              onClick={() => handleEditInternalCategory(row)}
              size="small"
              disabled={!authFunc("PRODUCT_CAROUSEL_CREATE")}
            >
              Product Category
            </Button>
          </Tooltip>
          <Popconfirm
            title="Are you sure to delete this task?"
            onConfirm={() => mutate(id)}
            okText="Yes"
            cancelText="No"
          >
            <Button size="small" danger icon={<DeleteOutlined />}
              disabled={!authFunc("PRODUCT_CAROUSEL_CREATE")}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const { mutate } = useMutation((val) => removeTrendingVendorPage(val), {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        queryClient.invalidateQueries("productTrendingCarousels");
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(["productTrendingCarousels", queryKey, page,], getTrendingVendorPage, {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editTrendingVendorPageStatus,
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          queryClient.invalidateQueries("productTrendingCarousels");
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const handleChangeActive = (row) => {

    row.status = !row.status;

    editMutate({ id: row.id, status: row.status });
  };

  if (error || (!data?.success && data?.data?.err))
    return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />
    );

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data ? data.data.records : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        defaultPageSize={pageSize}
        total={data && data.data.total}
        showSizeChanger={false}
      />
    </>
  );
};
