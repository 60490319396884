import create from "zustand";

export const useWeightsLogs = create((set) => ({
  page: 1,
  historyData: null,
  isHistoryModal: false,

  setPage: (page) => set({ page }),
  setHistoryData: (historyData) => set({ historyData }),
  setIsHistoryModal: (isHistoryModal) => set({ isHistoryModal }),
}));
