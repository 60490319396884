import create from "zustand";

export const useSubProduct = create((set) => ({
  id: null,
  name: null,
  endTime: null,
  productGroupID: null,
  price: null,
  description: null,
  historyData: null,
  isCreateModal: false,
  isHistoryModal: false,
  queryKey: "",
  status: false,
  priority:null,

  
  setQueryKey: (queryKey) => set({ queryKey }),

  setId: (id) => set({ id }),
  setName: (name) => set({ name }),
  setStatus: (status) => set({ status }),
  setProductGroupID: (productGroupID) => set({ productGroupID }),
  setPrice: (price) => set({ price }),
  setPriority: (priority) => set({ priority }),
  setDescription: (description) => set({ description }),
  setHistoryData: (historyData) => set({ historyData }),
  setEndTime: (endTime) => set({ endTime }),
  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
  setIsHistoryModal: (isHistoryModal) => set({ isHistoryModal }),
}));
