import { useState } from "react";
import {
  Button,
  Typography,
  Modal,
  Row,
  Col,
  message,
  Space,
  Input,
  Popover,
} from "antd";
import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
import { useCompoundVoucher } from "../store";
import { useMutation } from "react-query";
import { queryClient } from "../../../queryClient";
import { AddToVoucher } from "../../../api";
import readXlsxFile from "read-excel-file";
import { fileDialog } from "file-select-dialog";
import { VendorTable } from "./vendorTable";
import { PureGetVendors } from "./getVendrs";

const { Title, Text } = Typography;
const { Search } = Input;

export const PureAddVendores = () => {
  const { isVendorsModal, setIsVendorsModal, id, setId } = useCompoundVoucher();
  const [filter, setFilter] = useState("");

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    (values) => AddToVoucher("vendor", values),
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          queryClient.invalidateQueries("vouchers");
          queryClient.invalidateQueries("vendorsVoucher");
        } else {
          message.error(data.err);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const openFile = async () => {
    const fileList = await fileDialog({ accept: ".xlsx" });
    readXlsxFile(fileList[0]).then((rows) => {
      let indexID;

      rows.map((el, index) => {
        let isID = el.findIndex((el2) => el2 == "Id");
        if (isID != -1) {
          indexID = [isID, index];
        }
      });

      let vendorIDs = [];
      rows.map((el, index) => {
        if (index > indexID[1]) {
          vendorIDs.push(el[indexID[0]]);
        }
      });
      let uniqueVendorIDs = vendorIDs.filter(
        (item, i, arr) => arr.indexOf(item) === i
      );
      let dataForm = {
        id,
        vendorID: uniqueVendorIDs,
      };
      editMutate(dataForm);
    });
  };

  return (
    <Modal
      title={<Title level={5}>Add Resturant To Voucher</Title>}
      className="app-modal"
      centered
      visible={isVendorsModal}
      onCancel={() => {
        setId(null);
        setIsVendorsModal(false);
        queryClient.resetQueries("voucherOne");
      }}
      footer={false}
      maskClosable={false}
      destroyOnClose={true}
      width={"80%"}
    >
      <Row gutter={[10, 10]}>
        <Col flex={"100%"}>
          <div className="app-space-between">
            <Space>
              <Search
                onSearch={(val) => setFilter(val)}
                style={{ width: 340 }}
                placeholder="Write To Search..."
              />
            </Space>
            <Space>
              <Popover placement="rightTop" content={<PureGetVendors />}>
                <Button type="primary" icon={<SearchOutlined />}>
                  Get Vondors
                </Button>
              </Popover>
              <Text>OR</Text>
              <Button
                onClick={openFile}
                type="primary"
                icon={<DownloadOutlined />}
                loading={editLoading}
              >
                Import excel file
              </Button>
            </Space>
          </div>
        </Col>
        <Col span={24}></Col>
        <Col span={24}>
          <VendorTable id={id} filter={filter} />
        </Col>
      </Row>
    </Modal>
  );
};
