import create from "zustand";

export const useCompoundVoucher = create((set) => ({
  isCreateModal: false,
  queryKey: "",
  active: true,
  userIDs: null,
  cityIDs: null,
  BusinessCategoryIDs: null,
  vendorIDs: null,
  UDID: true,
  id: null,
  schedule: null,

  // filters
  active: null,
  from: null,
  to: null,

  isUsersModal: false,
  isVendorsModal: false,
  isCitiesModal: false,
  isCategoriesModal: false,
  page: 1,

  setActive: (active) => set({ active }),
  setFrom: (from) => set({ from }),
  setTo: (to) => set({ to }),
  setIsUsersModal: (isUsersModal) => set({ isUsersModal }),
  setIsVendorsModal: (isVendorsModal) => set({ isVendorsModal }),
  setIsCitiesModal: (isCitiesModal) => set({ isCitiesModal }),
  setIsCategoriesModal: (isCategoriesModal) => set({ isCategoriesModal }),

  setSchedule: (schedule) => set({ schedule }),
  setUserIDs: (userIDs) => set({ userIDs }),
  setCityIDs: (cityIDs) => set({ cityIDs }),
  setBusinessCategoryIDs: (BusinessCategoryIDs) => set({ BusinessCategoryIDs }),
  setVendorIDs: (vendorIDs) => set({ vendorIDs }),
  setId: (id) => set({ id }),
  setQueryKey: (queryKey) => set({ queryKey }),
  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
  setPage: (page) => set({ page }),
}));
