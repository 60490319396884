import create from "zustand";

export const useBikerReview = create((set) => ({
  queryKey: "",
  bikerRate: "",
  bikerPage: 1,
  setBikerRate: (bikerRate) => set({ bikerRate }),
  setBikerPage: (bikerPage) => set({ bikerPage }),
  setQueryKey: (queryKey) => set({ queryKey }),
}));
