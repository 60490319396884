import { useState, useEffect } from "react";
import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  message,
  Select,
  Spin,
  Tag,
  Space,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useCompoundVoucher } from "../store";
import { useMutation, useQuery } from "react-query";
import { queryClient } from "../../../queryClient";
import { AddToVoucher, getCategories, getVoucher } from "../../../api";

const { Title } = Typography;
const { Option } = Select;

export const PureAddCategories = () => {
  const { isCategoriesModal, setIsCategoriesModal, id, setId } =
    useCompoundVoucher();
  const [filter, setFilter] = useState("");
  const [selected, setSelected] = useState(null);
  const [list, setList] = useState([]);

  const {
    isLoading: clientLoading,
    data: clients,
    refetch,
  } = useQuery(["categories", filter], getCategories, {
    refetchOnWindowFocus: false,
    retry: 2,
    enabled: !!isCategoriesModal,
  });

  const {
    isLoading: getVoucherLoading,
    data: voucherOne,
    refetch: VoucherRefetch,
  } = useQuery("voucherOne", () => getVoucher(id), {
    retry: 2,
    refetchOnWindowFocus: false,
    enabled: false,
  });

  useEffect(() => {
    if (id && isCategoriesModal) {
      refetch();
      VoucherRefetch();
    }
  }, [id]);
  useEffect(() => {
    if (id && isCategoriesModal) {
      VoucherRefetch();
    }
  }, []);

  useEffect(() => {
    if (voucherOne && voucherOne.success) {
      setList(voucherOne.data.BusinessCategories || []);
    }
  }, [voucherOne]);

  useEffect(() => {
    if (filter) {
      refetch();
    }
  }, [filter]);

  const isRepeated = (id) => (list.find((el) => el.id == id) ? true : false);

  useEffect(() => {
    if (selected && clients?.success) {
      setList([
        ...list,
        clients?.data?.records?.find((el) => el.id == selected),
      ]);
      setSelected(null);
    }
  }, [selected]);

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    (values) => AddToVoucher("businesscategories", values),
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          setIsCategoriesModal(false);
          queryClient.invalidateQueries("vouchers");
          setList([]);
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const onFinish = () => {
    let dataForm = {
      id,
      BusinessCategoryIDs: JSON.stringify(list.map((el) => el.id)),
    };
    editMutate(dataForm);
  };

  return (
    <Modal
      title={<Title level={5}>Add Categories To Voucher</Title>}
      className="app-modal"
      centered
      visible={isCategoriesModal}
      onCancel={() => {
        queryClient.resetQueries("voucherOne");
        setId(null);
        setIsCategoriesModal(false);
        setList([]);
      }}
      footer={false}
      maskClosable={false}
      destroyOnClose={true}
      width={650}
    >
      <Row gutter={[10, 10]}>
        <Col flex={"100%"}>
          <Select
            showSearch
            value={selected}
            onChange={(val) => setSelected(val)}
            filterOption={true}
            optionFilterProp="children"
            onSearch={(val) => setFilter(val)}
            style={{ width: "100%" }}
            placeholder="Find Category"
            allowClear
          >
            {!clientLoading ? (
              clients?.success ? (
                clients?.data?.records?.map((el) => (
                  <Option
                    disabled={isRepeated(el.id)}
                    key={el.id}
                    id={el.id}
                    value={el.id}
                  >
                    {el.name}
                  </Option>
                ))
              ) : null
            ) : (
              <Spin spinning={true} />
            )}
          </Select>
        </Col>
        <Col span={24}></Col>
        <Col span={24}>
          <Spin spinning={getVoucherLoading}>
            <Space wrap>
              {list.length > 0
                ? list.map((el) => (
                    <Tag
                      key={el.id}
                      closable
                      onClose={() => {
                        setList(list.filter((item) => item.id != el.id));
                      }}
                      color="magenta"
                    >
                      {el.name}
                    </Tag>
                  ))
                : null}
            </Space>
          </Spin>
        </Col>
        <Col span={24}>
          <Divider />
          <Form.Item>
            <Button
              loading={editLoading}
              type="primary"
              htmlType="submit"
              icon={<SaveOutlined />}
              onClick={onFinish}
            >
              Save
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Modal>
  );
};
