import { useState, useEffect } from "react";
import {
  Table,
  Button,
  message,
  Alert,
  Space,
  Popconfirm,
  Pagination,
  Tag,
  Tooltip,
} from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useQuery, useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import authFunc from "../../helper/auth";
import { useParams, useSearchParams } from "react-router-dom";
import {
  getWaterfallProcessAction,
  removeWaterfallProcessAction,
} from "../../api";
import { useWaterfallProcessAction } from "./store";
import Text from "antd/lib/typography/Text";

export const PuerTable = () => {
  const [page, setPage] = useState(1);
  const {
    setId,
    setIsCreateModal,
    queryKey,
    setMaxSR,
    setMinSR,
    setBufferTime,
    // setPriority,
    setDelieveryfeeID,
    setType,
    setValue,
    setDelieveryfee,
    setIsDelieveryfeeModal,
    setDelieveryModalData,
    setStatusCodeID,
    setMinActiveOrders,
  } = useWaterfallProcessAction();

  const [searchParams, setSearchParams] = useSearchParams();
  const { id } = useParams();

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const handleEdit = (row) => {
    setId(row.id);
    setMaxSR(row.maxSR);
    setMinSR(row.minSR);
    setBufferTime(row.bufferTime);
    // setPriority(row.priority);
    setStatusCodeID(row.statusCodeID);
    setMinActiveOrders(row.minActiveOrders);
    setDelieveryfeeID(
      row?.steps?.CHANGE_DELIVERY_FEE
        ? JSON.parse(row?.steps?.CHANGE_DELIVERY_FEE).id
        : null
    );
    setDelieveryfee(
      row?.steps?.CHANGE_DELIVERY_FEE
        ? JSON.parse(row?.steps?.CHANGE_DELIVERY_FEE)
        : null
    );
    setValue(row?.steps?.CHANGE_RADIUS ? row?.steps?.CHANGE_RADIUS : null);
    setType(Object.keys(row.steps));
    setIsCreateModal(true);
  };

  const parseJSON = (jsonString) => {
    try {
      return JSON.parse(jsonString);
    } catch (e) {
      return jsonString;
    }
  };

  const handleOpenModal = (data) => {
    setDelieveryModalData(data);
    setIsDelieveryfeeModal(true);
  };

  const columns = [
    {
      title: "Action Type/Value",
      dataIndex: "steps",
      key: "steps",
      render: (steps) =>
        Object.entries(steps).map(([key, value]) => {
          const parsedValue = parseJSON(value);

          if (typeof parsedValue === "object") {
            return (
              <Tooltip title="view details">
                <Tag
                  style={{ cursor: "pointer" }}
                  onClick={() => handleOpenModal(parsedValue)}
                >
                  {key}
                </Tag>
              </Tooltip>
            );
          }

          return <Tag key={key}>{`${key}: ${value}`}</Tag>;
        }),
    },
    // {
    //   title: "Priority",
    //   dataIndex: "priority",
    //   key: "priority",
    // },
    {
      title: "Min Saturation Rate",
      dataIndex: "minSR",
      key: "minSR",
    },
    {
      title: "Max Saturation Rate",
      dataIndex: "maxSR",
      key: "maxSR",
    },
    {
      title: "Buffer Time",
      dataIndex: "bufferTime",
      key: "bufferTime",
    },
    {
      title: "Min Active Orders",
      dataIndex: "minActiveOrders",
      key: "minActiveOrders",
    },
    {
      title: "Actions",
      key: "id",
      dataIndex: "id",
      render: (id, row, index) => (
        <Space>
          {authFunc("WATERFALL_SETTINGS_CREATE") && (
            <Button
              onClick={() => handleEdit(row)}
              size="small"
              icon={<EditOutlined />}
            />
          )}
          {authFunc("WATERFALL_SETTINGS_CREATE") && (
            <Popconfirm
              title="Are you sure to delete this task?"
              onConfirm={() => removeMutate({ id: id })}
              okText="Yes"
              cancelText="No"
            >
              <Button size="small" danger icon={<DeleteOutlined />} />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  const { mutate: removeMutate } = useMutation(
    (e) => removeWaterfallProcessAction(id, e),
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          queryClient.invalidateQueries("waterfallProcessAction");
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(
    ["waterfallProcessAction", id, page, queryKey],
    getWaterfallProcessAction,
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );

  if (error || (!data?.success && data?.data?.err))
    return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />
    );

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data ? data?.data : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        total={data && data?.data?.total}
        showSizeChanger={false}
      />
    </>
  );
};
