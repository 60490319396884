import { useEffect } from "react";
import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  message,
  Upload,
} from "antd";
import {
  SaveOutlined,
  SmileOutlined,
  PictureOutlined,
} from "@ant-design/icons";
import Input from "antd/lib/input/Input";
import { useChain } from "./store";
import TextArea from "antd/lib/input/TextArea";
import { useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import { createChain, editChain } from "../../api";

const { Title } = Typography;
const { Dragger } = Upload;

export const PureCreate = () => {
  const {
    isCreateModal,
    setIsCreateModal,
    id,
    arName,
    description,
    setDescription,
    setId,
    setArName,
  } = useChain();
  const [form] = Form.useForm();

  const bannerProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    beforeUpload: (file) => {
      return false;
    },
  };
  const logoProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    beforeUpload: (file) => {
      return false;
    },
  };

  const { mutate, isLoading } = useMutation(createChain, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        setIsCreateModal(false);
        queryClient.invalidateQueries("chains");
        resetState();
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });
  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editChain,
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          setIsCreateModal(false);
          queryClient.invalidateQueries("chains");
          resetState();
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const onFinish = (values) => {
    values.status = true;
    values.enName = values.arName;
    values.kuName = values.arName;
    values.description = values.description || "";

    if (id) {
      values.id = id;
      editMutate(values);
    } else {
      values.bannerFile = values.bannerFile.file;
      values.logoFile = values.logoFile.file;
      mutate(values);
    }
  };

  let resetState = () => {
    setId(null);
    setArName(null);
    setDescription("");
    form.resetFields();
  };

  useEffect(() => {
    if (id) {
      form.setFieldsValue({
        arName,
        description,
      });
    } else {
      form.resetFields();
      resetState();
    }
  }, [id]);

  return (
    <Modal
      forceRender
      title={<Title level={5}>{id ? "Edit" : "New"} Chain</Title>}
      className="app-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetState();
        setIsCreateModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={600}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        form={form}
        onFinish={onFinish}
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Form.Item
              label="Chain Name"
              name="arName"
              rules={[{ required: true }]}
            >
              <Input placeholder="Write chain name" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Description" name="description">
              <TextArea
                rows={4}
                placeholder={"Write description of this chain"}
              />
            </Form.Item>
          </Col>

          {!id ? (
            <>
              <Col span={12}>
                <Form.Item
                  label="Logo"
                  name="logoFile"
                  rules={[{ required: true }]}
                >
                  <Dragger {...logoProps}>
                    <p className="ant-upload-drag-icon">
                      <SmileOutlined />
                    </p>
                    <p className="ant-upload-text">Brand</p>
                    <p className="ant-upload-hint">
                      Drag Image to this area to upload
                    </p>
                  </Dragger>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Image"
                  name="bannerFile"
                  rules={[{ required: true }]}
                >
                  <Dragger {...bannerProps}>
                    <p className="ant-upload-drag-icon">
                      <PictureOutlined />
                    </p>
                    <p className="ant-upload-text">Cover Image</p>
                    <p className="ant-upload-hint">
                      Drag Image to this area to upload
                    </p>
                  </Dragger>
                </Form.Item>
              </Col>
            </>
          ) : null}

          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={isLoading || editLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
