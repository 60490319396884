import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  message,
  Select,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import Input from "antd/lib/input/Input";
import { useOrderStatus } from "./store";
import { useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import { createOrderStatus, editOrderStatus } from "../../api";
import { ColorPicker } from "../../comonents/global";

const { Title } = Typography;
const { Option } = Select;

export const PureCreate = () => {
  const {
    isCreateModal,
    setIsCreateModal,
    id,
    setId,
    enTitle,
    arTitle,
    kuTitle,
    vmsTitle,
    enDescription,
    arDescription,
    kuDescription,
    userType,
    code,
    setUserType,
    setCode,
    setEnTitle,
    setArTitle,
    setKuTitle,
    setVmsTitle,
    setEnDescription,
    setArDescription,
    setKuDescription,
    color,
    setColor,
  } = useOrderStatus();

  const resetstate = () => {
    setId(null);
    setUserType(null);
    setCode(null);
    setEnTitle(null);
    setArTitle(null);
    setKuTitle(null);
    setVmsTitle(null);
    setEnDescription(null);
    setArDescription(null);
    setKuDescription(null);
    setColor(null);
  };

  const { mutate, isLoading } = useMutation(createOrderStatus, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        setIsCreateModal(false);
        queryClient.invalidateQueries("orderStatus");
        resetstate();
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editOrderStatus,
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          setIsCreateModal(false);
          setId(null);
          queryClient.invalidateQueries("orderStatus");
          resetstate();
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const onFinish = (values) => {
    values.enTitle = values.enTitle;
    values.arTitle = values.arTitle;
    values.kuTitle = values.kuTitle;
    values.vmsTitle = values.vmsTitle;
    values.enDescription = values.enDescription;
    values.arDescription = values.arDescription;
    values.kuDescription = values.kuDescription;
    values.vmsStatus = true;
    values.color = color;

    if (id) {
      values.id = id;
      editMutate(values);
    } else {
      mutate(values);
    }
  };

  return (
    <Modal
      title={<Title level={5}>{id ? "Edit" : "New"} Order Status</Title>}
      className="app-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetstate();
        setIsCreateModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={900}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        initialValues={{
          remember: true,
          enTitle,
          arTitle,
          kuTitle,
          vmsTitle,
          enDescription,
          arDescription,
          kuDescription,
          userType,
          code,
        }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={8}>
            <Form.Item
              label="English Title"
              name="enTitle"
              rules={[{ required: true }]}
            >
              <Input placeholder="Write Title" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Arabic Title"
              name="arTitle"
              rules={[{ required: true }]}
            >
              <Input placeholder="Write Title" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Kurdish Title"
              name="kuTitle"
              rules={[{ required: true }]}
            >
              <Input placeholder="Write Title" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Engilsh Description"
              name="enDescription"
              rules={[{ required: false }]}
            >
              <Input placeholder="Write Title" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Arabic Description"
              name="arDescription"
              rules={[{ required: false }]}
            >
              <Input placeholder="Write Title" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Kurdish Description"
              name="kuDescription"
              rules={[{ required: false }]}
            >
              <Input placeholder="Write Title" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Code" name="code" rules={[{ required: true }]}>
              <Input placeholder="Write Code" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="User Type"
              name="userType"
              rules={[{ required: true }]}
            >
              <Select placeholder="Choose User Type">
                <Option key="VENDOR" values={"VENDOR"}>
                  VENDOR
                </Option>
                <Option key="CLIENT" values={"CLIENT"}>
                  CLIENT
                </Option>
                <Option key="BIKER" values={"BIKER"}>
                  BIKER
                </Option>
                <Option key="BACKOFFICE_USER" values={"BACKOFFICE_USER"}>
                  BACKOFFICE_USER
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="VMS Title"
              name="vmsTitle"
              // rules={[{ required: true }]}
            >
              <Input placeholder="Write Title" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <ColorPicker value={color} onChange={(val) => setColor(val)} />
          </Col>

          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={isLoading || editLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
