import { Card, Button, Breadcrumb, Space } from "antd";
import { CreditCardOutlined } from "@ant-design/icons";
import { PuerTable } from "./table";
import { CreatePayment } from "./createPayment";
import { useVendorPayment } from "./store";
import PaymentFilters from "./filters";

export const VendorPaymentPage = () => {
  const { setIsPayModal, vendorID } = useVendorPayment();

  return (
    <div className="app-page">
      <Breadcrumb style={{ marginBottom: 10 }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Resturants</Breadcrumb.Item>
        <Breadcrumb.Item>Vendor Payment</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <div className="app-space-between">
          <Space wrap>
            <PaymentFilters />
          </Space>
          {
            // authFunc("STATUS_CODE_CREATE") &&
            <Button
              disabled={!vendorID}
              onClick={() => setIsPayModal(true)}
              type="primary"
              icon={<CreditCardOutlined />}
            >
              Pay to vendor
            </Button>
          }
        </div>
        <br />
        <PuerTable />
        <CreatePayment />
      </Card>
    </div>
  );
};
