import { useState } from "react";
import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Select,
  Divider,
  InputNumber,
  message,
  Space,
  Input,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useBulk } from "./store";
import { useMutation, useQuery } from "react-query";
import { queryClient } from "../../queryClient";
import { creatBulkVoucher, getVoucherSettings } from "../../api";
import InfoCard from "./infoCard";

const { Title } = Typography;
const { Option } = Select;
export const PureCreateBulk = () => {
  const { isCreateModal, setIsCreateModal } = useBulk();
  const [search, setSearch] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);

  const [form] = Form.useForm();

  const { loading, data } = useQuery(
    ["voucherSettingsBulk", search, "BATCH"],
    getVoucherSettings,
    {
      refetchOnWindowFocus: false,
      retry: 2,
      enabled: !!isCreateModal,
    }
  );

  const resetState = async () => {
    form.resetFields();
  };

  const { mutate, isLoading } = useMutation(creatBulkVoucher, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        resetState();
        setIsCreateModal(false);
        queryClient.invalidateQueries("bulkVouchers");
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const onFinish = (values) => {
    mutate(values);
  };

  const getTotal = (el) => {
    let value = el?.balyValue + el?.vendorValue;
    let label = el?.valueType === "PERCENTAGE" ? "%" : " IQD";
    return `${value}${label}`;
  };

  return (
    <Modal
      forceRender
      title={<Title level={5}>New Bulk Voucher</Title>}
      className="app-modal bulk-voucher-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetState();
        setIsCreateModal(false);
      }}
      footer={false}
      maskClosable={false}
      width={800}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        form={form}
        onFinish={onFinish}
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={11}>
            <Form.Item
              label="Voucher Settings"
              name="settingID"
              rules={[{ required: true, message: "required" }]}
            >
              <Select
                showSearch
                dropdownMatchSelectWidth={false}
                loading={loading}
                placeholder="Choose Voucher Setting"
                onSearch={(val) => setSearch(val)}
                filterOption={false}
                onChange={(id) => {
                  let item = data?.data?.records?.find((el) => el?.id === id);
                  if (item) {
                    setSelectedItem(item);
                  }
                }}
              >
                {data &&
                  data?.success &&
                  data?.data?.records?.map((el) => (
                    <Option value={el?.id}>
                      <Space>
                        <span>{el?.voucherTitle}</span>
                        <span>-</span>
                        <b>{getTotal(el)}</b>
                      </Space>
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Code"
              name="code"
              rules={[{ required: true, message: "required" }]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item
              label="Count"
              name="length"
              rules={[{ required: true, message: "required" }]}
            >
              <InputNumber min={0} max={1000} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Divider style={{ marginTop: 0 }} />
            <InfoCard item={selectedItem} />
          </Col>
          <Col span={24}>
            <Divider style={{ marginTop: 10 }} />
            <Form.Item>
              <Button
                loading={isLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
