import { Table } from "antd";

import { getDeclineReason } from "../../api";
import { useQuery } from "react-query";

// const dataKeys = [
//     {
//         id: 1,
//         key: "ID",
//         description: "الايدي الخاص بالمستخدم",
//     },
//     {
//         id: 2,
//         key: "UserID",
//         description: "testing",
//     },
//     {
//         id: 3,
//         key: "VendorID",
//         description: "testing",
//     },
//     {
//         id: 4,
//         key: "TrackNo",
//         description: "testing",
//     },
//     {
//         id: 5,
//         key: "PreOrderDeliveryTime",
//         description: "testing",
//     },
//     {
//         id: 6,
//         key: "ExpectedDeliveryTime",
//         description: "testing",
//     },
//     {
//         id: 7,
//         key: "BikerPickupDateTime",
//         description: "testing",
//     },
//     {
//         id: 8,
//         key: "BikerImg",
//         description: "testing",
//     },
//     {
//         id: 9,
//         key: "BikerPhone",
//         description: "testing",
//     },
//     {
//         id: 10,
//         key: "DiscountTotalValue",
//         description: "testing",
//     },
//     {
//         id: 11,
//         key: "DiscountBalyValue",
//         description: "testing",
//     },
//     {
//         id: 12,
//         key: "DiscountVendorValue",
//         description: "testing",
//     },
//     {
//         id: 13,
//         key: "TotalValue",
//         description: "testing",
//     },
//     {
//         id: 14,
//         key: "User",
//         description: "testing",
//     },
//     {
//         id: 15,
//         key: "Vendor",
//         description: "testing",
//     },
// ]

export const KeysTable = () => {
  const { isLoading, data } = useQuery(
    ["declineReasons", "", 1, 100],
    getDeclineReason,
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );

  const columns = [
    {
      title: "Key",
      dataIndex: "arTitle",
    },
    {
      title: "Description",
      dataIndex: "arTitle",
    },
  ];

  return (
    <Table
      size={"small"}
      loading={isLoading}
      columns={columns}
      dataSource={data ? data.data.records : []}
      bordered
      pagination={false}
      scroll={{
        y: 430,
      }}
    />
  );
};
