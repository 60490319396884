import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
import { useResturant } from "./store";
import "leaflet/dist/leaflet.css";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";

export const PureMap = ({ location }) => {
  const { setLat, setLon } = useResturant();
  const [markerLocation, setMarkerLocation] = useState(null);

  const MapComponent = () => {
    useMapEvents({
      click: (e) => {
        setMarkerLocation([e.latlng.lat, e.latlng.lng]);
        setLat(e.latlng.lat);
        setLon(e.latlng.lng);
      },
    });
    return null;
  };
  useEffect(() => {
    if (location !== null && location !== undefined) {
      setMarkerLocation([location[1], location[0]]);
    }
  }, []);

  return (
    <div style={{ position: "relative" }}>
      <MapContainer
        center={
          location != null && location != undefined
            ? [location[1], location[0]]
            : [33.318551766509614, 44.34730711451267]
        }
        zoomControl={false}
        zoom={15}
        scrollWheelZoom={true}
        attributionControl={false}
        style={{ height: "400px" }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://osrm.baly.dev/osm/{z}/{x}/{y}.png"
        />
        {markerLocation && (
          <Marker
            position={markerLocation}
            icon={
              new Icon({
                iconUrl: markerIconPng,
                iconSize: [25, 41],
                iconAnchor: [12, 41],
              })
            }
          />
        )}
        <MapComponent />
      </MapContainer>
    </div>
  );
};
