import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  message,
  Select,
  Spin,
  Checkbox
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import { editTrendingVendorPage, getRestaurantsNames } from "../../api";
import { useInsideVendor } from "./store";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";

const { Title } = Typography;
const { Option } = Select;

export const PureVendorModal = () => {
  const {
    isVendorModal,
    filterData,
    setIsVendorModal,
    setIsInternalModal,
    setIsCreateModal,
    setFilterData,
  } = useInsideVendor();
  const [form] = Form.useForm();
  const [search, setSearch] = useState("");
  const [allVendors, setAllVendors] = useState(false);

  const resetState = () => {
    setFilterData(null)
    setIsVendorModal(false)
    setIsInternalModal(false)
    setIsCreateModal(false)
    setAllVendors(false)
    setSearch("")
    form.resetFields()

  };

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editTrendingVendorPage,
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          queryClient.invalidateQueries("productTrendingCarousels");
          resetState();
          setIsCreateModal(false);
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );


  const { isLoading: restaurantLoading, data: restaurants } = useQuery(
    ["restaurants", search, 1, 1000],
    getRestaurantsNames,
    {
      refetchOnWindowFocus: false,
      retry: 2,
      enabled: !!isVendorModal,
    }
  );

  useEffect(() => {
    if (filterData && isVendorModal && restaurants && restaurants?.success) {
      if (JSON.stringify(filterData.vendors) === JSON.stringify([-1])) {
        setAllVendors(true)
        form.resetFields()
      } else {
        setAllVendors(false)
        form.setFieldsValue({
          vendors: filterData.vendors,
        });
      }

    }
  }, [filterData, restaurants, isVendorModal,]);

  const onFinish = (values) => {
    const { id, name, limit, internal_product_groups } = filterData;
    if (allVendors) {
      values.vendors = [-1]
    }
    let data = {
      id,
      name,
      limit,
      vendors: values.vendors || [],
      internal_product_groups: internal_product_groups || []
    };
    editMutate(data);
  };
  const AllVendorsCheck = (e) => {
    setAllVendors(e.target.checked)
  };

  return (
    <Modal
      forceRender
      title={<Title level={5}>{filterData && filterData.id ? "Edit" : "New"} White list Vendor</Title>}
      className="app-modal"
      centered
      visible={isVendorModal}
      onCancel={() => {
        resetState();
      }}
      footer={false}
      destroyOnClose={true}
      width={460}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={{
          remember: true,

        }}>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Form.Item
              label="White list Vendors"
              name="vendors"
            >
              <Select
                disabled={allVendors}
                mode="multiple"
                showSearch
                filterOption={true}
                optionFilterProp="children"
                onSearch={(val) => setSearch(val)}
                style={{ width: "100%" }}
                placeholder="select vendors"
                allowClear

              >
                {!restaurantLoading ? (
                  restaurants?.success ? (
                    restaurants?.data?.records.map((el) => (
                      <Option
                        // disabled={isRepeated(el.id)}
                        key={el.id}
                        id={el.id}
                        value={el.id}
                      >
                        {el.name}
                      </Option>
                    ))
                  ) : null
                ) : (
                  <Spin spinning={true} />
                )}
              </Select>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label="White list All Vendors"
              name="allVendors"
            >
              <Checkbox checked={allVendors} onChange={AllVendorsCheck}>All vendors</Checkbox>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={editLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
