export const calcTotal = (
  basket,
  totalDeliveryFee,
  serviceFee,
  voucherAmount,
  discountCap,
  extraCharge
) => {
  let value = 0;
  basket.map((el) => {
    value += el?.product?.subProductGroup
      ? (el?.product?.endprice + el?.product?.subProductEndPrice) * el?.qty
      : el?.product?.endprice * el?.qty;
  });

  return (
    value +
    totalDeliveryFee +
    serviceFee -
    voucherAmount -
    discountCap +
    extraCharge
  );
};

export const calcVoucher = (subTotal, voucher, discount) => {
  if (!voucher || voucher?.voucherType === "DELIVERY") return 0;
  let value = 0;
  let { totalValue, maxAmount } = voucher || {};
  if (totalValue === 0) return 0;
  else {
    value = (subTotal - discount) * (totalValue / 100);
    if (value > maxAmount) value = maxAmount;
  }
  return value || 0;
};

export const calcSubTotal = (basket) => {
  let value = 0;
  basket.map((el) => {
    value += el?.product?.subProductGroup
      ? (el?.product?.price + el?.product?.subProductPrice) * el?.qty
      : el?.product?.price * el?.qty;
  });
  return value;
};

export const calcDeliveryFee = (
  subTotal,
  deliveryFee,
  deliveryDiscountObj,
  voucher,
  discount = 0
) => {
  let value = deliveryFee;
  if (subTotal - discount >= deliveryDiscountObj?.minamount) {
    let discount = deliveryFee * (deliveryDiscountObj?.totalValue / 100);
    value = deliveryFee - discount;
    if (discount > deliveryDiscountObj?.maxamount) {
      value = deliveryFee - deliveryDiscountObj?.maxamount;
    }
  }

  if (voucher?.voucherType === "COMBINED") value = 0;
  else if (voucher?.voucherType === "DELIVERY") {
    if (subTotal >= voucher?.minAmount) {
      let discount = value * (voucher?.totalValue / 100);
      if (discount > voucher?.maxAmount) {
        value = value - voucher?.maxAmount;
      } else value = value - discount;
    }
  }

  return value;
};

export const calcBasketTotal = (basket) => {
  let value = 0;
  basket.map((el) => {
    value += el?.product?.subProductGroup
      ? (el?.product?.endprice + el?.product?.subProductEndPrice) * el?.qty
      : el?.product?.endprice * el?.qty;
  });
  return value;
};

export const calcDiscountCap = (subTotal, vendorDiscountCapObj, discount) => {
  let value = 0;
  let { totalValue, maxamount, minamount } = vendorDiscountCapObj || {};
  if (totalValue === 0) return 0;
  else if (subTotal >= minamount) {
    value = (subTotal - discount) * (totalValue / 100);
    if (value > maxamount) value = maxamount;
  }
  return value || 0;
};

export const calcDiscountFunc = (basket) => {
  let totals = basket
    .map((b) => ({
      price: (b.product.price + (b.product.subProductPrice || 0)) * b.qty,
      endPrice:
        (b.product.endprice + (b.product.subProductEndPrice || 0)) * b.qty,
    }))
    .reduce(
      (a, b) => ({
        price: a.price + b.price,
        endPrice: a.endPrice + b.endPrice,
      }),
      { price: 0, endPrice: 0 }
    );
  return totals.price - totals.endPrice;
};
