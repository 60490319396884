import create from "zustand";

export const useRegion = create((set) => ({
  isCreateModal: false,
  id: null,
  name: null,
  provinceID: null,
  area: null,
  areaSelect: null,
  createMapCenter: null,
  queryKey: "",
  isFilter: false,
  page: 1,
  provinceIdFilter: null,

  setProvinceIdFilter: (provinceIdFilter) => set({ provinceIdFilter }),
  setIsFilter: (isFilter) => set({ isFilter }),
  setQueryKey: (queryKey) => set({ queryKey }),
  setId: (id) => set({ id }),
  setAreaSelect: (areaSelect) => set({ areaSelect }),
  setName: (name) => set({ name }),
  setProvinceID: (provinceID) => set({ provinceID }),
  setArea: (area) => set({ area }),
  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
  setCreateMapCenter: (createMapCenter) => set({ createMapCenter }),
  setPage: (page) => set({ page }),
}));
