import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  message,
  Upload,
  Select,
  Spin,
  Tag,
  Space,
} from "antd";
import {
  SaveOutlined,
  FileAddOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import Input from "antd/lib/input/Input";
import { useVmsSettings } from "./store";
import { useMutation, useQuery } from "react-query";
import { queryClient } from "../../queryClient";
import {
  createVmsSettings,
  editVmsSettings,
  getResturants,
  getVendors,
} from "../../api";
import { useEffect, useState } from "react";

const { Title } = Typography;
const { Dragger } = Upload;
const { Option } = Select;

export const PureCreate = () => {
  const {
    isCreateModal,
    setIsCreateModal,

    id,
    link,
    version,

    setVersion,
    setLink,
    setId,
    setVendorList,
    vendorList,
  } = useVmsSettings();

  const [selected, setSelected] = useState(null);
  const [vendorsFilter, setVendorsFilter] = useState("");

  const resetState = () => {
    setVersion(null);
    setId(null);
    setLink(null);
    setVendorList([]);
    setSelected(null);
    setVendorsFilter("");
  };

  const { isLoading: vendorsLoading, data: vendors } = useQuery(
    ["vendors", vendorsFilter],
    getResturants,
    {
      retry: 2,
      refetchOnWindowFocus: false,
      enabled: !!isCreateModal,
    }
  );

  const isRepeated = (id) =>
    vendorList.find((el) => el.id === id) ? true : false;

  useEffect(() => {
    if (selected && vendors.success) {
      setVendorList([
        ...vendorList,
        vendors.data.records.find((el) => el.id == selected),
      ]);
      setSelected(null);
    }
  }, [selected]);

  const { mutate, isLoading } = useMutation(createVmsSettings, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        setIsCreateModal(false);
        queryClient.invalidateQueries("VmsSettings");
        resetState();
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });
  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editVmsSettings,
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          setIsCreateModal(false);
          queryClient.invalidateQueries("VmsSettings");
          resetState();
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  let fileProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    disabled: isLoading ? true : false,
    beforeUpload: () => {
      return false;
    },
  };

  const onFinish = (values) => {
    values.whiteList = JSON.stringify(vendorList.map((el) => el.id));
    if (id) {
      values.id = id;
      editMutate(values);
    } else {
      values.file = values.file.file;
      mutate(values);
    }
  };

  return (
    <Modal
      title={<Title level={5}>{id ? "Edit" : "New"} VMS Settings</Title>}
      className="app-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetState();
        setIsCreateModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={500}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        initialValues={{ remember: true, link, version }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          {id ? (
            <Col span={24}>
              <Form.Item label="Link" name="link" rules={[{ required: true }]}>
                <Input placeholder="Link" />
              </Form.Item>
            </Col>
          ) : (
            <Col span={24}>
              <Form.Item
                label="VMS File"
                name="file"
                rules={[{ required: true }]}
              >
                <Dragger {...fileProps}>
                  {!isLoading ? (
                    <>
                      <p className="ant-upload-drag-icon">
                        <FileAddOutlined />
                      </p>
                      <p className="ant-upload-text">VMS APK</p>
                      <p className="ant-upload-hint">
                        Drag File to this area to upload
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="ant-upload-text">Uploading File ...</p>
                      <LoadingOutlined />
                      <p className="ant-upload-hint">
                        please wait this may take a while
                      </p>
                    </>
                  )}
                </Dragger>
              </Form.Item>
            </Col>
          )}
          <Col span={24}>
            <Form.Item
              label="Version"
              name="version"
              rules={[{ required: true }]}
            >
              <Input placeholder={"Version"} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Divider style={{ marginTop: 0 }} />
            <Select
              showSearch
              filterOption={true}
              optionFilterProp="children"
              loading={vendorsLoading}
              value={selected}
              onChange={(val) => setSelected(val)}
              onSearch={(val) => setVendorsFilter(val)}
              style={{ width: "100%" }}
              placeholder="Find Vendors"
              allowClear
            >
              {!vendorsLoading ? (
                vendors?.success ? (
                  vendors?.data?.records?.map((el) => (
                    <Option
                      key={el.id}
                      id={el.id}
                      value={el.id}
                      disabled={isRepeated(el.id)}
                    >
                      {el?.arName}
                    </Option>
                  ))
                ) : null
              ) : (
                <Spin spinning={true} />
              )}
            </Select>
          </Col>
          <Col span={24}>
            <div style={{ maxHeight: "24vh", overflow: "auto" }}>
              <Space wrap>
                {vendorList.length > 0
                  ? vendorList.map((el) => (
                      <Tag
                        key={el?.id}
                        closable
                        onClose={() => {
                          setVendorList(
                            vendorList.filter((item) => item?.id != el?.id)
                          );
                        }}
                        color="geekblue"
                        // style={{ fontSize: 16, padding: "4px 8px" }}
                      >
                        {el?.name || el?.arName}
                      </Tag>
                    ))
                  : null}
              </Space>
            </div>
          </Col>
          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={isLoading || editLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
