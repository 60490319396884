import { useEffect, useState } from "react";
import { useOrderView } from "./store";
import deviceLogo from "../../assets/images/map-device.png";
import userLogo from "../../assets/images/map-user.png";
import vendorLogo from "../../assets/images/map-vendor.png";

import { MapContainer, TileLayer, useMap, Marker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

let userMarkerIcon = L.icon({
  iconUrl: userLogo,
  iconSize: [50, 50],
});
let vendorMarkerIcon = L.icon({
  iconUrl: vendorLogo,
  iconSize: [50, 50],
});
let deviceMarkerIcon = L.icon({
  iconUrl: deviceLogo,
  iconSize: [50, 50],
});

export const PureMap = () => {
  const { order } = useOrderView();
  const [clientAddress, setClientAddress] = useState(null);
  const [clientLocation, setClientLocation] = useState(null);
  const [vendorLocation, setVendorLocation] = useState(null);

  useEffect(() => {
    if (order && order?.order) {
      setClientAddress([order?.order?.clientLat, order?.order?.clientLon]);
      setClientLocation([
        order?.order?.currenClientLat,
        order?.order?.currenClientLon,
      ]);
      setVendorLocation([order?.order?.vendorLat, order?.order?.vendorLon]);
    }
  }, [order]);

  const Bounds = () => {
    const map = useMap();
    if (clientAddress) {
      map.fitBounds([clientAddress, vendorLocation, clientLocation], {
        padding: [100, 100],
      });
    }
    return null;
  };

  return (
    <MapContainer
      center={clientAddress || [44.3661, 33.3152]}
      zoomControl={false}
      zoom={15}
      scrollWheelZoom={true}
      attributionControl={false}
      style={{ height: "38vh" }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://osrm.baly.dev/osm/{z}/{x}/{y}.png"
      />
      {clientLocation && (
        <Marker icon={deviceMarkerIcon} position={clientLocation} />
      )}
      {clientAddress && (
        <Marker icon={userMarkerIcon} position={clientAddress} />
      )}
      {vendorLocation && (
        <Marker icon={vendorMarkerIcon} position={vendorLocation} />
      )}
      {clientAddress && <Bounds />}
    </MapContainer>
  );
};
