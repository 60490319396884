import { useState } from "react";
import {
  Table,
  Space,
  Typography,
  Alert,
  Pagination,
  Popconfirm,
  Button,
  message,
} from "antd";
import { WomanOutlined, ManOutlined, DeleteOutlined } from "@ant-design/icons";
import { useQuery, useMutation } from "react-query";
import {
  pageSize,
  getClientsVoucher,
  DeleteFromVoucher,
  DeleteAllUserVoucher,
} from "../../../api";
import dayjs from "dayjs";
import { queryClient } from "../../../queryClient";
import { useDiscount } from "../../vendor_discounts/store";

const { Text } = Typography;

export const ClientTable = ({ id, filter }) => {
  const { isUsersModal } = useDiscount();
  const [page, setPage] = useState(1);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      key: "id",
      render: (row) => (
        <Space>
          {row.gender == "female" ? (
            <WomanOutlined style={{ color: "#eb2f96" }} />
          ) : (
            <ManOutlined style={{ color: "#2196f3" }} />
          )}
          <Text style={{ fontWeight: "bold" }}>
            {row.firstName} {row.lastName}
          </Text>
        </Space>
      ),
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },

    {
      title: "Creation Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => (
        <Text type="secondary">{dayjs(date).format("MMMM D, YYYY")}</Text>
      ),
    },
    {
      title: (all) => (
        <Popconfirm
          title="Are you sure to delete All Clients?"
          onConfirm={() => deleteAll({ id })}
          okText="Yes"
          cancelText="No"
          placement="rightBottom"
        >
          <Button size="small" danger icon={<DeleteOutlined />}>
            Delete All
          </Button>
        </Popconfirm>
      ),
      key: "id",
      render: (row) => (
        <Popconfirm
          title="Are you sure to delete this client?"
          onConfirm={() =>
            mutate({
              id,
              userID: row.phone,
            })
          }
          okText="Yes"
          cancelText="No"
          placement="rightBottom"
        >
          <Button size="small" danger icon={<DeleteOutlined />} />
        </Popconfirm>
      ),
    },
  ];

  const { mutate, isLoading: deleteLoading } = useMutation(
    (values) => DeleteFromVoucher("user", values),
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          queryClient.invalidateQueries("vouchers");
          queryClient.invalidateQueries("clientsVoucher");
        } else {
          message.error(data.err);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const { mutate: deleteAll } = useMutation(
    (values) => DeleteAllUserVoucher(values),
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          queryClient.invalidateQueries("vouchers");
          queryClient.invalidateQueries("clientsVoucher");
        } else {
          message.error(data.err);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );
  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(["clientsVoucher", filter, page, id], getClientsVoucher, {
    refetchOnWindowFocus: false,
    retry: 2,
    enabled: !!isUsersModal,
  });

  if (error) return <Alert message={error.message} type="error" showIcon />;

  if (!loading && !data.success)
    return <Alert message={data.msg} type="error" showIcon />;

  return (
    <>
      <Table
        loading={loading || deleteLoading}
        columns={columns}
        dataSource={data ? data.data.records : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => setPage(val)}
        current={page}
        defaultPageSize={pageSize}
        total={data && data.data.total}
        showSizeChanger={false}
      />
    </>
  );
};
