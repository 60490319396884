import {
  Table,
  Space,
  Button,
  Typography,
  Pagination,
  Alert,
  message,
  Popconfirm,
  Switch,
  Tooltip,
  Tag,
} from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useQuery, useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import {
  getSubProductGroup,
  removeData,
  editToppingGroup,
  pageSize,
} from "../../api";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSubProductGroup } from "./store";
import { NavLink } from "react-router-dom";
import authFunc from "../../helper/auth";
import { useSearchParams } from "react-router-dom";

const { Text } = Typography;

export const PureTable = () => {
  const { resturantID, productID } = useParams();
  const [page, setPage] = useState(1);
  const {
    setIsCreateModal,
    setId,
    setName,
    setStatus,
    setProductID,
    setPriority,
    setMultiSelect,

    queryKey,
  } = useSubProductGroup();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, row) => <Text style={{ fontWeight: "bold" }}>{text}</Text>,
    },

    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      render: (text) => <Text style={{ fontSize: 12 }}>{text}</Text>,
    },

    {
      title: "Multi Select",
      dataIndex: "multiSelect",
      key: "multiSelect",
      render: (val) => <Tag>{val ? "True" : "False"}</Tag>,
    },

    {
      title: "Status",
      key: "id",
      render: (row) => (
        <Switch
          disabled={authFunc("TOPPINGS_GROUP_UPDATE") ? false : true}
          loading={editLoading}
          checked={row.status}
          onChange={(state) => handleChangeActive(row)}
        />
      ),
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id, row) => (
        <Space>
          {authFunc("TOPPINGS_READ") && (
            <NavLink
              to={`/resturants/${resturantID}/products/${productID}/SubProductGroup/${row.id}/topping`}
            >
              <Tooltip placement="top" title="view topping for this product">
                <Button size="small">Toppings</Button>
              </Tooltip>
            </NavLink>
          )}
          {authFunc("TOPPINGS_GROUP_UPDATE") && (
            <Button
              onClick={() => handleEdit(row)}
              size="small"
              icon={<EditOutlined />}
            />
          )}
          {authFunc("TOPPINGS_GROUP_DELETE") && (
            <Popconfirm
              title="Are you sure to delete this task?"
              onConfirm={() => mutate(id)}
              okText="Yes"
              cancelText="No"
            >
              <Button size="small" danger icon={<DeleteOutlined />} />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  const handleEdit = (row) => {
    setId(row.id);
    setName(row.name);
    setProductID(row.productID);
    setPriority(row.priority);
    setMultiSelect(row.multiSelect);
    setIsCreateModal(true);
    setStatus(row.status);
  };

  const handleChangeActive = (row) => {
    let _data = {
      id: row.id,
      name: row.name,
      productID: row.productID,
      multiSelect: row.multiSelect,
      priority: row.priority,
      status: (row.status = !row.status),
    };
    {
      editMutate(_data);
    }
  };

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editToppingGroup,
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          queryClient.invalidateQueries("subProductGroup");
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const { mutate } = useMutation((val) => removeData("toppingGroup", val), {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        queryClient.invalidateQueries("subProductGroup");
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const {
    isLoading: loading,
    error,
    data,
  } = useQuery(
    ["subProductGroup", productID, queryKey, page],
    getSubProductGroup,
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );

  if (error || (!data?.success && data?.data?.err))
    return (
      <Alert
        message={data?.data?.err || error?.message}
        type="error"
        showIcon
      />
    );

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data ? data.data.records : []}
        size="small"
        rowKey={(record) => record.id}
        pagination={false}
      />
      <br />
      <Pagination
        onChange={(val) => {
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
        current={Number(searchParams.get("page_num")) || page}
        defaultPageSize={pageSize}
        total={data && data.data.total}
        showSizeChanger={false}
      />
    </>
  );
};
