import create from "zustand";

export const useCompensationSettings = create((set) => ({
  isCreateModal: false,
  id: null,
  type: null,
  paddingTime: null,
  voucherSettingsID: null,
  filters: null,
  filterIDs: [],
  page: 1,
  typeFilter: null,

  setTypeFilter: (typeFilter) => set({ typeFilter }),
  setPage: (page) => set({ page }),
  setFilterIDs: (filterIDs) => set({ filterIDs }),
  setId: (id) => set({ id }),
  setFilters: (filters) => set({ filters }),
  setVoucherSettingsID: (voucherSettingsID) => set({ voucherSettingsID }),
  setPaddingTime: (paddingTime) => set({ paddingTime }),
  setType: (type) => set({ type }),
  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
}));
