import { useEffect } from "react";
import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Select,
  Divider,
  InputNumber,
  Checkbox,
  DatePicker,
  Input,
  message,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useDiscount } from "../vendor_discounts/store";
import { useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import { creatVoucher, editVoucher } from "../../api";
import { CustomeCalender } from "../../comonents/global";
import dayjs from "dayjs";

const { Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;
export const PureCreate = () => {
  const {
    isCreateModal,
    setIsCreateModal,
    code,
    voucherTitle,
    voucherType,
    maxAmount,
    minAmount,
    noOfUsage,
    totalQty,
    startDate,
    endDate,
    platform,
    fisrtOrderOnly,
    newUsersOnly,
    UDID,
    applyOverDiscount,
    id,
    setEndDate,
    setStartDate,
    setVoucherTitle,
    setVoucherType,
    setUserIDs,
    setTotalQty,
    setPlatform,
    setDayOfWeek,
    setMinAmount,
    setId,
    setFisrtOrderOnly,
    setCode,
    setNoOfUsage,
    setMaxAmount,
    setNewUsersOnly,
    setUDID,
    setApplyOverDiscount,
    schedule,
    setSchedule,
    totalValue,
    vendorValue,
    balyValue,
    setBalyValue,
    setTotalValue,
    setVendorValue,
    setAvToCustomers,
    avToCustomers,
    customerDesc,
    setCustomerDesc,
  } = useDiscount();

  const [form] = Form.useForm();

  const resetState = async () => {
    setEndDate(null);
    setStartDate(null);
    setVoucherTitle(null);
    setVoucherType(null);
    setUserIDs(null);
    setTotalQty(null);
    setPlatform(null);
    setDayOfWeek(null);
    setNewUsersOnly(null);
    setUDID(null);
    setApplyOverDiscount(null);
    setMinAmount(null);
    setId(null);
    setFisrtOrderOnly(null);
    setNoOfUsage(null);
    setMaxAmount(null);
    setCode(null);
    setSchedule(null);
    setBalyValue(null);
    setTotalValue(null);
    setVendorValue(null);
    setAvToCustomers(null);
    setCustomerDesc(null);
    form.resetFields();
  };

  useEffect(() => {
    if (id) {
      form.setFieldsValue({
        code,
        voucherTitle,
        voucherType,
        maxAmount,
        minAmount,
        noOfUsage,
        totalQty,
        startDate,
        endDate,
        platform: platform ? platform : [],
        fisrtOrderOnly,
        newUsersOnly,
        UDID,
        applyOverDiscount,
        totalValue,
        vendorValue,
        balyValue,
        avToCustomers,
        customerDesc,
        schedule: schedule ? schedule : [],
      });
    } else {
      form.resetFields();
    }
  }, [id]);

  const { mutate, isLoading } = useMutation(creatVoucher, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        resetState();
        setIsCreateModal(false);
        queryClient.invalidateQueries("vouchers");
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editVoucher,
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          resetState();
          setIsCreateModal(false);
          queryClient.invalidateQueries("vouchers");
          queryClient.invalidateQueries("bulkVouchers");
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const onFinish = (values) => {
    values.platform = JSON.stringify(values.platform);
    values.schedule = JSON.stringify(schedule);
    values.startDate = dayjs(values.startDate).startOf("day").toDate();
    values.endDate = dayjs(values.endDate).startOf("day").toDate();
    values.userIDs = "[]";
    values.cityIDs = "[]";
    values.BusinessCategoryIDs = "[]";
    values.vendorIDs = "[]";
    values.tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (id) {
      values.id = id;
      editMutate(values);
    } else {
      mutate(values);
    }
  };

  const handleMinMaxValue = () => {
    if (form.getFieldValue("maxAmount") > form.getFieldValue("minAmount")) {
      form.setFields([
        {
          name: "minAmount",
          errors: [],
        },
        {
          name: "maxAmount",
          errors: [],
        },
      ]);
    }
  };

  const getVendorValue = () => {
    form.setFieldsValue({
      vendorValue:
        form.getFieldValue("totalValue") - form.getFieldValue("balyValue"),
    });
  };

  return (
    <Modal
      forceRender
      title={<Title level={5}>{id ? "Edit" : "New"} Voucher</Title>}
      className="app-modal voucher-modal"
      centered
      visible={isCreateModal}
      onCancel={() => {
        resetState();
        setIsCreateModal(false);
      }}
      footer={false}
      maskClosable={false}
      width={1280}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        form={form}
        onFinish={onFinish}
        layout="vertical"
      >
        <Row gutter={[16, 16]}>
          <Col span={15}>
            <Row gutter={[20, 20]}>
              <Col span={8}>
                <Form.Item
                  label="Title"
                  name="voucherTitle"
                  rules={[
                    {
                      required: true,
                      message: "required",
                      message: "required",
                    },
                  ]}
                >
                  <Input placeholder={"Voucher Title"} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Code"
                  name="code"
                  rules={[{ required: true, message: "required" }]}
                >
                  <Input placeholder={"Balyf70"} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Voucher Type"
                  name="voucherType"
                  rules={[{ required: true, message: "required" }]}
                >
                  <Select placeholder="Choose type">
                    <Option value={"DELIVERY"}>Free Delivery</Option>
                    <Option value={"DISCOUNT"}>Discount</Option>
                    <Option value={"COMBINED"}>Combined</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Total Value"
                  name="totalValue"
                  rules={[
                    { required: true, message: "required" },
                    {
                      type: "number",
                      min: 0,
                      max: 100,
                      message: "must be percentage",
                    },
                  ]}
                >
                  <InputNumber
                    min={0}
                    max={100}
                    addonAfter="%"
                    parser={(value) => value.replace("%", "")}
                    onChange={getVendorValue}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Baly Value"
                  name="balyValue"
                  rules={[
                    { required: true, message: "required" },
                    {
                      type: "number",
                      min: 0,
                      max: 100,
                      message: "must be percentage",
                    },
                  ]}
                >
                  <InputNumber
                    min={0}
                    max={100}
                    addonAfter="%"
                    parser={(value) => value.replace("%", "")}
                    onChange={getVendorValue}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Vendor Value"
                  name="vendorValue"
                  rules={[
                    { required: true, message: "required" },
                    {
                      type: "number",
                      min: 0,
                      max: 100,
                      message: "must be percentage",
                    },
                  ]}
                >
                  <InputNumber
                    min={0}
                    max={100}
                    addonAfter="%"
                    readOnly={true}
                    parser={(value) => value.replace("%", "")}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item
                  label="No. Use"
                  name="noOfUsage"
                  rules={[{ required: true, message: "required" }]}
                >
                  <InputNumber
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    style={{ width: "100%" }}
                    min={0}
                  />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item
                  label="Quentity"
                  name="totalQty"
                  rules={[{ required: true, message: "required" }]}
                >
                  <InputNumber
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    style={{ width: "100%" }}
                    min={1}
                  />
                </Form.Item>
              </Col>

              <Col span={7}>
                <Form.Item
                  label="Basket Value ( IQD )"
                  name="minAmount"
                  rules={[{ required: true, message: "required" }]}
                >
                  <InputNumber
                    onChange={(value) => {
                      handleMinMaxValue();
                    }}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    style={{ width: "100%" }}
                    max={100000}
                  />
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item
                  label="Max Value ( IQD )"
                  name="maxAmount"
                  rules={[{ required: true, message: "required" }]}
                >
                  <InputNumber
                    onChange={(value) => {
                      handleMinMaxValue();
                    }}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    style={{ width: "100%" }}
                    max={100000}
                  />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Col span={24}>
                  <Form.Item
                    label="Customer Description"
                    name="customerDesc"
                    rules={[
                      {
                        required: true,
                        message: "required",
                        message: "required",
                      },
                    ]}
                  >
                    <TextArea
                      autoSize={{
                        minRows: 4,
                      }}
                      placeholder={"Customer Description"}
                    />
                  </Form.Item>
                </Col>
              </Col>
              <Col span={14}>
                <Form.Item
                  label="Platforms"
                  name="platform"
                  rules={[{ required: true, message: "required" }]}
                >
                  <Select
                    mode="tags"
                    placeholder="Choose platform"
                    style={{
                      maxHeight: "80px",
                      overflow: "auto",
                    }}
                  >
                    <Option value={"PWA"}>PWA</Option>
                    <Option value={"WEB"}>WEB</Option>
                    <Option value={"IOS_SUPERAPP"}>IOS_SUPERAPP</Option>
                    <Option value={"ANDROID_SUPERAPP"}>ANDROID_SUPERAPP</Option>
                    <Option value={"IOS_SHELL"}>IOS_SHELL</Option>
                    <Option value={"ANDROID_SHELL"}>ANDROID_SHELL</Option>
                    <Option value={"IOS"}>IOS</Option>
                    <Option value={"ANDROID"}>ANDROID</Option>
                    <Option value={"WINDOWS10"}>WINDOWS10</Option>
                    <Option value={"DESKTOP_WEB"}>DESKTOP_WEB</Option>
                    <Option value={"MOBILE_WEB"}>MOBILE_WEB</Option>
                    <Option value={"WEB_IOS"}>WEB_IOS</Option>
                    <Option value={"WEB_MACOS"}>WEB_MACOS</Option>
                    <Option value={"WEB_WINDOWS"}>WEB_WINDOWS</Option>
                    <Option value={"SUPERAPP_ANDROID"}>SUPERAPP_ANDROID</Option>
                    <Option value={"SUPERAPP_IOS"}>SUPERAPP_IOS</Option>
                    <Option value={"WEB_ANDROID"}>WEB_ANDROID</Option>
                    <Option value={"SHELL_IOS"}>SHELL_IOS</Option>
                    <Option value={"SHELL_ANDROID"}>SHELL_ANDROID</Option>
                    <Option value={"OTHER"}>OTHER</Option>
                  </Select>
                </Form.Item>

                <Col>
                  <Row>
                    <Form.Item name="fisrtOrderOnly" valuePropName="checked">
                      <Checkbox>First Order Only?</Checkbox>
                    </Form.Item>

                    <Form.Item name="newUsersOnly" valuePropName="checked">
                      <Checkbox>New Users Only?</Checkbox>
                    </Form.Item>

                    <Form.Item name="UDID" valuePropName="checked">
                      <Checkbox> Devices Only?</Checkbox>
                    </Form.Item>
                  </Row>
                  <Row>
                    <Form.Item name="applyOverDiscount" valuePropName="checked">
                      <Checkbox>Apply over discount ?</Checkbox>
                    </Form.Item>

                    <Form.Item name="avToCustomers" valuePropName="checked">
                      <Checkbox> Available to Customers ?</Checkbox>
                    </Form.Item>
                  </Row>
                </Col>
              </Col>
              <Col span={24}>
                <Divider />
                <Form.Item>
                  <Button
                    loading={isLoading || editLoading}
                    type="primary"
                    htmlType="submit"
                    icon={<SaveOutlined />}
                  >
                    Save
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={1}>
            <Divider type="vertical" style={{ height: "100%" }} />
          </Col>
          <Col span={8}>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  // value={moment(startDate)}
                  label="Start Date"
                  name="startDate"
                  rules={[{ required: true, message: "required" }]}
                >
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  // value={moment(endDate)}
                  label="End Date"
                  name="endDate"
                  rules={[{ required: true, message: "required" }]}
                >
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Schedule"
                  name="schedule"
                  rules={[{ required: true, message: "schedule is required" }]}
                >
                  <CustomeCalender
                    value={schedule}
                    onChange={(val) => {
                      setSchedule(val);
                    }}
                    isAll={() => null}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
