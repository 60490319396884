import React from "react";
import { Select } from "antd";
import { useBikerReview } from "./store";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { DislikeFilled, LikeFilled } from "@ant-design/icons";

const { Option } = Select;

export default function BikerReviewFilters() {
  const { bikerRate, setBikerRate, setBikerPage } = useBikerReview();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    let obj = {
      rate: searchParams.get("rate") || null,
      page_num: searchParams.get("page_num") || null,
    };

    setBikerPage(obj.page_num);
    setBikerRate(obj.rate);
  }, [searchParams.get("rate"), searchParams.get("page_num")]);

  return (
    <div className="filter-wrapper">
      <div className="item">
        <Select
          filterOption={false}
          dropdownMatchSelectWidth={false}
          allowClear
          onChange={(val) => {
            if (val != undefined) {
              setBikerRate(val);
              searchParams.set("rate", Number(val));
              searchParams.set("page_num", 1);
              setSearchParams(searchParams);
            }
          }}
          onClear={() => {
            setBikerRate(null);
            searchParams.delete("rate");
            searchParams.set("page_num", 1);
            setSearchParams(searchParams);
          }}
          placeholder="Select rate"
          style={{ width: "100%" }}
          value={bikerRate}
        >
          <Option value={"5"}>
            Like <LikeFilled />{" "}
          </Option>
          <Option value={"1"}>
            Dislike <DislikeFilled />
          </Option>
        </Select>
      </div>
    </div>
  );
}
